import { useParams } from "react-router-dom"
import BannersForm from "./BannersForm"
import HeaderReturn from "./BannersReturnHeader"
import './style.scss'
import { useDispatch, useSelector } from "react-redux"
import { getBannerById } from "../../Redux/Actions/Banners"
import { useState } from "react"
import { useEffect } from "react"
const BannersEdit = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [banner, setBanner] = useState();

  useEffect(() => {
    getData(params.id);
  }, [])
  const getData = async (id) => {
    const res = await dispatch(getBannerById(id));
    setBanner(res.data.banner)
  }

  return (
    <div className="page-container">
      <div className="mb-4">
        <HeaderReturn />
        <h3 className="align-middle ms-3 d-inline">Edit banner</h3>
      </div>
      <BannersForm data={banner} actionString="Save" />
    </div>
  )
}

export default BannersEdit;