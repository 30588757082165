import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getdisplayFlash, setdisplayFlash } from "../../Utils/Common";
import changePassword from "../../Redux/Actions/ChangePassword";
import FlashMessage from "../FlashMessage/FlashMessage";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// ASSETS
import backIcon from "../../assets/images/icon_back.png";
import iconView from "../../assets/images/icon_view.png";

// STYLING
import "./UpdatePassword.css";

const UpdatePassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [current_password, setCurrent_password] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");

  // FLASH MESSAGE STATE
  const [flashMessage, setFlashMessage] = useState(getdisplayFlash());
  const [iconImage, setIconImage] = useState("");
  const [firstMsg, setFirstMsg] = useState("");
  const [secondMsg, setSecondMsg] = useState("");

  // TOGGLE PASSWORD
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [newPasswordAgainShown, setNewPasswordAgainShown] = useState(false);

  const toggleCurrentPassword = () => {
    setCurrentPasswordShown(!currentPasswordShown);
  };

  const toggleNewPassword = () => {
    setNewPasswordShown(!newPasswordShown);
  };

  const toggleNewPasswordAgain = () => {
    setNewPasswordAgainShown(!newPasswordAgainShown);
  };

  //PASSWORD VALIDATION
  const schema = yup.object({
    password: yup
      .string()
      .min(10, "Your password must be at least 10 characters long")
      .max(64, "The password can't be longer than 64 characters")
      .matches(
        /[a-z]/,
        "Your password must contain a minimum of one lower case letter"
      )
      .matches(/[A-Z]/, "The password must contain one capital letter")
      .matches(/[0-9]/, "Your password must contain at least one number")
      .matches(/^(\S+$)/, "The password can't contain any blank spaces")
      .required()
      .matches(
        /^[aA-zZ0-9#!%&/()åÅäÄöÖ\s]+$/,
        "The password can't contain other special characters than: #!%&/()"
      )
      .matches(
        /^((?!]).)*$/,
        "The password can't contain other special characters than: #!%&/()"
      )
      .matches(
        /^[^^[`\\_]+$/,
        "The password can't contain other special characters than: #!%&/()"
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async () => {
    const data = {
      password,
      current_password,
      password_confirmation,
    };
    const { status, message } = await dispatch(changePassword(data));
    if (status) {
      setdisplayFlash(true);
      history.push("/profile");
    } else {
      openFlash("alert", "Error!", message);
    }
  };

  function closeFlash() {
    setdisplayFlash(false);
    setFlashMessage(false);
  }

  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  }

  return (
    <div className="container_fluid col-9">
      <div
        className={`alert-popup alert-success-bar ${
          flashMessage
            ? setTimeout(() => setFlashMessage(false), 3000) &
              setdisplayFlash(false) &
              "alert-shown"
            : "alert-hidden"
        }`}
      >
        <FlashMessage
          iconImage={iconImage}
          firstMsg={firstMsg}
          secondMsg={secondMsg}
          closeFlash={() => closeFlash()}
        />
      </div>
      <h3 className="basic-head01 basic-flex-start">
        <div className="back-heading">
          <span onClick={() => history.push("/profile")}>
            <img src={backIcon} alt="backIcon" style={{ cursor: "pointer" }} />
          </span>
          Change Password
        </div>
      </h3>
      <div className="bg-white-password bg-30-top form-step-first d-flex">
        <form className="password-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="form-group">
              <label>Current Password</label>
              <div className="viewpassword">
                <input
                  required
                  data-lpignore="true"
                  type={currentPasswordShown ? "text" : "password"}
                  className="up-input-width form-control icon-sector-name"
                  placeholder="Enter password"
                  value={current_password}
                  onChange={(e) => setCurrent_password(e.target.value)}
                />
                <img
                  onClick={toggleCurrentPassword}
                  className="up-reveal-password-top"
                  src={iconView}
                  alt="iconView"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <label>New Password</label>
              <div className="viewpassword">
                <input
                  {...register("password")}
                  required
                  data-lpignore="true"
                  type={newPasswordShown ? "text" : "password"}
                  className="up-input-width form-control icon-sector-name"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <img
                  onClick={toggleNewPassword}
                  className="up-reveal-password-middle"
                  src={iconView}
                  alt="iconView"
                />

                {errors && (
                  <p className="error-msg">{errors.password?.message}</p>
                )}

                {/[0-9]/.test(password) ||
                /[a-z]/.test(password) ||
                /[A-Z]/.test(password)
                  ? (/[0-9]/.test(password) &&
                    !/[A-Z]/.test(password) &&
                    !/[a-z]/.test(password) ? (
                      <p className="error-msg">
                        Your password must contain a minimum of one capital
                        letter and one lower case letter
                      </p>
                    ) : null) ||
                    (/[a-z]/.test(password) &&
                    !/[0-9]/.test(password) &&
                    !/[A-Z]/.test(password) ? (
                      <p className="error-msg">
                        Your password must contain a minimum of one capital
                        letter and one number
                      </p>
                    ) : null) ||
                    (/[A-Z]/.test(password) &&
                    !/[0-9]/.test(password) &&
                    !/[a-z]/.test(password) ? (
                      <p className="error-msg">
                        Your password must contain a minimum of one lower case
                        letter and one number
                      </p>
                    ) : null) ||
                    (/[A-Z]/.test(password) &&
                    /[0-9]/.test(password) &&
                    !/[a-z]/.test(password) ? (
                      <p className="error-msg">
                        Your password must contain a minimum of one lower case
                        letter
                      </p>
                    ) : null) ||
                    (/[a-z]/.test(password) &&
                    /[0-9]/.test(password) &&
                    !/[A-Z]/.test(password) ? (
                      <p className="error-msg">
                        Your password must contain a minimum of one capital
                        letter
                      </p>
                    ) : null) ||
                    (/[A-Z]/.test(password) &&
                    /[a-z]/.test(password) &&
                    !/[0-9]/.test(password) ? (
                      <p className="error-msg">
                        Your password must contain a minimum of one number
                      </p>
                    ) : null)
                  : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <label>Re-enter New Password</label>
              <div className="viewpassword">
                <input
                  required
                  data-lpignore="true"
                  type={newPasswordAgainShown ? "text" : "password"}
                  className=" up-input-width form-control icon-sector-name"
                  placeholder="Enter password"
                  value={password_confirmation}
                  onChange={(e) => setPassword_confirmation(e.target.value)}
                />
                <img
                  onClick={toggleNewPasswordAgain}
                  className="up-reveal-password-bottom"
                  src={iconView}
                  alt="iconView"
                />
                {password !== password_confirmation && (
                  <p className="error-msg">
                    Re-entered password must match new password
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <div className="confirm-pass">
                <button type="submit" className="up-save-button">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
        <section className="requirements-section">
          <h4 className="requirements-heading">Password requirements</h4>
          <p>
            The password must contain:
            <br></br>- Be at least 10 characters long
            <br></br>- Can be a maximum of 64 characters long
            <br></br>- An uppercase letter
            <br></br>- A lowercase letter
            <br></br>- A number
            <br></br>- The password must not contain spaces
          </p>
          <p>
            <span>The password can contain special characters: #!%&/()</span>
          </p>
        </section>
      </div>
    </div>
  );
};

export default UpdatePassword;
