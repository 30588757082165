import React, { useState } from 'react';
import './editModalBank.css'
import { useForm } from 'react-hook-form';

const EditModalBank = (props) => {
  const { register, handleSubmit, reset } = useForm();
  const [status, setStatus] = useState(props?.itemForEdit?.op_status)
  const [authenticationType, setAuthenticationType] = useState(props?.itemForEdit?.banking_authentication_type)
  const [authApproach, setAuthApproach] = useState(props.itemForEdit.auth_approach)

  const ApiData = async (data) => {
    props.setOpenEditBank(false)
    const formData = new FormData();
    const post = { ...data, file_name: data.file_name[0] }
    formData.append('file_name', post.file_name)
    props.confirm(formData, JSON.parse(status), authenticationType)
    reset()
  }
  return (
    <div className='EditPopup'>
      <div className='EditPopup_inner'>
        <h3>Edit Provider</h3>
        <form onSubmit={handleSubmit(ApiData)}>
          <div className="row">
            <div className="col-md-6">
              <div className="setting-filter-container input-credentials">
                <label>Icon</label>
                <div className="provider-input-file">
                  <input type="file" accept="image/png, image/gif, image/jpeg" {...register('file_name')} />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container organization-provider-filter-container">
                <label>Active</label>
                <select className="select-filter-organization-provider" value={status} onChange={(e) => setStatus(e.target.value)}>
                  <option value="">Select</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container organization-provider-filter-container">
                <label>Authentication Type</label>
                <select className="select-filter-organization-provider" value={authenticationType} onChange={(e) => setAuthenticationType(e.target.value)}>
                  <option value="">Select</option>
                  {
                    authApproach.map(authApp => <option value={authApp.toLowerCase()}>{authApp}</option>)
                  }
                </select >
              </div >
            </div >
          </div >
          <div className="modal-btns">
            <button className='closeDelete-update' onClick={props.closeDelete} style={{ marginLeft: '0px' }}>Cancel</button>
            <button className='confirm-update' type='submit'>Update</button>
          </div>
        </form >
      </div >
    </div >
  )
}
export default EditModalBank;