import React, { useState, useEffect } from 'react';
import ToggleOrgWithKey from '../ToggleOrgWithKeys/ToggleOrgWithKeys';
import _ from 'lodash';

const MarketTab = ({
  setClientDetails,
  setBankFiltersCorporate,
  bankFilterCountries,
  setBankFilterCountries,
  setBankFilters,
  bankFilterCountriesCorporate,
  setBankFilterCountriesCorporate,
  country,
  clientDetails,
  submitHandle,
}) => {

  const [isMarkets, setMarkets] = useState(false)
  const [isCorporateMarkets, setCorporateMarkets] = useState(false)

  const handleMarketIds = (event, name) => {
    if (name === 'all') {
      setMarkets(event.target.checked)
      let Markets = country.map(temp => {
        return temp.id.toString()
      })
      setClientDetails(prevState => ({
        ...prevState,
        market_ids: event.target.checked ? Markets : []
      }))
      event.target.checked ? setBankFilterCountries(country) : setBankFilterCountries([])
    } else {
      setMarkets(false)
      if (event.target.checked) {
        if (!_.includes(clientDetails.market_ids, name.toString())) {
          let _markets = []
          _markets = clientDetails.market_ids ? clientDetails.market_ids : []
          _markets.push(name.toString())
          setClientDetails(prevState => ({
            ...prevState,
            market_ids: _markets
          }))

          let _bankMarketCountries = bankFilterCountries
          let _bankCountry = _.filter(country, function (_bank) {
            return _bank.id == name
          })
          _bankMarketCountries.push(_bankCountry[0])
          setBankFilters(prevState => ({
            ...prevState,
            country_id: _bankCountry[0]?.id
          }))
          setBankFilterCountries([..._bankMarketCountries])
        }
      } else {
        if (_.includes(clientDetails.market_ids, name.toString())) {
          let _markets = clientDetails.market_ids
          _markets = _.filter(_markets, function (f) { return f !== name.toString() })
          setClientDetails(prevState => ({
            ...prevState,
            market_ids: _markets
          }))
          let _bankMarketCountries = bankFilterCountries
          _bankMarketCountries = _.filter(_bankMarketCountries, function (f) { return f?.id != name })
          setBankFilters(prevState => ({
            ...prevState,
            country_id: _bankMarketCountries.length > 0 ? _bankMarketCountries[0]?.id : 1
          }))
          setBankFilterCountries([..._bankMarketCountries])
        }
      }
    }
  }

  const handleCorporateMarketIds = (event, name) => {
    if (name === 'all') {
      setCorporateMarkets(event.target.checked)
      let Markets = country.map(temp => {
        return temp.id.toString()
      })
      setClientDetails(prevState => ({
        ...prevState,
        corporate_market_ids: event.target.checked ? Markets : []
      }))
      event.target.checked ? setBankFilterCountriesCorporate(country) : setBankFilterCountriesCorporate([])
    } else {
      setCorporateMarkets(false)
      if (event.target.checked) {
        if (!_.includes(clientDetails.corporate_market_ids, name.toString())) {
          let _markets = []
          _markets = clientDetails.corporate_market_ids ? clientDetails.corporate_market_ids : []
          _markets.push(name.toString())
          setClientDetails(prevState => ({
            ...prevState,
            corporate_market_ids: _markets
          }))
          let _bankMarketCountriesCorporate = bankFilterCountriesCorporate
          let _bankCountryCorporate = _.filter(country, function (_bank) { return _bank.id == name })
          _bankMarketCountriesCorporate.push(_bankCountryCorporate[0])
          setBankFiltersCorporate(prevState => ({
            ...prevState,
            country_id: _bankCountryCorporate[0]?.id
          }))
          setBankFilterCountriesCorporate([..._bankMarketCountriesCorporate])
        }
      } else {
        if (_.includes(clientDetails.corporate_market_ids, name.toString())) {
          let _markets = clientDetails.corporate_market_ids
          _markets = _.filter(_markets, function (f) { return f !== name.toString() })
          setClientDetails(prevState => ({
            ...prevState,
            corporate_market_ids: _markets
          }))
          let _bankMarketCountriesCorporate = bankFilterCountriesCorporate
          _bankMarketCountriesCorporate = _.filter(_bankMarketCountriesCorporate, function (f) { return f?.id != name })
          setBankFiltersCorporate(prevState => ({
            ...prevState,
            country_id: _bankMarketCountriesCorporate.length > 0 ? _bankMarketCountriesCorporate[0]?.id : 1
          }))
          setBankFilterCountriesCorporate([..._bankMarketCountriesCorporate])
        }
      }
    }
  }

  useEffect(() => {
    if (clientDetails?.market_ids?.length === country.length) {
      setMarkets(true)
    } else {
      setMarkets(false)
    }
    if (clientDetails?.corporate_market_ids?.length === country.length) {
      setCorporateMarkets(true)
    } else {
      setCorporateMarkets(false)
    }
  }, [clientDetails])


  return (
    <div id="markets_details" className="tab-pane fade show hide" role="tabpanel" aria-labelledby="market-tab">
      <div className="detail-two-box">
        <div className="details-left details-full-box">
          <h3 className="headeing-switch sub-tab">
            Market Consumer
            <div className="adjust-switcher">
              ALL
              <div className="select-switch">
                <label className="switch-box">
                  <input type="checkbox" checked={isMarkets} onChange={(e) => handleMarketIds(e, 'all')} />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </h3>
          {country &&
            country.map((keys, index) => (
              <ToggleOrgWithKey
                keys={keys.name}
                id={keys.id.toString()}
                checked={_.includes(clientDetails.market_ids, keys.id.toString())}
                toggleFunction={handleMarketIds}
                key={"country-" + keys.id}
              />
            ))}
        </div>
        <div className="details-right details-full-box">
          <h3 className="headeing-switch sub-tab">
            Market Corporate
            <div className="adjust-switcher">
              ALL
              <div className="select-switch">
                <label className="switch-box">
                  <input type="checkbox" checked={isCorporateMarkets} onChange={(e) => handleCorporateMarketIds(e, 'all')} />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </h3>
          {country &&
            country.map((keys, index) => (
              <ToggleOrgWithKey
                keys={keys.name}
                id={keys.id.toString()}
                checked={_.includes(clientDetails.corporate_market_ids, keys.id.toString())}
                toggleFunction={handleCorporateMarketIds}
                key={"country-" + keys.id}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default MarketTab;
