import React, { useEffect, useState } from "react";
import iconImg from "../../assets/images/icon_arrow_down.png"
import '../ItMenu/itMenu.scss'
import { createOrganization, getOrganizationAccountList } from '../../Redux/Actions/OrganizationManagement';
import { useDispatch } from 'react-redux'
import FlashMessage from "../../Components/FlashMessage/FlashMessage";
import { getOrganizationListCertificates, getCertificateData, getCertificateDelete } from "../../Redux/Actions/ItMenu";
import { getOrganizationUserData, getOrganizationUserEdit, getDeactivateUser, getActivateUser, createOrganizationUser,getInviteOrgUserData } from "../../Redux/Actions/OrganizationUser";
import ReactPaginate from "react-paginate";
import vectorImg from "../../assets/images/Vector.svg"
import { setdisplayFlash, getdisplayFlash } from "../../Utils/Common";
import { filter } from "lodash";
import Select from 'react-select'
import ReactSelect from "../ReactSelect/ReactSelect";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import DeactivateUserModal from "../DeactivateUserModal/DeactivateUser";
import EditUserModal from "../EditUserModal/EditUserModal";
import CertificateLogsList from "../Certificate/certificateLogsList";
import invitationGray from '../../assets/images/invitationGray.svg';
import InviteOrgUser from "./InviteOrgUser";
import CreateUserModal from "./CreateUserModal.jsx";
const EditUserIt = () => {
  const dispatch = useDispatch();
  const [orgList, setOrgList] = useState([])
  const [filters, setFilters] = useState({ 'org_id': '', 'role': '', 'user_id': '' })
  const [selectedPage, setSelectedPage] = useState(1);
  const [total_pages, setTotal_pages] = useState("");
  const [editPopup, setEditPopup] = useState(false)
  const [btnEnabled, setBtnEnabled] = useState(false)
  const [filteredCertificates, setFilteredCertificates] = useState([])
  const [orgName, setOrgName] = useState()
  const [page, setPage] = useState(1);
  const [paginationData, setpaginationData] = useState({ current_page: 1, total_count: '', total_pages: '' })
  const [orgUser, setOrgUser] = useState()
  const [flashMessage, setFlashMessage] = useState(false);
  const [iconImage, setIconImage] = useState("");
  const [firstMsg, setFirstMsg] = useState("");
  const [secondMsg, setSecondMsg] = useState("");
  const [reason, setReason] = useState("")
  const [showCertificate, setShowCertificate] = useState(false)
  const textConfirmation1 = ("Are you sure you want")
  const textConfirmation2 = ("to delete client");
  const [openDeactivateUser, setOpenDeactivateUser] = useState(false)
  const [deactivateItem, setDeactivateItem] = useState()
  const [orgId, setOrgId] = useState()
  const [openCreate, setOpenCreate] = useState(false)
  const [openInviteOrg,setOpenInviteOrg]=useState(false)
  const [inviteData,setInviteData]=useState()


  let options = [{ value: 1, label: 1 }, { value: 2, label: 2 }, { value: 3, label: 3 }]


  useEffect(() => {
    fetchOrganization()
  }, [])



  const selectPageHandler = (event) => {
    const currentPage = event.selected + 1;
    setSelectedPage(currentPage);
  };
  const fetchOrganization = async () => {
    const response = await dispatch(getOrganizationListCertificates())
    setOrgList(response?.result?.data?.organizations_list)
  }
  const handleChange = (type, value) => {
    setFilters(prevState => ({
      ...prevState,
      [type]: value
    }))
  }
  const applyFiltersHandler = async () => {
    await dispatch(setLoaderSpinner(true))
    let dataQuery = '?org_id=' + filters.org_id + '&role=' + filters.role + '&user_id=' + filters.user_id;
    const data = filters;
    const response = await dispatch(getOrganizationUserData(dataQuery))
    if (response.message === "success") {
      setFilteredCertificates(response?.result?.data?.users)
        let result = orgList.findIndex(org => org.id == filters.org_id)
      setOrgId(orgList[result]?.id)
      setOrgName(`${orgList[result]?.name}(${orgList[result]?.id})`)
      setShowCertificate(true)
    } else {
      openFlash("", "Error", response?.message);
      setFilteredCertificates([])
    }
    await dispatch(setLoaderSpinner(false))
  }

  const editUserModalHandler = (certificate) => {
    setEditPopup(true)
    setOrgUser(certificate)
  }

  const editUserHandler = async (data) => {
    setEditPopup(false)
    let dataQuery = '?id=' + orgUser?.id + '&role=' + data.role + '&name=' + data.name + '&email=' + data.email;
    const response = await dispatch(getOrganizationUserEdit(dataQuery))
    if (response?.status) {
      const { status, message } = response?.result
      if (status === "success") {
        let result = filteredCertificates.findIndex(item => item.id === orgUser.id)
        let newFilteredUser = [...filteredCertificates]
        if (result !== -1) {
          newFilteredUser[result] = { ...newFilteredUser[result], name: orgUser.name }
          newFilteredUser[result] = { ...newFilteredUser[result], role: orgUser.role }
          newFilteredUser[result] = { ...newFilteredUser[result], email: orgUser.email }
        }

        setFilteredCertificates(newFilteredUser)

        openFlash("tick", "Done!", message);
        setBtnEnabled(false)
      }
    }
    else {
      openFlash("", "Error", response?.message);
    }
  }

  const togglePopup = () => {
    setOpenDeactivateUser(false)
    setEditPopup(false)
    setBtnEnabled(false)
    setOpenCreate(false)
  }

  const deleteChangeHandler = (e) => {
    e.target.value === "DELETE" ? setBtnEnabled(true) : setBtnEnabled(false)
  }

  function closeFlash() {
    setdisplayFlash(false);
    setFlashMessage(false);
  }
  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  }

  const reasonHandler = (e) => {
    setReason(e.target.value)
  }

  const pageChangeHandler = (e) => {
    setPage(e.selected + 1)
    applyFiltersHandler()
  }

  const deactivateUserModalHandler = (data) => {
    setDeactivateItem(data)
    setOpenDeactivateUser(true)
  }


  const deactivateUserHandler = async (data) => {
    setOpenDeactivateUser(false)
    let dataQuery = '?id=' + deactivateItem?.id + '&status=inactive';
    const response = await dispatch(getDeactivateUser(dataQuery))
    if (response?.status) {
      const { status, message, data } = response?.result
      if (status === "success") {
        let result = filteredCertificates.findIndex(item => item.id === deactivateItem.id)
        let newFilteredUser = [...filteredCertificates]
        if (result !== -1) {
          newFilteredUser[result] = { ...newFilteredUser[result], deleted_at: data?.deleted_at }
        }
        setFilteredCertificates(newFilteredUser)
        openFlash("tick", "Done!", response?.message);
        setBtnEnabled(false)
      }
    }
    else {
      openFlash("", "Error", response?.message);
    }
  }

  const activateUserHandler = async (activeItem) => {
    let dataQuery = '?id=' + activeItem?.id + '&status=active';
    const response = await dispatch(getActivateUser(dataQuery))
    if (response?.status) {
      const { status, message, data } = response?.result
      if (status === "success") {
        let result = filteredCertificates.findIndex(item => item.id === activeItem.id)
        let newFilteredUser = [...filteredCertificates]
        if (result !== -1) {
          newFilteredUser[result] = { ...newFilteredUser[result], deleted_at: data?.deleted_at }
        }
        setFilteredCertificates(newFilteredUser)
        openFlash("tick", "Done!", response?.message);
        setBtnEnabled(false)
      }
    }
    else {
      openFlash("", "Error", response?.message);
    }
  }

  const createUserHandler = async (data) => {
    let query = `?registration[email]=${data.email}&registration[role]=${data.role}&registration[name]=${data.name}&registration[organization_id]=${data.organization_id}`
    const res = await dispatch(createOrganizationUser(query))
    setOpenCreate(false)
    if (res.status) {
      openFlash("tick", "Done!", res.message);
    } else {
      openFlash("", "Error!", res.message);
    }
  }

  const capitalizeFirstLetter = key => key.split('_').map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(' ')


  const inviteUserPopupHandler= async (id)=>{
    let query=`?id=${id}`
    const {data,status,message} = await dispatch(getInviteOrgUserData(query))
    if(status==="success")
    {
      setInviteData(data)
      setOpenInviteOrg(true)
    }else{
      openFlash("", "Error!", message);
    }
  }

  const closeInvitePopup=()=>{
    setOpenInviteOrg(false)
  }


  return (
    <div className="service-settings-container page-container">
      <div
        className={`alert-popup alert-success-bar ${flashMessage
          ? setTimeout(() => setFlashMessage(false), 3000) &
          setdisplayFlash(false) &
          "alert-shown"
          : "alert-hidden"
          }`}
      >
        <FlashMessage
          iconImage={iconImage}
          firstMsg={firstMsg}
          secondMsg={secondMsg}
          closeFlash={() => closeFlash()}
        />
      </div>
      {
        openInviteOrg ?
          <InviteOrgUser
            inviteData={inviteData}
            closeDelete={closeInvitePopup}
            openFlash={openFlash}
          />
          : null
      }
      {
        openDeactivateUser && <DeactivateUserModal
          firstTextUser={textConfirmation1}
          secondTextUser={textConfirmation2}
          orgUser={orgUser}
          deactivateItem={deactivateItem}
          deactivateUserHandler={deactivateUserHandler}
          closeDelete={togglePopup}
        />
      }
      {editPopup &&
        <EditUserModal orgUser={orgUser} setOrgUser={setOrgUser} handleChange={handleChange} editUserHandler={editUserHandler} closeDelete={togglePopup} />
      }

      {openCreate &&
        <CreateUserModal orgList={orgList} setOrgUser={setOrgUser} handleChange={handleChange} editUserHandler={editUserHandler} closeDelete={togglePopup} createUserHandler={createUserHandler} />
      }
      <div className="lower-header-service-settings service-settings-head-bg lower-header-it-certificates">
        <h3>Organization User</h3>
      </div>
      <div className="filter-container-service-settings">
        <div className="service-settings-filter-container overflow-inherit">
          <label>Organization</label>
          <ReactSelect
            orgList={orgList}
            handleChange={handleChange}
            pageRender={'organization user'}
          />
        </div>
        <div className="service-settings-filter-container">
          <label>Role</label>
          <select className="select-filter-service-settings capitalize" onChange={(e) => handleChange("role", e.target.value)}>
            <option value="">Select</option>
            <option value="admin">Administrator</option>
            <option value="employee">Administrative Officer</option>
          </select>
        </div>
        <div className="service-settings-filter-container search-certificate-id">
          <input type="text" className="select-filter-service-settings" placeholder="Search: User ID" onChange={(e) => handleChange("user_id", e.target.value)} />
        </div>
        <div className="service-settings-filter-container">
          <button className={filters.org_id === '' ? 'apply-filter-button login_submit_disabled' : 'apply-filter-button'} onClick={applyFiltersHandler}>Apply</button>
        </div>
        <button className="org-create-user-btn" onClick={() => setOpenCreate(true)}>Create User</button>
      </div>
      <div className="bg-white-organizations bg-20-top" style={{ margin: "20px 0 0 0" }}>
        <div className="organizations-thead-scroll-y it-certificates-table">
          <table className="table" width="100%">
            <thead className="organizations-thead">
              <tr>
                <th className="org-head" style={{ minWidth: "180px" }}>Organization</th>
                <th className="org-head" style={{ minWidth: "100px" }}>Role</th>
                <th className="org-head" style={{ minWidth: "180px" }}>Name</th>
                <th className="org-head" style={{ minWidth: "180px" }}>Email</th>
                <th className="org-head" style={{ minWidth: "100px" }}>User ID</th>
                <th className="org-head" style={{ minWidth: "100px" }}>Status</th>
                <th className="org-head" style={{ minWidth: "100px" }}>Invitation Status</th>
                <th colSpan={2} style={{ minWidth: "140px" }}>Action</th>
              </tr>
            </thead>
            <tbody className="organizations-tbody org-user-tbody">
              {showCertificate &&
                filteredCertificates && filteredCertificates?.map(item => <tr className={(item.deleted_at !== null) ? "deactivate" : ""}>
                  <td className="org-td">{orgName}</td>
                  <td className="org-td">{item?.role === "employee" ? "Administrative Officer" : "Administrator"}</td>
                  <td className="org-td">{item?.name}</td>
                  <td className="org-td">{item?.email}</td>
                  <td className="org-td">{item?.id}</td>
                  <td className="org-td">{capitalizeFirstLetter((item.deleted_at !== null) ? "deactive" : "active")}</td>
                  <td className="org-td">{item?.invitation_link_status}</td>
                  
                  <td className="org-td">
                    <span className="invite-icon">{(item?.deleted_at===null && item?.show_icon_if_link_not_accepted) && <img src={invitationGray} alt="invite" className="me-2 cursor-pointer" onClick={()=>inviteUserPopupHandler(item.id)} />}</span>
                    {(item.deleted_at === null) && <a onClick={() => editUserModalHandler(item)} href="#" className="delete-rad">Edit</a>}
                  </td>
                  {(item.deleted_at === null) ? <td className="org-td cursor-pointer"><b onClick={() => deactivateUserModalHandler(item)}>Deactivate</b></td> : <td className="org-td cursor-pointer" ><b onClick={() => activateUserHandler(item)}>Activate</b></td>}
                </tr>)}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default EditUserIt;