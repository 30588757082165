import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import "./organizationList.scss";
import calendarIcon from '../../assets/images/calendar_icon_gray.svg';
import calendarIconWhite from '../../assets/images/calendar_icon_white.svg';
import searchIcon from '../../assets/images/search_icon.svg';
import sortIcon from '../../assets/images/sort_icon.svg';
import edit from '../../assets/images/edit.svg';
import remove from '../../assets/images/remove.svg';
import { useHistory } from "react-router-dom";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import ReactPaginate from 'react-paginate';
import DeleteModal from '../DeleteModal/DeleteModal';
import { setLoaderSpinner } from '../../Redux/Actions/LoaderSpinner';
import InviteModal from "../InviteModal/InviteModal";
import invitationGray from '../../assets/images/invitationGray.svg';
import FlashMessage from "../FlashMessage/FlashMessage";

import { getOrganizationList, removeOrganization, getInvitationLink } from '../../Redux/Actions/OrganizationManagement';
import { setdisplayFlash, getdisplayFlash } from "../../Utils/Common";
import Flag from 'react-world-flags'


const OrganizationList = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [orgsData, setOrgData] = useState([])
	const [searchData, setSearchData] = useState('')
	const [openDeleteOrg, setOpenDeleteOrg] = useState(false)
	const [deleteOrgId, setDeleteOrgId] = useState(null)
	const [deleteOrgName, setDeleteOrgName] = useState('')
	const textConfirmation1 = ("Are you sure you want")
	const textConfirmation2 = ("to delete client");
	const [_month, setMonth] = useState(false)
	const [_year, setYear] = useState(false)
	const [_dateRange, setDateRange] = useState('')
	const [page, setPage] = useState(1)
	const [show, setShow] = useState(false);
	const [showName, setShowName] = useState(false);

	const activateToolTip = (index) => {
		setShow(index)
	}

	const activateNameToolTip = (index) => {
		setShowName(index)
	}

	const [openInviteOrg, setOpenInviteOrg] = useState(false)
	const [invitationData, setInvitationData] = useState()
	const [flashMessageinvite, setFlashMessageInvite] = useState(getdisplayFlash())
	const [paginationData, setpaginationData] = useState({ current_page: 1, total_pages: '', total_count: '', sort_column: 'created_at', sort_order: 'desc' })
	const [flashDataInvite, setFlashDataInvite] = useState({ iconImage: "", firstMsg: "", secondMsg: "" })


	useEffect(() => {
		fetchOrgList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_month, _year, _dateRange, page, paginationData.sort_column, paginationData.sort_order]);

	const fetchOrgList = async (typeSearch) => {
		await dispatch(setLoaderSpinner(true))
		let query = ''
		let search = searchData && searchData.length > 0 ? searchData : ""

		query =
			'?page=' + page +
			'&query=' + search +
			"&sort_column=" + paginationData.sort_column +
			"&sort_order=" + paginationData.sort_order

		if (_month || _year) {
			if (_month) {
				query = query + '&month=' + _month
			} else if (_year) {
				query = query + '&year=' + _year
			}
		} else {
			query = query + '&date_range=' + _dateRange
		}

		const data = await dispatch(getOrganizationList(query))
		await dispatch(setLoaderSpinner(false))
		if (data.status) {
			setOrgData(data.data.organizations)
			setpaginationData(prevState => ({
				...prevState,
				current_page: data.data.current_page,
				total_count: data.data.total_count,
				total_pages: data.data.total_pages
			}))
		} else {
			history.push('/login')
		}
	}


	const searchHandle = (e) => {
		setSearchData(e.target.value)
	}

	const searchOrgSubmit = () => {
		fetchOrgList("Yes");
	}

	const deleteOrgHandle = (id, name) => {
		setOpenDeleteOrg(true)
		setDeleteOrgId(id)
		setDeleteOrgName(name)
	}

	const inviteOrg = async (id) => {
		const { status, message, data } = await dispatch(getInvitationLink(id))
		if (data.status === "success") {
			setInvitationData(data)
			setOpenInviteOrg(true)
		} else if (data.status === "error") {
			openFlash("alert", "Error!", message);
		}
	}

	const deleteSelectedOrg = async () => {
		const data = await dispatch(removeOrganization(deleteOrgId))
		if (data.status) {
			fetchOrgList()
		}

		setOpenDeleteOrg(false)
		setDeleteOrgId(null)
		setDeleteOrgName('')
	}

	const togglePopup = () => {
		setOpenDeleteOrg(false)
		setDeleteOrgId(null)
		setDeleteOrgName('')
	}

	const closeInvitePopup = () => {
		setOpenInviteOrg(false)
	}

	const handleDuration = (type) => {
		if (type === 'month') {
			setMonth(true)
			setYear(false)
		}

		if (type === 'year') {
			setMonth(false)
			setYear(true)
		}
	}

	const handleDatePicker = (start, end) => {
		const startDateValue = moment(start._d).format('DD/MM/YYYY');
		const endDateValue = moment(end._d).format('DD/MM/YYYY');
		setDateRange(`${startDateValue} - ${endDateValue}`);
	}

	const applySorting = (type) => {
		const sortOrder = paginationData.sort_column === type ? paginationData.sort_order === 'asc' ? 'desc' : 'asc' : 'asc'
		setpaginationData(prevState => ({
			...prevState,
			sort_column: type,
			sort_order: sortOrder
		}))
		setPage(1)
	}

	const goto = () => {
		history.push('/create_organization')
	}

	function openFlash(iconImage, firstMsg, secondMsg) {
		setdisplayFlash(true);
		setFlashMessageInvite(true);
		setFlashDataInvite(prevState => ({
			...prevState,
			iconImage: iconImage,
			firstMsg: firstMsg,
			secondMsg: secondMsg
		}))
	}

	function closeEditFlash() {
		setdisplayFlash(false);
		// setFlashMessage(false);
	}

  const returnAccountTypeName = (key) => {
    let accessTypeName = {
      'client': 'Portal Only',
      'api': 'API Only',
      'dev': 'Portal+API'
    }
    return accessTypeName[key] || ""
  }

	return (
		<div className="page-container">

			<div className={`alert-popup alert-success-bar ${flashMessageinvite ? setTimeout(() => setFlashMessageInvite(false), 2000) & setdisplayFlash(false) & 'alert-shown' : 'alert-hidden'}`}>
				<FlashMessage
					iconImage={flashDataInvite.iconImage}
					firstMsg={flashDataInvite.firstMsg}
					secondMsg={flashDataInvite.secondMsg}
					closeFlash={() => closeEditFlash()}
				/>
			</div>

			{
				openDeleteOrg ?
					<DeleteModal
						firstTextUser={textConfirmation1}
						secondTextUser={textConfirmation2}
						userId={deleteOrgId}
						name={deleteOrgName}
						confirm={deleteSelectedOrg}
						closeDelete={togglePopup}
					/>
					: null
			}
			{
				openInviteOrg ?
					<InviteModal
						// firstTextUser={textConfirmation1}
						// secondTextUser={textConfirmation2}
						invitationData={invitationData}
						userId={deleteOrgId}
						name={deleteOrgName}
						confirm={deleteSelectedOrg}
						closeDelete={closeInvitePopup}
						openFlash={openFlash}
					/>
					: null
			}
			<div className="page-top">
				<h3 className="page-h3">Organization Management</h3>
				<button className="costum-link-btn org-create-button" type="submit" onClick={() => goto()}> Create Organization </button>
			</div>
			<br />
			<div className="bg-white-organizations bg-white-org-list bg-20-top">
				<h5 className="org-client-head">Total Clients: <span className="org-client-count">{paginationData.total_count}</span></h5>
				<div className="switch-search margin-search">
					<div className="tab-date">
						<ul className="switch-map">
							<li className='icon-month' style={{ marginLeft: '-30px', borderRight: "1px solid #E3E7EE" }} onClick={() => handleDuration("month")}>Month</li>
							<li className='icon-year' onClick={() => handleDuration("year")}>Year</li>
						</ul>
						<div className="input-sets">
							<div className="input-group date k-datepicker">
								<div>
									<DateRangePicker
										initialSettings={{ locale: { format: "DD/MM/YYYY" } }}
										onCallback={handleDatePicker}
									>
										<input
											type="text"
											id="date-range-picker"
											className="form-control"
										/>
									</DateRangePicker>
									<div className="calender-icon-field">
										<span className="input-group-addon org-datepicker org-calendar-icon"><img src={calendarIconWhite} alt="calendarIcon" /></span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="select-search tab-date">
						<div className="search-input org-search-input">
							<input
								className="search-input"
								name="search"
								value={searchData}
								placeholder="Search by Organization name, Contact number, Phone"
								onChange={(e) => searchHandle(e)}
								onKeyPress={(event) => {
									if (event.key === 'Enter') {
										searchOrgSubmit();
									}
								}}
							/>
							<img className="search-icon" src={searchIcon} alt="searchIcon" onClick={() => searchOrgSubmit()} />
						</div>
					</div>
				</div>
        <div className="organizations-thead-scroll-y">
          <table className="table" width="100%">
            <thead className="organizations-thead">
              <tr>
                <td className="td-35">Id</td>
                <td className="org-head" onClick={(e) => applySorting('name')}>Org Name<img src={sortIcon} alt="sortIcon" /></td>
                <td className="org-head td-100" onClick={(e) => applySorting('organization_number')}>Org Number<img src={sortIcon} alt="sortIcon" /></td>
                <td className="org-head" onClick={(e) => applySorting('phone')}>Contact Person<img src={sortIcon} alt="sortIcon" /></td>
                <td className="org-head td-110" onClick={(e) => applySorting('account_type')}>Account Type<img src={sortIcon} alt="sortIcon" /></td>
                <td className="org-head td-110" onClick={(e) => applySorting('access_type')}>Access Type<img src={sortIcon} alt="sortIcon" /></td>
                <td className="org-head" >Active Markets</td>
                <td className="org-head td-100">Email</td>
                <td className="org-head td-115">Dummy Account</td>
                <td className="org-head td-70">Active</td>
                <td><center>Action</center></td>
              </tr>
            </thead>
            <tbody className="organizations-tbody">
              {
                orgsData && orgsData.length > 0 && orgsData.map((item, index) => {
                  return (
                    <tr key={Math.random()} className={(item && item.deleted_at !== null) ? "del-row" : null}>
                      <td className="org-td td-35">{item.id}</td>
                      <td className="org-td">{item.name}</td>
                      <td className="org-td td-100">{item.organization_number}</td>
                      <td className="org-td">{item.company_contact}</td>
                      <td className="org-td td-110">{item.account_type === "main_account" ? `Main Account ${item?.sub_account_list!=="" ? `-Sub (${ item?.sub_account_list})`:''} ` : `Sub account - Main (${item?.parent_org_name})`}</td>
                      <td className="org-td td-110">{returnAccountTypeName(item.organization_type)}</td>
                      <td className="org-td">
                        {
                          item?.countries_code?.map((c_code, key) =>
                            c_code === "UK" ?
                              <span key={key}>
                                <Flag code={"GB"} height="16" className='invoicing-flag' />
                              </span> :
                              <span key={key}>
                                <Flag code={c_code} height="16" className='invoicing-flag' />
                              </span>
                          )
                        }
                      </td>
                      <td className="org-td td-100">
												<span className="org-tooltip-wrapper header-tooltip-hover">
													<span>
														{item.email.slice(0, 10) + "..."}
													</span>
													<span className='org-tooltip-header'>{item.email}</span>
												</span>
											</td>
                      <td className={item?.dummy_account ? "org-td td-115 precent" : "org-td td-115 active-status-false"}>{item?.dummy_account ? 'YES' : 'NO'}</td>
                      <td className={item?.active ? "org-td td-70 precent" : "org-td td-70 active-status-false"}>{item?.active ? 'YES' : 'NO'}</td>
                      {item && item.deleted_at == null ? (
                        <td className="org-td org-action">
                          <center className="org-invitation">
                            {(item.organization_user_activated_at === null && item.active === true) && <img src={invitationGray} alt="edit" onClick={() => inviteOrg(item.organization_user_id)} />}
                            <img src={edit} alt="edit" onClick={() => history.push(`/edit_organization/${item.id}`)} />
                            <img src={remove} alt="remove" onClick={() => deleteOrgHandle(item.id, item.name)} />
                          </center>
                        </td>
                      ) : null
                      }
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
				<div className="pagination-box">
					{paginationData.total_count > 30 &&
						<ReactPaginate
							previousLabel="Previous"
							nextLabel="Next"
							breakLabel={"..."}
							pageCount={Math.ceil(paginationData.total_pages)}
							marginPagesDisplayed={2}
							pageRangeDisplayed={5}
							onPageChange={(e) => setPage(e.selected + 1)}
							containerClassName={"pagination justify-content-center"}
							pageClassName={"page-item"}
							pageLinkClassName={"page-link"}
							previousClassName={"page-item"}
							previousLinkClassName={"page-link"}
							nextClassName={"page-item"}
							nextLinkClassName={"page-link"}
							breakClassName={"page-item"}
							breakLinkClassName={"page-link"}
							activeClassName={"active"}
							forcePage={parseInt(page) - 1}
						/>
					}
				</div>
			</div>
		</div >
	)
}

export default OrganizationList;