import React, { useState, useEffect } from "react";
import { left } from "@popperjs/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
//import { set } from "lodash";

import "./Scss/dashboard.scss";

const YearlyGraph = ({
  accepted_requests_month = {},
  accepted_requests_last_year = {},
}) => {
  const accepted_requests_months = Object.keys(accepted_requests_month);
  const accepted_requests_values = Object.values(accepted_requests_month);
  const accepted_requests_last_year_values = Object.values(accepted_requests_last_year);
  const d = new Date();
  let thisYear = d.getFullYear();
  let lastYear = (d.getFullYear() - 1);

  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const initialOptions = {
    chart: {
      type: "column",
      // height: 300,
      style: {
        fontFamily: "SF Pro Display",
      },
    },
    title: {
      text: "Accepted requests current year vs accepted requests last year",
      align: left,
      margin: 20,
      style: {
        fontWeight: 500,
        color: "#596980",
      },
    },
    accessibility: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        const monthToDisplay = this.series.name === thisYear ? thisYear : lastYear;
        return monthToDisplay + "<br/>" + "requests: " + Highcharts.numberFormat(this.y, 0, ' ');
      },
      animation: false,
      backgroundColor: "#ffffff",
      style: {
        color: "#333333",
      },
    },
    colors: ["#43BF58", "#3e85f9"],
    credits: {
      enabled: false,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total counts",
      },
      visible: true,
    },
    xAxis: {
      categories: months || 0,
      title: {
        text: "Year",
      },
      crosshair: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        pointWidth: 10,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return Highcharts.numberFormat(this.y, 0, ' ');
          },
          rotation: 270,
          y: -20,
          color: "#596980",
          style: {
            textOutline: "0px",
          },
          inside: false,
          crop: false,
        }
      },
      series: {
        minPointLength: 2,
      },
    },
    series: [
      {
        name: lastYear,
        data: accepted_requests_last_year_values || 0,
        borderRadiusTopLeft: "20px",
        borderRadiusTopRight: "20px",
      },
      {
        name: thisYear,
        data: accepted_requests_values || 0,
        borderRadiusTopLeft: "20px",
        borderRadiusTopRight: "20px",
      },
    ],
  };

  return (
    <div className="ld-main-data-container">
      <div className="ld-requests-container">
        <div className="ld-request-chart-container ">
          <div className="ld-request-chart yearGraph">
            <HighchartsReact
              containerProps={{ style: { height: "380px" } }}
              highcharts={Highcharts}
              options={initialOptions}
            ></HighchartsReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YearlyGraph;