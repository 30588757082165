import React from 'react';
import PropTypes from 'prop-types';

const PricesTab = () => {
  return (
    <div id="prices_details" className="hide">
      <div className="detail-two-box">
        <div className="details-left details-full-box">
          <h3 className="headeing-switch">Cost Per Consumer Certificate</h3>
          <h3 className="headeing-switch-light form-group-flex">
            Sweden
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Free limit count" />
              <input type="text" className="form-control" placeholder="Cost after fee limit" />
            </div>
          </h3>
          <h3 className="headeing-switch-light form-group-flex">
            Finland
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Free limit count" />
              <input type="text" className="form-control" placeholder="Cost after fee limit" />
            </div>
          </h3>
          <h3 className="headeing-switch-light form-group-flex">
            United Kingdom
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Free limit count" />
              <input type="text" className="form-control" placeholder="Cost after fee limit" />
            </div>
          </h3>
          <h3 className="headeing-switch">Cost Per SMS</h3>
          <h3 className="headeing-switch-light form-group-flex">
            Sweden
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Enter cost" />
            </div>
          </h3>
          <h3 className="headeing-switch-light form-group-flex">
            Sweden
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Enter cost" />
            </div>
          </h3>
          <h3 className="headeing-switch-light form-group-flex">
            Sweden
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Enter cost" />
            </div>
          </h3>
        </div>
        <div className="details-right details-full-box">
          <h3 className="headeing-switch">Cost Per Business Certificate</h3>
          <h3 className="headeing-switch-light form-group-flex">
            Sweden
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Free limit count" />
              <input type="text" className="form-control" placeholder="Cost after fee limit" />
            </div>
          </h3>
          <h3 className="headeing-switch-light form-group-flex">
            Finland
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Free limit count" />
              <input type="text" className="form-control" placeholder="Cost after fee limit" />
            </div>
          </h3>
          <h3 className="headeing-switch-light form-group-flex">
            United Kingdom
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Free limit count" />
              <input type="text" className="form-control" placeholder="Cost after fee limit" />
            </div>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default PricesTab;
