import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import searchIcon from "../../assets/images/search.svg";
import closeIcon from "../../assets/images/icon_close_sms.svg";
import ReactPaginate from "react-paginate";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import { useDispatch } from "react-redux";
import { getSmsLogsList } from "../../Redux/Actions/SmsList";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./scss/smsList.scss";
import Select from 'react-select'
import ReactSelect from "../ReactSelect/ReactSelect";


const API_PAGE_START_INDEX = 1;
const START_DATE = moment(new Date()).format("DD/MM/YYYY");
const END_DATE = moment(new Date()).format("DD/MM/YYYY");

const SmsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedDateRange, setSelectedDateRange] = useState(
    `${START_DATE}-${END_DATE}`
  );
  const [organizationId, setOrganizationId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchData, setSearchData] = useState("");
  const [smsLogsData, setSmsLogsData] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);
  const [graphDataNames, setGraphDataNames] = useState([]);
  const [graphDataValues, setGraphDataValues] = useState([]);
  const [pageData, setPageData] = useState({
    current_page: 1,
    total_pages: "",
    total_count: "",
    next_page: "",
    prev_page: "",
  });
  const [orgOptions, setOrgOptions] = useState()
  const [selectedPage, setSelectedPage] = useState(API_PAGE_START_INDEX);
  const [query, setQuery] = useState("");
  useEffect(() => {
    fetchSmsLogsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, selectedPage]);

  const fetchSmsLogsList = async () => {
    await dispatch(setLoaderSpinner(true));
    let params =
      "status=" +
      selectedStatus +
      "&organization_id=" +
      organizationId +
      "&sms_date_range=" +
      selectedDateRange +
      "&page=" +
      selectedPage +
      "&query=" +
      searchData;


    const { status, message, data } = await dispatch(getSmsLogsList(params));
    if (status) {
      if (data.sms_logs.length > 0) {
        setSmsLogsData(data.sms_logs);
      } else {
        setSmsLogsData([]);
      }
      // Set status
      if (data.status.length > 0) {
        let statusArr = [];
        data.status.forEach((item) => {
          if (item !== undefined && item !== null) {
            statusArr.push(item);
          }
        });
        setStatusList(statusArr);
      }
      // set organization
      if (data.organizations) {
        let organizationName = [];
        Object.entries(data.organizations).forEach(([key, value]) => {
          organizationName.push({
            name: value,
            id: key,
          });
        });
        setOrganizationList(organizationName);
      }
      // set Graph data
      if (data.graph_data) {
        let graphNames = Object.keys(data.graph_data);
        setGraphDataNames(graphNames);
        let graphValues = Object.values(data.graph_data);
        setGraphDataValues(graphValues);
      }
      // set pagination
      data.pagination_data && setPageData(data.pagination_data);
      await dispatch(setLoaderSpinner(false));
    } else {
      dispatch(setLoaderSpinner(false));
      if (message === "Unauthorize") {
        history.push("/login");
      }
    }
  };

  const selectHandler = (selected) => {
    setOrganizationId(selected.value)
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      paddingTop: '0px',
      paddingBottom: '0px',
      fontSize: '13px',
      backgroundColor: state.isSelected ? '#2e70d0' : 'white',
      color: state.isSelected ? 'white !important' : '#5B6A83',
      ':hover': {
        backgroundColor: '#2e70d0',
        color: '#fff',
      },
    }),
  };

  const handleDateRangePicker = (start, end) => {
    const startDateValue = moment(start._d).format("DD/MM/YYYY");
    const endDateValue = moment(end._d).format("DD/MM/YYYY");
    setSelectedDateRange(`${startDateValue}-${endDateValue}`);
  };

  const handleFilter = async () => {
    fetchSmsLogsList();
  };

  const searchHandler = (event) => {
    const inputData = event.target.value;
    setSearchData(inputData);
  };

  const closeSearch = () => {
    setSearchData("");
    setQuery("");
  };

  const selectPageHandler = (event) => {
    const choosenPage = event.selected + 1;
    setSelectedPage(choosenPage);
  };

  const options = {
    chart: {
      type: "column",
      style: {
        fontFamily: "SF Pro Display",
        color: "#8591A6",
      },
    },
    title: {
      text: "",
    },
    tooltip: {
      animation: false,
      backgroundColor: "#ffffff",
      style: {
        color: "#333333",
      },
    },
    accessibility: {
      enabled: false,
    },
    yAxis: {
      min: 0,
      gridLineDashStyle: "longdash",
      title: {
        text: "Total Counts",
        style: {
          fontSize: "13px",
          color: "#8591A6",
        },
      },
      labels: {
        enabled: true,
        style: {
          fontSize: "14px",
          color: "#8591A6",
        },
      },
    },
    xAxis: {
      categories: graphDataNames,
      title: {
        text: "Clients",
        style: {
          fontSize: "13px",
          color: "#8591A6",
        },
      },
      labels: {
        enabled: true,
        style: {
          fontSize: "14px",
          color: "#8591A6",
        },
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        pointWidth: 10,
        borderRadiusTopLeft: 5,
        borderRadiusTopRight: 5,
      },
      series: {
        minPointLength: 2,
      },
    },
    series: [
      {
        showInLegend: false,
        name: "",
        data: graphDataValues,
        color: "#3E85F9",
        pointWidth: 5,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  const renderTable = () => {
    return (
      <table className="sms-recent-log-table" id="sms-recent-log-table">
        <thead className="th-light">
          <tr>
            <th className="sms-th">Date</th>
            <th className="sms-th">Service</th>
            <th className="sms-th">From</th>
            <th className="sms-th">To</th>
            <th className="sms-th">#Segments</th>
            <th className="sms-th">Status</th>
            <th className="sms-th">Cert ID</th>
          </tr>
        </thead>
        <tbody>
          {smsLogsData &&
            smsLogsData.length > 0 &&
            smsLogsData.map((item) => {
              return (
                <tr key={item.id}>
                  <td className="sms-td td-date">
                    {item.sms_sent_at &&
                      moment(item.sms_sent_at).format("DD-MM-YYYY T h:mm:ss")}
                  </td>
                  <td className="sms-td" value="service">
                    Twilio
                  </td>
                  <td className="sms-td" value="from">
                    {item.organization_name}
                  </td>
                  <td className="sms-td" value="to">
                    {item.cellphone}
                  </td>
                  <td className="sms-td" value="segments">
                    {item.num_segments}
                  </td>
                  <td className="sms-td capitalize" value="status">
                    {item.status}
                  </td>
                  <td className="sms-td" value="cert-id">
                    {item.organization_request_id}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  return (
    <div className="page-container">
      <div className="sms-log-container">
        <h3>SMS Logs</h3>
        <div className="sms-header">
          <div className="sms-filter-container">
            <label>Status</label>
            <select
              className="select-filter-sms capitalize"
              onChange={(e) => setSelectedStatus(e.target.value)}
              value={selectedStatus}
            >
              <option value="">Select</option>
              {statusList &&
                statusList.length > 0 &&
                statusList.map((status) => {
                  return (
                    <option key={Math.random()} value={status}>
                      {status}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="sms-filter-container overflow-inherit">
            <label>Organization</label>
            {/* <Select
              className="react-select-custom"
              options={orgOptions}
              placeholder="Select"
              styles={customStyles}
              onChange={selectHandler}
            /> */}
            <ReactSelect
              orgList={organizationList}
              pageRender={"logs"}
              setOrganizationId={setOrganizationId}
            />
            {/* <select
              className="select-filter-sms capitalize"
              onChange={(e) => setOrganizationId(e.target.value)}
              value={organizationId}
            >
              <option value="">Select</option>
              {organizationList &&
                organizationList.length > 0 &&
                organizationList.map((organization) => {
                  return (
                    <option key={Math.random()} value={organization.id}>
                      {organization.name}
                    </option>
                  );
                })}
            </select> */}
          </div>
          <DateRangePicker
            initialSettings={{ locale: { format: "DD/MM/YYYY" } }}
            onCallback={handleDateRangePicker}
          >
            <input
              type="text"
              id="date-range-picker"
              className="sms-date-box"
            />
          </DateRangePicker>
          <div className="calendar-icon-sms">
            <FontAwesomeIcon icon={faCalendar} size="lg" color="#e8edfa" />{" "}
          </div>
          <button className="sms-filter-button" onClick={() => handleFilter()}>
            {" "}
            Apply{" "}
          </button>
        </div>
        <div className="sms-top-clients-container">
          <div className="sms-top-clients-chart-header">
            <h5 className="h5"> Top Clients</h5>
          </div>
          <div>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
            ></HighchartsReact>
          </div>
        </div>
        <div className="sms-recent-log-table-container sms-table-container-scroll-y">
          <div className="sms-recent-log-table-header">
            <h5 className="h5"> Recent Log</h5>
            <div className="sms-search-field">
              <input
                className="sms-search"
                placeholder="Enter cert id/ Phone number"
                autoComplete="off"
                type="text"
                value={searchData}
                onChange={searchHandler}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    setQuery(searchData);
                  }
                }}
              />
              {searchData.length === 0 ? (
                <img
                  src={searchIcon}
                  alt="search"
                  className="sms-search-icon"
                />
              ) : (
                <img
                  src={closeIcon}
                  alt="close"
                  onClick={closeSearch}
                  className="sms-close-icon"
                />
              )}
            </div>
          </div>
          {renderTable()}
          {pageData.total_pages > 1 && (
            <div className="sms-pagination-box">
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                breakLabel={"..."}
                pageCount={Math.ceil(pageData.total_pages)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={selectPageHandler}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SmsList;
