import React from "react";
import { left } from "@popperjs/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const ProvidersChart = ({ services = [] }) => {
  let data = [];
  let colors = ["#43BF58", "#9a76b8", "#3e85f9", "#e8c30c", "#cfa83e", "#e86b0c", "#000000"];

  services.map((service, index) => {
    let info = {
      name: service.name,
      y: service.value,
      color: colors[index],
    }
    if (service.value > 0) {
      data.push(info);
    }
  })

  const options = {
    chart: {
      type: "pie",
      height: 300,
      style: {
        fontFamily: "SF Pro Display",
      },
    },
    title: {
      text: "Providers - Monthly",
      align: left,
      style: {
        fontWeight: 500,
        color: "#596980",
      },
    },
    accessibility: {
      enabled: false,
      point: {
        valueSuffix: "%"
      }
    },
    tooltip: {
      animation: false,
      backgroundColor: "#ffffff",
      style: {
        color: "#333333",
      },
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: "Total counts",
      },
    },
    xAxis: {
      categories: "months",
      title: {
        text: "Test",
      },
      crosshair: true,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b> : {point.percentage: .2f} %"
        }
      },
    },
    legend: {
      layout: 'horizontal',
      verticalAlign: 'bottom'
    },
    series: [
      {
        name: "monthly",
        data: data,
        showInLegend: true,
        borderRadiusTopLeft: "20px",
        borderRadiusTopRight: "20px",
      },
    ],
  };
  return (
    <div className="ld-main-data-container">
      <div className="ld-requests-container">
        <div className="ld-request-chart-container ">
          <div className="ld-request-chart yearGraph">
            <HighchartsReact
              containerProps={{ style: { height: "280px" } }}
              highcharts={Highcharts}
              options={options}
            ></HighchartsReact>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProvidersChart;