import React, { useEffect, useState } from "react";
import './demoAggregation.css'
import NewFlash from '../FlashMessage/NewFlash';
import { getDemoData, generateAccessCode, sendAccessCode } from '../../Redux/Actions/DemoAggregation';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setdisplayFlash } from '../../Utils/Common';
import menuArrow from '../../assets/images/icon_arrow_down.png';
import AccessCodePopup from "./AccessCodePopup/AccessCodePopup";
import DemoAccessTable from "./DemoAccessCodes/DemoAccessTable";
import DemoAccessForm from "./DemoAccessCodes/DemoAccessForm";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";

const initialAccessCode = { email: '', code: '', phone: '' }
const DemoAggregation = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [demoAccess, setDemoAccess] = useState([]);
	const [generateCode, setGenerateCode] = useState('');
	const [accessCodeData, setAccessCodeData] = useState(initialAccessCode)
	const [countryCode, setCountryCode] = useState('+46')
	const [flashMessage, setFlashMessage] = useState(false);
	const [flashData, setFlashData] = useState({ iconImage: "", firstMsg: "", secondMsg: "" })
	const [show, setShow] = useState(false);
	const [showApiDoc, setShowApiDoc] = useState(true);
	const [showDemoAgg, setShowDemoAgg] = useState(false);
	const [activeArrow, setActiveArrow] = useState(true);
	const [count, setCount] = useState(0);
	const [showPopup, setShowPopup] = useState(false);
	const [updateTable, setUpdateTable] = useState(false);

	useEffect(() => {
		setUpdateTable(false);
		fetchDemoAggregation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateTable]);

	const fetchDemoAggregation = async () => {
		await dispatch(setLoaderSpinner(true))
		let data = ''
		const accessData = await dispatch(getDemoData(data))
		if (accessData.status) {
			setDemoAccess(accessData.data.reverse());
		}
		await dispatch(setLoaderSpinner(false))
	}

	const closeFlash = () => {
		setdisplayFlash(false);
		setFlashMessage(false);
	}

	const generateDemoAggregation = async () => {
		await dispatch(setLoaderSpinner(true))
		let data = ''
		const generateData = await dispatch(generateAccessCode(data));
		if (generateData.status) {
			setCount(count + 1)
			setGenerateCode(generateData.data.access_code);
			setAccessCodeData((prevState) => ({
				...prevState,
				code: generateData.data.access_code
			}))
		}
		await dispatch(setLoaderSpinner(false))
	}
	const generateNewAccessCode = () => {
		if (count === 0) {
			generateDemoAggregation()
		}
		else {
			setShowPopup(true)
		}
	}
	const sendDemoAggregation = async () => {
		await dispatch(setLoaderSpinner(true))
		let data = accessCodeData
		if (data.phone.length > 1) {
			data.phone = countryCode + data.phone
		}
		const sendData = await dispatch(sendAccessCode(data))
		if (sendData.status) {
			setCount(0)
			setAccessCodeData(initialAccessCode)
			setCountryCode('+46')
			setGenerateCode(''); //emty input efter send clicked
			setFlashMessage(true);
			setFlashData(prevState => ({
				...prevState,
				iconImage: "tick",
				firstMsg: sendData.data.status,
				secondMsg: sendData.data.message
			}))
			setUpdateTable(true)
		} else {
			if (sendData.message === "Unauthorize") {
				history.push("/login")
			} else {
				setFlashMessage(true);
				setFlashData(prevState => ({
					...prevState,
					iconImage: "",
					firstMsg: "Error",
					secondMsg: sendData.message
				}))
			}
		}
		await dispatch(setLoaderSpinner(false))
	}

	const handleChange = (e) => {
		setAccessCodeData((prevState) => ({ //setFormValues
			...prevState,
			[e.target.name]: e.target.value
		}))
	}

	const handleChangeCountryCode = (e) => {
		setCountryCode((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value
		}))
	}

	const handleShow = () => {
		show ? setShow(false) : setShow(true);
	}

	const showVistaApiDoc = () => {
		setShowApiDoc(true);
		setShowDemoAgg(false);
		setShow(false);
		const arrowIcon = document.querySelector(".menu-icon");
		arrowIcon.style.transform = "none";
	}

	const showDemoAggregation = () => {
		setShowApiDoc(!showApiDoc);
		setShowDemoAgg(true);
		setShow(false);
		const arrowIcon = document.querySelector(".menu-icon");
		arrowIcon.style.transform = "none";
	}

	const toggleMenuArrow = () => {
		if (activeArrow) {
			const arrowIcon = document.querySelector(".menu-icon");
			arrowIcon.style.transform = "rotate(-180deg) scaleX(-1)";
			//arrowIcon.style.paddingLeft = "10px";
			setActiveArrow(!activeArrow);
		} else {
			const arrowIcon = document.querySelector(".menu-icon");
			arrowIcon.style.transform = "none";
		}
		setActiveArrow(!activeArrow);
	};

	return (
		<div className="demo-aggregation-container">
			{flashMessage &&
				<div className={`alert-popup alert-success-bar ${flashMessage ? setTimeout(() => setFlashMessage(false), 3000) & 'alert-shown' : 'alert-hidden'}`}>
					<NewFlash
						data={flashData}
						closeFlash={() => closeFlash()}
					/>
				</div>
			}

			<div className="lower-header-access-codes">
				{showApiDoc ? (
					<h3>Demo Aggregation</h3>
				) : (<h3>Vista - API Documentation</h3>)}
			</div>
			<div className="demo-wrapper">
				{showPopup && <AccessCodePopup generateCodeAgain={generateDemoAggregation} setShowPopup={setShowPopup} count={count} setCount={setCount} />}
				<DemoAccessForm
					generateNewAccessCode={generateNewAccessCode}
					generateCode={generateCode}
					accessCodeData={accessCodeData}
					handleChange={handleChange}
					handleChangeCountryCode={handleChangeCountryCode}
					sendDemoAggregation={sendDemoAggregation}
					countryCode={countryCode} />
				< DemoAccessTable demoAccess={demoAccess} />
			</div>
		</div >
	)
}

export default DemoAggregation;