import React, { useState, useEffect } from "react";
import "./Scss/statistics.scss";
import totalrequests from "../../assets/images/total-requests.svg";
import acceptedrequests from "../../assets/images/accepted-requests.svg";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { left } from "@popperjs/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getStatisticsData,
  getStatisticsFilter,
} from "../../Redux/Actions/Statistics";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import Select from "react-select"
import ReactSelect from "../ReactSelect/ReactSelect";

const removeUnderlineSupplier = (suppliers) => {
  return suppliers
    .replace("open_payments", "Open Payments")
    .replace("enable_banking", "Enable Banking ");
};
const removeUnderlineProduct = (productTypes) => {
  return productTypes
    .replace("manual_request", "Manual request")
    .replace("account_verification", "Account verification");
};
const removeUnderlineSource = (sourceTypes) => {
  return sourceTypes
    .replace("manual_request", "Manual request")
    .replace("generic_link", "Generic link");
};

function Statistics() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [switchColor, setSwitchColor] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [selectedMarket, setSelectedMarket] = useState("");
  const [selectedCertificate, setSelectedCertificate] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedSending, setSelectedSending] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState("");

  const [totalRequest, setTotalRequest] = useState(0);
  const [acceptedRequest, setAcceptedRequest] = useState(0);
  const [statisticsAccepted, setStatisticsAccepted] = useState({});
  const [statisticsTotal, setStatisticsTotal] = useState({});

  const [suppliers, setSuppliers] = useState({});
  const [markets, setMarkets] = useState([]);
  const [certificateTypes, setCertificateTypes] = useState({});
  const [productTypes, setProductTypes] = useState({});
  const [sourceTypes, setSourceTypes] = useState({});
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    fetchStatisticsFilter();
    fetchStatisticsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMarket]);

  const fetchStatisticsData = async () => {
    const params =
      "?market_id=" +
      selectedMarket +
      "&customer_id=" +
      selectedCustomer +
      "&certificate_type=" +
      selectedCertificate +
      "&product_type=" +
      selectedProduct +
      "&source_type=" +
      selectedSource +
      "&supplier_type=" +
      selectedSupplier +
      "&sent_by=" +
      selectedSending +
      "&statistics_date_range=" +
      selectedDateRange;

    await dispatch(setLoaderSpinner(true));
    const { status, data } = await dispatch(getStatisticsData(params));
    const {
      total_request_count,
      accepted_request_count,
      accepted_perday_count,
      total_perday_count,
    } = data;
    setTotalRequest(total_request_count);
    setAcceptedRequest(accepted_request_count);
    setStatisticsAccepted(accepted_perday_count);
    setStatisticsTotal(total_perday_count);
    if (!status) {
      history.push("/login");
    }
    await dispatch(setLoaderSpinner(false));
  };

  const fetchStatisticsFilter = async () => {
    const params = "?market_id=" + selectedMarket;
    await dispatch(setLoaderSpinner(true));
    const { data } = await dispatch(getStatisticsFilter(params));
    const {
      suppliers,
      markets,
      certificate_types,
      product_types,
      source_types,
      customers,
    } = data;
    setSuppliers(suppliers);
    setMarkets(markets);
    setCertificateTypes(certificate_types);
    setProductTypes(product_types);
    setSourceTypes(source_types);
    setCustomers(customers);
    await dispatch(setLoaderSpinner(false));
  };

  const displayedTotalGraphDates = Object.keys(statisticsTotal);
  const displayedSuppliers = Object.values(suppliers);
  const displayedCertificateTypes = Object.values(certificateTypes);
  const displayedProductTypes = Object.values(productTypes);
  const displayedSourceTypes = Object.keys(sourceTypes);

  const ShowSetting = () => {
    show ? setShow(false) : setShow(true);
  };
  const ColorSwitch = () => {
    switchColor ? setSwitchColor(false) : setSwitchColor(true);
  };

  const ButtonClick = () => {
    ShowSetting();
    ColorSwitch();
  };

  const handleSubmit = (event) => {
    fetchStatisticsData();
  };

  const displayedCustomers = customers.map((customer) => {
    return { id: customer[0], name: customer[1] };
  });


  const formGraphTotalDates = displayedTotalGraphDates.map((displayedDate) => {
    return moment(displayedDate).format("MMM DD");
  });

  const handleCallback = (start, end, label) => {
    const startDateValue = moment(start._d).format("DD/MM/YYYY");
    const endDateValue = moment(end._d).format("DD/MM/YYYY");
    setSelectedDateRange(`${startDateValue}-${endDateValue}`);
  };

  function getStartDate(d) {
    return moment(new Date(d.setMonth(d.getMonth() - 1))).format("DD/MM/YYYY");
  }

  function getEndDate(d) {
    return moment(d).format("DD/MM/YYYY");
  }

  const options = {
    chart: {
      type: "column",
      style: {
        fontFamily: "SF Pro Display",
        color: "#8591A6",
      },
    },
    title: {
      text: "Accepted Requests",
      align: left,
      margin: 40,
      style: {
        fontWeight: 500,
        color: "#596980",
      },
    },
    tooltip: {
      animation: false,
      backgroundColor: "#ffffff",
      style: {
        color: "#333333",
      },
    },
    accessibility: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        pointWidth: 10,
      },
      series: {
        minPointLength: 2,
      },
    },
    colors: ["#3e85f9", "#43BF58"],
    xAxis: {
      categories: formGraphTotalDates,
      title: {
        text: "Days",
      },
      crosshair: true,
    },
    yAxis: {
      // type: 'logarithmic',
      min: 0,
      title: {
        text: "Total counts",
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Total Requests",
        data: Object.values(statisticsTotal),
        borderRadiusTopLeft: "20px",
        borderRadiusTopRight: "20px",
      },
      {
        name: "Accepted Requests",
        data: Object.values(statisticsAccepted),
        borderRadiusTopLeft: "20px",
        borderRadiusTopRight: "20px",
      },
    ],
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      paddingTop: '0px',
      paddingBottom: '0px',
      fontSize: '13px',
      backgroundColor: state.isSelected ? '#2e70d0' : 'white',
      color: state.isSelected ? 'white !important' : '#5B6A83',
      ':hover': {
        backgroundColor: '#2e70d0',
        color: '#fff',
      },
    }),
  };

  const selectHandler = (selected) => {
    setSelectedCustomer(selected.value)
  }

  function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  return (
    <div className="statistics-container">
      <div className="lower-header">
        <h3>Requests</h3>
        <div className="flex-header">
          <button
            onClick={ButtonClick}
            className={switchColor ? "clicked-filter-button" : "filter-button"}
            type="button"
          >
            Filters
          </button>
          <DateRangePicker
            onCallback={handleCallback}
            initialSettings={{
              startDate: getStartDate(new Date()),
              endDate: getEndDate(new Date()),
              locale: { format: "DD/MM/YYYY" },
            }}
            onApply={handleSubmit}
          >
            <input
              type="text"
              id="date-range-picker"
              className="date-form-box"
            />
          </DateRangePicker>
          <div className="calendar-icon-statistics">
            <FontAwesomeIcon icon={faCalendar} size="1x" color="#e8edfa" />
          </div>
        </div>
      </div>
      {show ? (
        <div className="filters-box">
          <div className="each-fliter-container">
            <label>Supplier</label>
            <select
              className="select-filter capitalize"
              onChange={(e) => setSelectedSupplier(e.target.value)}
            >
              <option value="">Select</option>
              {suppliers &&
                displayedSuppliers.map((supplier) => (
                  <option
                    label={removeUnderlineSupplier(supplier)}
                    key={supplier}
                    value={supplier}
                  >
                    {supplier}
                  </option>
                ))}
            </select>
          </div>
          <div className="each-fliter-container">
            <label>Market</label>
            <select
              className="select-filter capitalize"
              onChange={(e) => setSelectedMarket(e.target.value)}
            >
              <option value="">Select</option>
              {markets &&
                markets.map((market) => (
                  <option key={market.id} value={market.id}>
                    {market.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="each-fliter-container">
            <label>Certificate type</label>
            <select
              className="select-filter capitalize"
              onChange={(e) => setSelectedCertificate(e.target.value)}
            >
              <option value="">Select</option>
              {certificateTypes &&
                displayedCertificateTypes.map((certificate) => (
                  <option key={certificate} value={certificate}>
                    {certificate === "customer" ? "Consumer" : certificate}
                  </option>
                ))}
            </select>
          </div>
          <div className="each-fliter-container">
            <label>Product</label>
            <select
              className="select-filter capitalize"
              onChange={(e) => setSelectedProduct(e.target.value)}
            >
              <option value="">Select</option>
              {productTypes &&
                displayedProductTypes.map((product) => (
                  <option
                    label={removeUnderlineProduct(product)}
                    key={product}
                    value={product}
                  >
                    {product}
                  </option>
                ))}
            </select>
          </div>
          <div className="each-fliter-container">
            <label>Source</label>
            <select
              className="select-filter capitalize"
              onChange={(e) => setSelectedSource(e.target.value)}
            >
              <option value="">Select</option>
              {sourceTypes &&
                displayedSourceTypes.map((source) => (
                  <option
                    label={removeUnderlineSource(source)}
                    key={source}
                    value={source}
                  >
                    {source}
                  </option>
                ))}
            </select>
          </div>
          <div className="each-fliter-container">
            <label>SMS/Email</label>
            <select
              className="select-filter capitalize"
              onChange={(e) => setSelectedSending(e.target.value)}
            >
              <option value="">Select</option>
              <option key="sms" value="sms">
                SMS
              </option>
              <option key="email" value="email">
                Email
              </option>
            </select>
          </div>
          <div className="each-fliter-container overflow-inherit">
            <label>Costumers</label>
            <ReactSelect
              orgList={customers}
              pageRender={"customers"}
              setSelectedCustomer={setSelectedCustomer}
            />
          </div>
          <div className="each-fliter-container">
            <button className="apply-filter-button" onClick={handleSubmit}>
              Apply
            </button>
          </div>
        </div>
      ) : null}
      <div className="main-statistics-box">
        <div className="statistics-graf">
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          ></HighchartsReact>
        </div>
        <div className="requests-box">
          <div className="each-requests-box">
            <div>
              <img
                className="requests-icon"
                alt="requests"
                src={totalrequests}
              />
            </div>
            <div className="request-info">
              <p>Total Requests</p>
              <h4>{numberWithSpaces(totalRequest)}</h4>
            </div>
          </div>
          <div className="each-requests-box">
            <div>
              <img
                className="requests-icon"
                alt="requests"
                src={acceptedrequests}
              />
            </div>
            <div className="request-info">
              <p>Accepted Requests</p>
              <h4>{numberWithSpaces(acceptedRequest)}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statistics;
