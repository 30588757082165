import React, { useEffect, useState } from "react";
import { left } from "@popperjs/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const CustomersYearlyGraph = ({ activeCustomer = {}, activeCustomersLastYear = {} }) => {
  const activeCustomersCurrentYearKeys = Object.keys(activeCustomer);
  const activeCustomersCurrentYearValues = Object.values(activeCustomer);
  const activeCustomersLastYearVlues = Object.values(activeCustomersLastYear);

  const d = new Date();
  let thisYear = d.getFullYear();
  let lastYear = (d.getFullYear() - 1);

  const initialOptions = {
    chart: {
      type: "column",
      height: 300,
      style: {
        fontFamily: "SF Pro Display",
      },
    },
    title: {
      text: "Active customers current year vs active customers last year",
      align: left,
      margin: 20,
      style: {
        fontWeight: 500,
        color: "#596980",
      },
    },
    accessibility: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        const yearToDisplay = this.series.name === thisYear ? thisYear : lastYear;
        return yearToDisplay + "<br/>" + "customers: " + Highcharts.numberFormat(this.y, 0, ' ');
      },
      animation: false,
      backgroundColor: "#ffffff",
      style: {
        color: "#333333",
      },
    },
    colors: ["#43BF58", "#3e85f9"],
    credits: {
      enabled: false,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total counts",
      },
    },
    xAxis: {
      categories: activeCustomersCurrentYearKeys || 0,
      title: {
        text: "Year",
      },
      crosshair: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        pointWidth: 10,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return Highcharts.numberFormat(this.y, 0, ' ');
          },
          rotation: -90,
          y: -20,
          color: "#596980",
          inside: false,
          crop: false,
        }
      },
      series: {
        minPointLength: 2,
      },
    },
    series: [
      {
        name: lastYear,
        data: activeCustomersLastYearVlues || 0,
        borderRadiusTopLeft: "20px",
        borderRadiusTopRight: "20px",
      },
      {
        name: thisYear,
        data: activeCustomersCurrentYearValues || 0,
        borderRadiusTopLeft: "20px",
        borderRadiusTopRight: "20px",
      },
    ],
  };

  return (
    <div className="ld-main-data-container">
      <div className="ld-requests-container">
        <div className="ld-request-chart-container ">
          <div className="ld-request-chart yearGraph">
            <HighchartsReact
              containerProps={{ style: { height: "280px" } }}
              highcharts={Highcharts}
              options={initialOptions}
            ></HighchartsReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomersYearlyGraph;