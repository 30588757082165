import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState, useEffect } from "react";
import { left } from "@popperjs/core";

const borderRadius = require('highcharts-border-radius');
 borderRadius(Highcharts)

const CompareGraph = (props) => {
  //Adds months from current year to a separate array
  const currentMonths = [];
  props.currentYearAccepted.map((x) => {
    return currentMonths.push(x.month);
  });
  //Adds accepted requests from current year to array
  const currentYearAcceptedRequests = [];
  props.currentYearAccepted.map((x) => {
    return currentYearAcceptedRequests.push(x.accepted_requests);
  });
  //Adds accepted requests from comparing year to array
  const comparingYearAcceptedRequests = [];
  props.comparingYearAccepted.map((x) => {
    return comparingYearAcceptedRequests.push(x.accepted_requests);
  });
  //Creates an object with data that will be displayed in Highcharts
  const dataObj = {
    currentYearAccepted: currentYearAcceptedRequests,
    comparingYearAccepted: comparingYearAcceptedRequests,
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  };
  //Adds highcharts object to state called data
  const [options, setOptions] = useState({
    chart: {
      type: "spline",
      spacing: [20, 30, 10, 20],
      style: {
        fontFamily: "SF Pro Display",
        color: "#8591A6",
      },
    },
    tooltip: {
      formatter: function () {
        return "The value for <b>" + this.x + "</b> is <b>" + this.y + "</b>";
      },
    },
    title: {
         text: "Accepted Requests",
      align: left,
      margin: 20,
      style: {
        fontWeight: 500,
        color: "#596980",
      },
    },
    legend: {
      itemStyle: {
        fontFamily: "SF Pro Display",
        color: "#666666",
        fontWeight: "regular",
      },
    },
    tooltip: {
      animation: false,
      backgroundColor: "#ffffff",
      style: {
        color: "#333333",
      },
    },
    accessibility: {
      enabled: false,
    },
    data: {
      enablePolling: true,
      dataRefreshRate: 10,
    },
    plotOptions: {
      column: {
        borderWidth: 0,
      },
      line: {
        dashStyle: "LongDash",
      },
      series: {
        minPointLength: 2,
        groupPadding: 0.4,
        pointPadding: 0.2,
      },
    },
    colors: ["#3e85f9", "#43BF58"],
    xAxis: {
      categories: dataObj.months,
      lineWidth: 0,
      labels: {
        style: {
          color: "#666666",
        },
      },
    },
    yAxis: {
      min: 0,
      offset: -10,
      title: {
        text: "Total counts",
        x: -10,
        style: {
          color: "#666666",
        },
      },
      labels: {
        style: {
          color: "#666666",
        },
      },
      gridLineColor: "#CFD7E5",
      gridLineWidth: 0.5,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: props.comparingYear,
        data: dataObj.comparingYearAccepted,
        borderRadiusTopLeft: "10px",
        borderRadiusTopRight: "10px",
        dashStyle: "longdash",
        tooltip: {
          headerFormat:
            "<p style='color: blue'>{point.key}" +
            " " +
            props.comparingYear +
            "</p>",
        },
        categories: "test",
      },
      {
        name: "Current year",
        data: dataObj.currentYearAccepted,
        borderRadiusTopLeft: "10px",
        borderRadiusTopRight: "10px",
        marker: {
          symbol: "circle",
        },
        tooltip: {
          headerFormat:
            "<span>{point.key} " + props.currentYear + "<br></br></span>",
          pointFormat:
            '<span class="highcharts-tooltip-live-data">{point.y}</span>',
        },
      },
    ],
  });
  //Updates highcharts graph when the state of "props.comparingYearAccepted" is updated from filters
  useEffect(() => {
    setOptions({
      series: [
        {
          name: props.comparingYear,
          data: dataObj.comparingYearAccepted,
          borderRadiusTopLeft: "10px",
          borderRadiusTopRight: "10px",
          tooltip: {
            textAlign: "center",
            useHTML: true,
            headerFormat:
              "<span>{point.key} " + props.comparingYear + "<br></br></span>",
            pointFormat:
              '<span class="highcharts-tooltip-live-data">{point.y}</span>',
          },
        },
        {
          name: "Current year",
          data: dataObj.currentYearAccepted,
          borderRadiusTopLeft: "10px",
          borderRadiusTopRight: "10px",
          marker: {
            symbol: "circle",
          },
          tooltip: {
            headerFormat:
              "<span>{point.key} " + props.currentYear + "<br></br></span>",
            pointFormat:
              '<span class="highcharts-tooltip-live-data">{point.y}</span>',
          },
        },
      ],
    });
  }, [props.comparingYearAccepted]);

  return (
    <section className="live-data-compare-graph-section">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        allowChartUpdate={true}
        updateArgs={[true, true, true]}
      ></HighchartsReact>
    </section>
  );
};

export default CompareGraph;