import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import "./editOrganization.scss";
import arrow from '../../assets/images/greater_than2x.png';
import calendarIcon from '../../assets/images/icon_calander.png';
import uploadIcon from '../../assets/images/icon_upload.png';
import searchIcon from '../../assets/images/search_icon.svg';
import { updateOrganization, getOrganizationById, getOrganizationBanks, getBankDetails, getBankCustomMessage, updateBankCustomMessage, getOrganizationAccountList, getServiceProviders, removeServiceProvider, setBank } from '../../Redux/Actions/OrganizationManagement';
import NewFlash from '../FlashMessage/NewFlash';
import moment from "moment";
import _, { set, slice } from "lodash"
// LIBRARIES
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EditModal from '../EditModal/EditModal';
import EditModalBank from "../EditModalBank/editModalBank";

import { setdisplayFlash, getdisplayFlash } from '../../Utils/Common';
import FlashMessage from '../../Components/FlashMessage/FlashMessage';
import { setLoaderSpinner } from '../../Redux/Actions/LoaderSpinner';

import { getAllCountry } from "../../Redux/Actions/Countries";
import OptionOrgInputForm from "./OptionOrgInput/OptionOrgInputForm";
import ProductTab from "./ProductTab/ProductTab";
import V4CustomizationTab from "./V4CustomizationTab/V4CustomizationTab";
import BankListTab from "./BankListTab/BankListTab";
import AgreementTab from "./AgreementTab/AgreementTab";
import CertificateTab from "./CertificateTab/CertificateTab";
import OptionTab from "./OptionTab/OptionTab";
import LanguageTab from "./LanguageTab/LanguageTab";
import PricesTab from "./PriceTab/PriceTab";
import EditOrgFormSelect from "./EditOrgFormSelect/EditOrgFormSelect";
import NotificationTab from "./Notification/Notification";
import DeleteModal from "../DeleteModal/DeleteModal";
import { getBankPriorityUpdate } from "../../Redux/Actions/OrganizationProvider"
import ReactSelect from "../ReactSelect/ReactSelect";
import { getConfigurationSettings } from "../../Redux/Actions/ConfigurationSettings";

const languages = [
  { name: 'English', code: 'en' },
  { name: 'Swedish', code: 'sw' },
  { name: 'Finnish', code: 'fi' },
  { name: 'Danish', code: 'da' },
  { name: 'Norwegian', code: 'nn' }
]

const accessOptions = [{ name: "Portal Only", id: "client" }, { name: "Portal+API", id: "dev" }, { name: "API only", id: "api" }]

const customizedResponseOptions = [{ name: "Raw Data", id: "false" }, { name: "Certificate Data", id: "true" }]

const statusOptions = [{ name: "Active", id: "true" }, { name: "Inactive", id: "false" }]
let options = [{ value: "one", label: "one" },
{ value: "two", label: "two" },
{ value: "three", label: "three" }
]

const initialClientDetails = {
  agreement_end_date: new Date(),
  agreement_start_date: new Date(),
  bank_connection: 'default',
  billing_email: '',
  business_rules_enabled: false,
  ip_restricted_login: false,
  company_contact: '',
  corporate_enabled: false,
  corporate_market_ids: [],
  country_id: 1,
  customer_enabled: false,
  customized_response: false,
  customized_result_view: false,
  customized_view: false,
  email: '',
  email_template_enabled: false,
  end_user_display_name: '',
  epost_for_request_response: '',
  first_name: '',
  homepage_address: '',
  last_name: '',
  market_ids: [],
  name: '',
  organization_number: '',
  organization_type: '',
  partial_name: '',
  phone: '',
  phone_country_id: 1,
  sms_notification: false,
  sms_reminder: false,
  sms_sender_name: '',
  sms_template_enabled: false,
  password: '',
  terms: '1',
  agreement: {},
  language: [],
  cancellation_period: '',
  contact_email: '',
  contact_phone: '',
  account_type: 'main_account',
  parent_organization_id: null,
  is_parent_org: false,
  response_for: 'kreditz_certificate',
  organization: {
    notification_settings_attributes: [
      { id: 1, email_for_request_response: '', type: 'manual_request', status: false },
      { id: 3, email_for_request_response: '', type: 'api', status: false },
      { id: 2, email_for_request_response: '', type: 'iframe', status: false },
    ]
  },
  shared_organization_ids: [],
  remove_ids: [],

  setting: {
    enable_primary_type_customization: false,
    certificate_delete_ability: {
      employee: false
    },
    certificate_column_customizable: false,
    customized_consent: false,
    refetch_transactions_type: 'new',
    iframe_bank_connection: 'default',
    api_bank_connection: 'default',
    sub_account_readonly: false,
    delivery_via: 'webhook',
    analyzing_markets: [],
    default_locale: '',
    continuous_access_enabled: false,
    early_response: false,
    enable_cerificate_sharing: false,
    generic_bank_connection: 'default',
    two_factor_enabled: false,
    custom_payload_for: '',
    custom_pdf_for: '',
    early_response_for: '',
    fetch_data_for_months: '',
    session_storage_for: '',
    skip_used_banks: false,
    used_bank_skip_time: 15,
    bank_error: false,
    error_response: false,
    customized_flow: false,
    accuracy_check_for_analyzing: false,
    hide_request_log_icons: false,
    download_request_log_csv: false,
    credit_decision_editable: false,
    certificate_tabs: {
      overview: false,
      account_information: false,
      sources: false,
      income_tab: false,
      expense_tab: false,
      assets: false,
      debts: false,
      business_rules: false,
      credit_decision_application: false,
      documents: false,
      transactions: false,
      credit_decision_surveillance: false
    },
    corporate_tabs: {
      overview: false,
      sources: false,
      cashflow: false,
      business_rules: false,
      credit_score: false,
      documents: false,
      transactions: false
    },
    webhook_events: {
      BankDataFetched: false,
      BankDataProcessed: false,
      AccountsDataPrepared: false,
      AccountsData: false,
      ConsentGiven: false,
      ProcessCancelled: false,
      ErrorOccurred: false,
      api_back_button: false,
      iframe_back_button: false,
      manual_back_button: false,
      api_cancel_button: false,
      manual_cancel_button: false,
      iframe_cancel_button: false,
      error_response: false,
      use_old_http_status_on_v4: false
    },
    enable_back_button: {
      api_back_button: false,
      iframe_back_button: false,
      manual_back_button: false,
      api_cancel_button: false,
      manual_cancel_button: false,
      iframe_cancel_button: false
    }
  },
  certificate: {
    consumer: {
      account_information: {
        balance_column: false,
        transaction_column: false,
      },
      income_tab: {
        income_graph: false,
        monthly_view: false,
        average_income: false,
        source_of_income: false,
        cashflow_net_graph: false,
        income_vs_spending: false,
        income_ratio_salary_burn_rate: false,
      },
      spending_tab: {
        monthly_view: false,
        spending_graph: false,
        debt_categories: false,
      },
      asset_tab: {
        assets_graph: false,
        monthly_savings_boxes: false,
        balance_before_salary_graph: false,
        total_assets_with_allocation_boxes: false,
      },
      debts_tab: {
        summary_of_debts: false,
        debts_categories_boxes: false,
        paidout_vs_repayments_graph: false,
        credit_institutions: false,
      },
      credit_decision_tab: {
        affordability_box: false,
        credit_scoring_box: false,
        overview_decision_box: false,
        credit_policy_rules_box: false,
      }
    },
    corporate: {
      source_tab: {
        bank_sources_box: false,
      },
      account_information: {
        balance_column: false,
        transaction_column: false
      },
      cashflow_tab: {
        average_inflow_boxes: false,
        average_outflow_boxes: false,
        cashflow_12_months_graph_or_accordion: false,
      },
      bussiness_rule_tab: {
        bussiness_rules_section: false,
      },
      credit_decision_tab: {
        cashflow_box: false,
        credit_scoring_box: false,
        overview_decision_box: false,
        credit_policy_rules_box: false,
        category_expansion: false
      },
    },
  }
}

let countries = []

const attachData = { agreement_file_name: "", agreement_data: "" }
const agreement_dates = { agreement_start_date: new Date(), agreement_end_date: new Date() }
const bankParams = { type: 'private', country_id: 1, locale: 'en', service_id: 1 }
const initialElement = {}
let months = Array.from({ length: 15 }, (_, index) => index + 1);
months = months.map(item => [item, item])

const EditOrganization = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const BankList = useSelector(state => state.organization_management?.bank_list);
  const [activeTab, setActiveTab] = useState('products')
  const [clientDetails, setClientDetails] = useState(initialClientDetails)
  const [certificateConsumer, setCertificateConsumer] = useState(false)
  const [isAnalyzingMarkets, setAnalyzingMarkets] = useState(false)
  const [agreementData, setAgreementData] = useState(attachData)
  const [agreementDates, setAgreementDates] = useState(agreement_dates)

  // FLASH MESSAGE STATE
  const [flashMessage, setFlashMessage] = useState(false);
  const [flashData, setFlashData] = useState({ iconImage: "", firstMsg: "", secondMsg: "" })
  const [bankFilters, setBankFilters] = useState(bankParams)
  const [banks, setBanks] = useState([])
  const [bankSearch, setBankSearch] = useState('')
  const [customBankDetails, setCustomBankDetails] = useState([])
  const [foundElement, setFoundElement] = useState(initialElement);
  const [showMessage, setShowMessage] = useState(false)
  // const [bankCountries, setBankCountries] = useState(countries)
  const [bankFilterCountries, setBankFilterCountries] = useState([])
  const [webhookEvent, setWebhookEvent] = useState(false);

  let path = history.location && history.location.pathname && history.location.pathname.split('/')
  let organization_id = path && path[2]

  const [openEditOrg, setOpenEditOrg] = useState(false)
  const [editOrgName, setEidtOrgName] = useState('')
  const textConfirmation1 = ("Are you sure you want")
  const textConfirmation2 = ("to update organization");

  const [editFlashMessage, setEditFlashMessage] = useState(getdisplayFlash());
  const [editFlashData, setEditFlashData] = useState({ iconImage: "", firstMsg: "", secondMsg: "" })

  const [orgAccountList, setOrgAccountList] = useState([])
  const [orgResponseFor, setOrgResponseFor] = useState([])
  const [orgPartialName, setOrgPartialName] = useState([])
  const [orgCustomFor, setOrgCustomFor] = useState([])
  const [customPdfFor, setCustomPdfFor] = useState([])
  const [earlyResponseFor, setEarlyResponseFor] = useState([])
  const [creditDecisionVista, setCreditDecisionVista] = useState([])
  const [creditDecisionCorpVista, setCreditDecisionCorpVista] = useState([])
  const [country, setCountry] = useState([]);
  const [bankFilterCountriesCorporate, setBankFilterCountriesCorporate] = useState([])
  const [bankFiltersCorporate, setBankFiltersCorporate] = useState(bankParams)
  const [continuous_access_response_for_list, setContinuous_access_response_for_list] = useState()
  const [openEditBank, setOpenEditBank] = useState(false)
  const [openDeleteBank, setOpenDeleteBank] = useState(false)
  const [editBank, setEditBank] = useState()
  const [deleteBank, setDeleteBank] = useState()
  const [serviceProviders, setServiceProvicers] = useState([])
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [multiSelect, setMultiSelect] = useState([])
  const [organizationList, setOrganizationList] = useState([])
  let organizationListForSave = []
  const [sharedOrganizationSelect, setSharedOrganizationSelect] = useState([])
  const [configurationSettings, setConfigurationSetting] = useState([]);



  const editBankListHandler = async (bank) => {
    dispatch(setBank(bank))
    history.push({ pathname: '/organization_providers', state: bank })
    setOpenEditBank(true)
    setEditBank(bank)
  }

  const deleteBankListHandler = (bank) => {
    setOpenDeleteBank(true)
    setDeleteBank(bank)
  }

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = async (e) => {
    const copyListItems = [...banks];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setBanks(copyListItems);
    let bank = copyListItems.map((item, index) => ({ organization_provider_id: item.organization_provider_id, index: index }))
    let bankData = { bank }
    const priorityResponse = await dispatch(getBankPriorityUpdate(bankData))
    const { status, message } = priorityResponse;
    if (status) {
      openFlash("tick", "Done!", message)
    }
  };
  const [orgOptions, setOrgOptions] = useState()
  const [selectedOrgSelect, setSelectedOrgSelect] = useState()
  let selectedParentOrgId;
  let orgArr;


  const fetchBankList = async () => {
    setFoundElement(initialElement)
    setShowMessage(false)
    let query =
      '?type=' + bankFilters.type +
      '&country_id=' + bankFilters.country_id +
      "&organization_id=" + organization_id +
      "&service_id=" + bankFilters.service_id


    const response = await dispatch(getOrganizationBanks(query))
    if (response.status) {
      setBanks(response.data)
    } else {
      setBanks([])
      openFlashError("", "Error", response.message)
    }
  }

  useEffect(() => {
    fetchBankList()
  }, [bankFilters])

  const fetchOrgAccountTypes = async () => {
    const response = await dispatch(getOrganizationAccountList())
    setOrgResponseFor(response.data.response_for_list)
    setContinuous_access_response_for_list(response.data.continuous_access_response_for_list)
    setOrgPartialName(response.data.partial_name_list)
    setOrgCustomFor(response.data.custom_payload_for_list)
    setCustomPdfFor(response.data.custom_pdf_for_list)
    setEarlyResponseFor(response.data.early_response_for_list)
    setCreditDecisionVista(response.data.credit_decision_vista_list)
    setCreditDecisionCorpVista(response.data.credit_decision_corporate_list)
    organizationListForSave = response.data.organizations_list
    let orgList = response.data.organizations_list.map(org => ({ "value": org.id, "label": `${org.name} (${org.id})` }))
    let newResult = orgList.filter(item => item.value != organization_id)
    setOrganizationList(newResult)
    if (response && response.data.organizations_list) {
      orgArr = response.data.organizations_list
      _.remove(orgArr, function (n) {
        return n.id === organization_id;
      })
      orgArr.sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
      });
      setOrgAccountList(orgArr)
    }
  }

  useEffect(() => {
    fetchOrgAccountTypes()
    setTimeout(() => {
      fetchOrganization()
    }, 2000)
    fetchCountries();
  }, []);


  const fetchCountries = async () => {
    await dispatch(setLoaderSpinner(true))
    const { data } = await dispatch(getAllCountry());
    const { countries_list } = data;
    countries = countries_list
    setCountry(countries_list);
    let dummyOptions = countries_list.map(item => ({ "value": item.id, "label": item.name }))
    setMultiSelect(dummyOptions)
  };


  const getServices = async () => {
    const response = await dispatch(getServiceProviders())
    setServiceProvicers(response.data)
  }


  const bankListHandler = () => {
    setActiveTab('banklist')
    fetchBankList()
    getServices()
  }


  const fetchOrganization = async () => {
    await dispatch(setLoaderSpinner(true))
    const response = await dispatch(getOrganizationById(organization_id))
    let sharedOrgDataArray = response.data.shared_organization_ids;
    let sharedIdsArr = []
    organizationListForSave.length > 0 && organizationListForSave.map(item => {
      sharedOrgDataArray.map(item2 => {
        if (item.id === item2) {
          sharedIdsArr.push(item)
        }
      })
    })
    let sharedIdsKeyValues = sharedIdsArr.map(item => ({ "value": item.id, "label": `${item.name} (${item.id})` }))
    setSharedOrganizationSelect(sharedIdsKeyValues)
    if (response.status) {
      let orgDetails = response.data
      selectedParentOrgId = orgDetails.parent_organization_id
      let newListOne = [{ "value": "", "label": "Select" }]
      let newList = orgArr.map(org => ({ "value": org.id, "label": `${org.name}` }))
      newListOne = [...newListOne, ...newList]
      setOrgOptions(newListOne)
      let result = newListOne.filter(item => item.value === selectedParentOrgId)
      setSelectedOrgSelect(result)
      orgDetails.setting.webhook_events = orgDetails && orgDetails.setting && orgDetails.setting.webhook_events ? orgDetails.setting.webhook_events : initialClientDetails.setting.webhook_events
      orgDetails.setting.fetch_data_for_months = orgDetails && orgDetails.setting && orgDetails.setting.fetch_data_for_months ? orgDetails.setting.fetch_data_for_months : ''
      orgDetails.setting.session_storage_for = orgDetails && orgDetails.setting && orgDetails.setting.session_storage_for ? orgDetails.setting.session_storage_for : ''
      orgDetails.certificate = initialClientDetails.certificate
      orgDetails.organization = orgDetails && orgDetails.organization ? orgDetails.organization : initialClientDetails.organization

      let certificateData = orgDetails.certificate_modulizations
      if (certificateData && certificateData.length > 0) {
        let consumerData = _.filter(certificateData, function (_cert) { return _cert.certificate_type === 'consumer' })
        if (consumerData && consumerData.length > 0) {
          let _consumerD = {
            overview: {
              assets: consumerData[0].overview && consumerData[0].overview.assets ? consumerData[0].overview.assets : false,
              business_rules: consumerData[0].overview && consumerData[0].overview.business_rules ? consumerData[0].overview.business_rules : false,
              debts: consumerData[0].overview && consumerData[0].overview.debts ? consumerData[0].overview.debts : false,
              primary_income: consumerData[0].overview && consumerData[0].overview.primary_income ? consumerData[0].overview.primary_income : false,
              sources: consumerData[0].overview && consumerData[0].overview.sources ? consumerData[0].overview.sources : false,
              spending: consumerData[0].overview && consumerData[0].overview.spending ? consumerData[0].overview.spending : false,
            },
            account_information: {
              balance_column: consumerData[0].account_information && consumerData[0].account_information.balance_column ? consumerData[0].account_information.balance_column : false,
              transaction_column: consumerData[0].account_information && consumerData[0].account_information.transaction_column ? consumerData[0].account_information.transaction_column : false,
            },

            income_tab: {
              income_graph: consumerData[0].income_tab && consumerData[0].income_tab.income_graph ? consumerData[0].income_tab.income_graph : false,
              monthly_view: consumerData[0].income_tab && consumerData[0].income_tab.monthly_view ? consumerData[0].income_tab.monthly_view : false,
              average_income: consumerData[0].income_tab && consumerData[0].income_tab.average_income ? consumerData[0].income_tab.average_income : false,
              source_of_income: consumerData[0].income_tab && consumerData[0].income_tab.source_of_income ? consumerData[0].income_tab.source_of_income : false,
              cashflow_net_graph: consumerData[0].income_tab && consumerData[0].income_tab.cashflow_net_graph ? consumerData[0].income_tab.cashflow_net_graph : false,
              income_vs_spending: consumerData[0].income_tab && consumerData[0].income_tab.income_vs_spending ? consumerData[0].income_tab.income_vs_spending : false,
              income_ratio: consumerData[0].income_tab && consumerData[0].income_tab.income_ratio ? consumerData[0].income_tab.income_ratio : false,
              salary_burn_rate: consumerData[0].income_tab && consumerData[0].income_tab.salary_burn_rate ? consumerData[0].income_tab.salary_burn_rate : false,
              category_expansion: consumerData[0].income_tab && consumerData[0].income_tab.category_expansion ? consumerData[0].income_tab.category_expansion : false,
              primary_income_transactions: consumerData[0].income_tab && consumerData[0].income_tab.primary_income_transactions ? consumerData[0].income_tab.primary_income_transactions : false,
              income_transactions: consumerData[0].income_tab && consumerData[0].income_tab.income_transactions ? consumerData[0].income_tab.income_transactions : false,
            },
            spending_tab: {
              monthly_view: consumerData[0].spending_tab && consumerData[0].spending_tab.monthly_view ? consumerData[0].spending_tab.monthly_view : false,
              spending_graph: consumerData[0].spending_tab && consumerData[0].spending_tab.spending_graph ? consumerData[0].spending_tab.spending_graph : false,
              category_expansion: consumerData[0].spending_tab && consumerData[0].spending_tab.category_expansion ? consumerData[0].spending_tab.category_expansion : false,
              highest_cost_last_month: consumerData[0].spending_tab && consumerData[0].spending_tab.highest_cost_last_month ? consumerData[0].spending_tab.highest_cost_last_month : false,
              spending_ratio: consumerData[0].spending_tab && consumerData[0].spending_tab.spending_ratio ? consumerData[0].spending_tab.spending_ratio : false,
              spending_categories_children: consumerData[0].spending_tab && consumerData[0].spending_tab.spending_categories_children ? consumerData[0].spending_tab.spending_categories_children : false,
              spending_categories_food_and_drinks: consumerData[0].spending_tab && consumerData[0].spending_tab.spending_categories_food_and_drinks ? consumerData[0].spending_tab.spending_categories_food_and_drinks : false,
              spending_categories_health_and_beauty: consumerData[0].spending_tab && consumerData[0].spending_tab.spending_categories_health_and_beauty ? consumerData[0].spending_tab.spending_categories_health_and_beauty : false,
              spending_categories_hobbies: consumerData[0].spending_tab && consumerData[0].spending_tab.spending_categories_hobbies ? consumerData[0].spending_tab.spending_categories_hobbies : false,
              spending_categories_housing_costs: consumerData[0].spending_tab && consumerData[0].spending_tab.spending_categories_housing_costs ? consumerData[0].spending_tab.spending_categories_housing_costs : false,
              spending_categories_loans: consumerData[0].spending_tab && consumerData[0].spending_tab.spending_categories_loans ? consumerData[0].spending_tab.spending_categories_loans : false,
              spending_categories_risk: consumerData[0].spending_tab && consumerData[0].spending_tab.spending_categories_risk ? consumerData[0].spending_tab.spending_categories_risk : false,
              spending_categories_savings: consumerData[0].spending_tab && consumerData[0].spending_tab.spending_categories_savings ? consumerData[0].spending_tab.spending_categories_savings : false,
              spending_categories_shopping: consumerData[0].spending_tab && consumerData[0].spending_tab.spending_categories_shopping ? consumerData[0].spending_tab.spending_categories_shopping : false,
              spending_categories_temporary_expenses: consumerData[0].spending_tab && consumerData[0].spending_tab.spending_categories_temporary_expenses ? consumerData[0].spending_tab.spending_categories_temporary_expenses : false,
              spending_categories_transfers: consumerData[0].spending_tab && consumerData[0].spending_tab.spending_categories_transfers ? consumerData[0].spending_tab.spending_categories_transfers : false,
              spending_categories_transportation: consumerData[0].spending_tab && consumerData[0].spending_tab.spending_categories_transportation ? consumerData[0].spending_tab.spending_categories_transportation : false,
            },
            asset_tab: {
              assets_graph: consumerData[0].asset_tab && consumerData[0].asset_tab.assets_graph ? consumerData[0].asset_tab.assets_graph : false,
              balance_before_salary_graph: consumerData[0].asset_tab && consumerData[0].asset_tab.balance_before_salary_graph ? consumerData[0].asset_tab.balance_before_salary_graph : false,
              total_assets: consumerData[0].asset_tab && consumerData[0].asset_tab.total_assets ? consumerData[0].asset_tab.total_assets : false,
              asset_allocation: consumerData[0].asset_tab && consumerData[0].asset_tab.asset_allocation ? consumerData[0].asset_tab.asset_allocation : false,
              asset_ratio: consumerData[0].asset_tab && consumerData[0].asset_tab.asset_ratio ? consumerData[0].asset_tab.asset_ratio : false,
              monthly_savings_ratio: consumerData[0].asset_tab && consumerData[0].asset_tab.monthly_savings_ratio ? consumerData[0].asset_tab.monthly_savings_ratio : false,
              monthly_savings: consumerData[0].asset_tab && consumerData[0].asset_tab.monthly_savings ? consumerData[0].asset_tab.monthly_savings : false,
            },
            debts_tab: {
              summary_of_debts: consumerData[0].debts_tab && consumerData[0].debts_tab.summary_of_debts ? consumerData[0].debts_tab.summary_of_debts : false,
              debts_categories_boxes: consumerData[0].debts_tab && consumerData[0].debts_tab.debts_categories_boxes ? consumerData[0].debts_tab.debts_categories_boxes : false,
              paidout_vs_repayments_graph: consumerData[0].debts_tab && consumerData[0].debts_tab.paidout_vs_repayments_graph ? consumerData[0].debts_tab.paidout_vs_repayments_graph : false,
              credit_institutions: consumerData[0].debts_tab && consumerData[0].debts_tab.credit_institutions ? consumerData[0].debts_tab.credit_institutions : false,
              debt_involvement: consumerData[0].debts_tab && consumerData[0].debts_tab.debt_involvement ? consumerData[0].debts_tab.debt_involvement : false,
              disposition_of_repayments: consumerData[0].debts_tab && consumerData[0].debts_tab.disposition_of_repayments ? consumerData[0].debts_tab.disposition_of_repayments : false,
              noteworthy_liabilities: consumerData[0].debts_tab && consumerData[0].debts_tab.noteworthy_liabilities ? consumerData[0].debts_tab.noteworthy_liabilities : false,
              paidout_vs_repayments_detailed: consumerData[0].debts_tab && consumerData[0].debts_tab.paidout_vs_repayments_detailed ? consumerData[0].debts_tab.paidout_vs_repayments_detailed : false,
              paidout_vs_repayments_accumulated: consumerData[0].debts_tab && consumerData[0].debts_tab.paidout_vs_repayments_accumulated ? consumerData[0].debts_tab.paidout_vs_repayments_accumulated : false,
            },
            credit_decision_tab: {
              affordability_box: consumerData[0].credit_decision_tab && consumerData[0].credit_decision_tab.affordability_box ? consumerData[0].credit_decision_tab.affordability_box : false,
              credit_scoring_box: consumerData[0].credit_decision_tab && consumerData[0].credit_decision_tab.credit_scoring_box ? consumerData[0].credit_decision_tab.credit_scoring_box : false,
              overview_decision_box: consumerData[0].credit_decision_tab && consumerData[0].credit_decision_tab.overview_decision_box ? consumerData[0].credit_decision_tab.overview_decision_box : false,
              credit_policy_rules_box: consumerData[0].credit_decision_tab && consumerData[0].credit_decision_tab.credit_policy_rules_box ? consumerData[0].credit_decision_tab.credit_policy_rules_box : false,
              category_expansion: consumerData[0].credit_decision_tab && consumerData[0].credit_decision_tab.category_expansion ? consumerData[0].credit_decision_tab.category_expansion : false,
            }
          }
          orgDetails.certificate.consumer = _consumerD
        }

        let corporateData = _.filter(certificateData, function (_cert) { return _cert.certificate_type === 'corporate' })
        if (corporateData && corporateData.length > 0) {
          let _corporateD = {
            overview: {
              cash_and_bank_balances: corporateData[0].overview && corporateData[0].overview.cash_and_bank_balances ? corporateData[0].overview.cash_and_bank_balances : false,
              cashflow: corporateData[0].overview && corporateData[0].overview.cashflow ? corporateData[0].overview.cashflow : false,
              largest_incoming_payments: corporateData[0].overview && corporateData[0].overview.largest_incoming_payments ? corporateData[0].overview.largest_incoming_payments : false,
              largest_outgoing_payments: corporateData[0].overview && corporateData[0].overview.largest_outgoing_payments ? corporateData[0].overview.largest_outgoing_payments : false,
              source_box: corporateData[0].overview && corporateData[0].overview.source_box ? corporateData[0].overview.source_box : false,
              sources: corporateData[0].overview && corporateData[0].overview.sources ? corporateData[0].overview.sources : false,
              tax_authority: corporateData[0].overview && corporateData[0].overview.tax_authority ? corporateData[0].overview.tax_authority : false,
            },
            source_tab: {
              bank_sources_box: corporateData[0].source_tab && corporateData[0].source_tab.bank_sources_box ? corporateData[0].source_tab.bank_sources_box : false,
              bank_accounts: corporateData[0].source_tab && corporateData[0].source_tab.bank_accounts ? corporateData[0].source_tab.bank_accounts : false,
            },
            account_information: {
              balance_column: corporateData[0].account_information && corporateData[0].account_information.balance_column ? corporateData[0].account_information.balance_column : false,
              transaction_column: corporateData[0].account_information && corporateData[0].account_information.transaction_column ? corporateData[0].account_information.transaction_column : false,
            },
            cashflow_tab: {
              average_inflow_boxes: corporateData[0].cashflow_tab && corporateData[0].cashflow_tab.average_inflow_boxes ? corporateData[0].cashflow_tab.average_inflow_boxes : false,
              average_outflow_boxes: corporateData[0].cashflow_tab && corporateData[0].cashflow_tab.average_outflow_boxes ? corporateData[0].cashflow_tab.average_outflow_boxes : false,
              cashflow_12_months_graph_or_accordion: corporateData[0].cashflow_tab && corporateData[0].cashflow_tab.cashflow_12_months_graph_or_accordion ? corporateData[0].cashflow_tab.cashflow_12_months_graph_or_accordion : false,
              category_expansion: corporateData[0].cashflow_tab && corporateData[0].cashflow_tab.category_expansion ? corporateData[0].cashflow_tab.category_expansion : false,
            },
            bussiness_rule_tab: {
              bussiness_rules_section: corporateData[0].bussiness_rule_tab && corporateData[0].bussiness_rule_tab.bussiness_rules_section ? corporateData[0].bussiness_rule_tab.bussiness_rules_section : false,
            },
            credit_decision_tab: {
              cashflow_box: corporateData[0].credit_decision_tab && corporateData[0].credit_decision_tab.cashflow_box ? corporateData[0].credit_decision_tab.cashflow_box : false,
              credit_scoring_box: corporateData[0].credit_decision_tab && corporateData[0].credit_decision_tab.credit_scoring_box ? corporateData[0].credit_decision_tab.credit_scoring_box : false,
              overview_decision_box: corporateData[0].credit_decision_tab && corporateData[0].credit_decision_tab.overview_decision_box ? corporateData[0].credit_decision_tab.overview_decision_box : false,
              credit_policy_rules_box: corporateData[0].credit_decision_tab && corporateData[0].credit_decision_tab.credit_policy_rules_box ? corporateData[0].credit_decision_tab.credit_policy_rules_box : false,
              category_expansion: corporateData[0].credit_decision_tab && corporateData[0].credit_decision_tab.category_expansion ? corporateData[0].credit_decision_tab.category_expansion : false,
            }
          }
          orgDetails.certificate.corporate = _corporateD
        }
      }
      setAgreementDates(prevState => ({
        ...prevState,
        agreement_start_date: orgDetails && orgDetails.agreement_start_date ? new Date(orgDetails.agreement_start_date) : new Date(),
        agreement_end_date: orgDetails && orgDetails.agreement_end_date ? new Date(orgDetails.agreement_end_date) : new Date()
      }))

      orgDetails.agreement_start_date = orgDetails && orgDetails.agreement_start_date ? moment(orgDetails.agreement_start_date).format('DD-MM-YYYY') : new Date()
      orgDetails.agreement_end_date = orgDetails && orgDetails.agreement_end_date ? moment(orgDetails.agreement_end_date).format('DD-MM-YYYY') : new Date()

      orgDetails.account_type = orgDetails.account_type || "main_account"
      if (orgDetails.market_ids && orgDetails.market_ids.length > 0) {
        let _bankCountry = _.filter(country.length > 0 ? country : countries, function (_bank) { return _.includes(orgDetails.market_ids, _bank.id.toString()) })
        if (_bankCountry && _bankCountry.length > 0) {
          setBankFilters(prevState => ({
            ...prevState,
            country_id: _bankCountry[0].id
          }))
        }
        setBankFilterCountries([..._bankCountry])
      }

      if (orgDetails.corporate_market_ids && orgDetails.corporate_market_ids.length > 0) {
        let _bankCountryCorporate = _.filter(country.length > 0 ? country : countries, function (_bank) { return _.includes(orgDetails.corporate_market_ids, _bank.id.toString()) })
        if (_bankCountryCorporate && _bankCountryCorporate.length > 0) {
          setBankFiltersCorporate(prevState => ({
            ...prevState,
            country_id: _bankCountryCorporate[0].id
          }))
        }
        setBankFilterCountriesCorporate([..._bankCountryCorporate])
      }
      setClientDetails(orgDetails)
      await dispatch(setLoaderSpinner(false))
    } else {
      history.push('/organizations')
    }
  }

  useEffect(() => {
    if (clientDetails?.setting?.certificate_tabs?.overview && clientDetails?.setting?.certificate_tabs?.account_information && clientDetails?.setting?.certificate_tabs?.income_tab && clientDetails?.setting?.certificate_tabs?.expense_tab && clientDetails?.setting?.certificate_tabs?.assets && clientDetails?.setting?.certificate_tabs?.debts && clientDetails?.setting?.certificate_tabs?.transactions && clientDetails?.setting?.certificate_tabs?.business_rules && clientDetails?.setting?.certificate_tabs?.sources && clientDetails?.setting?.certificate_tabs?.documents && clientDetails?.setting?.certificate_tabs?.credit_decision_application && clientDetails?.setting?.certificate_tabs?.credit_decision_surveillance) {
      setCertificateConsumer(true)
    } else {
      setCertificateConsumer(false)
    }

    if (clientDetails?.setting?.webhook_events?.BankDataFetched && clientDetails?.setting?.webhook_events?.BankDataProcessed && clientDetails?.setting?.webhook_events?.AccountsDataPrepared) {
      setWebhookEvent(true)
    }
    if (clientDetails?.setting?.webhook_events?.BankDataFetched && clientDetails?.setting?.webhook_events?.BankDataProcessed && clientDetails?.setting?.webhook_events?.AccountsData) {
      setWebhookEvent(true)
    }
    else { setWebhookEvent(false) }
  }, [clientDetails])

  useEffect(() => {
    fetchConfigurationSettingsData();
  }, []);

  const fetchConfigurationSettingsData = () => {
    getConfigurationSettings()
      .then(response => {
        if (response.status) {
          setConfigurationSetting(response.data);
        } else {
          console.error(response.message);
        }
      })
      .catch(error => {
        console.error(error.message);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target
    setClientDetails(prevState => ({
      ...prevState,
      [name]: value !== "" ? value : null
    }))
  }

  const selectCountryHandler = (event) => {
    setClientDetails(prevState => ({
      ...prevState,
      country_id: event.target.value
    }))
  }

  const handleStartDateAgreement = (date) => {
    setAgreementDates(prevState => ({
      ...prevState,
      agreement_start_date: date
    }))

    setClientDetails(prevState => ({
      ...prevState,
      agreement_start_date: moment(date).format('DD/MM/YYYY')
    }))
  }

  const handleEndDateAgreement = (date) => {
    setAgreementDates(prevState => ({
      ...prevState,
      agreement_end_date: date
    }))

    setClientDetails(prevState => ({
      ...prevState,
      agreement_end_date: moment(date).format('DD/MM/YYYY')
    }))
  }

  const handleAttachmentChange = (event, index) => {
    let value = event.target.files[0];
    let file_name = value.name
    if (value) {
      setAgreementData(prevState => ({
        ...prevState,
        agreement_file_name: file_name
      }))

      let reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = function () {
        setAgreementData(prevState => ({
          ...prevState,
          agreement_data: reader.result
        }))
      };
      reader.onerror = function (error) {
      };
    }
  }

  const handleSetting = (event, type) => {
    let setting = clientDetails.setting

    if (type !== "certificate_delete_ability") {
      setting[type] = event.target.checked
      setClientDetails(prevState => ({
        ...prevState,
        setting: setting
      }))
    }

    if (type === "certificate_delete_ability") {
      setting.certificate_delete_ability.employee = event.target.checked
      setClientDetails(prevState => ({
        ...prevState,
        setting: setting
      }))
    }

    if (type === "generic_bank_connection") {
      setting.generic_bank_connection = event.target.value;
      setClientDetails(prevState => ({
        ...prevState,
        setting: setting
      }))
    }

    if (type === 'api_bank_connection') {
      setting.api_bank_connection = event.target.value;
      setClientDetails(prevState => ({
        ...prevState,
        setting: setting
      }))
    }
    if (type === 'refetch_transactions_type') {
      setting.refetch_transactions_type = event.target.value;
      setClientDetails(prevState => ({
        ...prevState,
        setting: setting
      }))
    }
    if (type === 'iframe_bank_connection') {
      setting.iframe_bank_connection = event.target.value
      setClientDetails(prevState => ({
        ...prevState,
        setting: setting
      }))
    }

  }

  const handleRunUntil = (event) => {
    setClientDetails(prevState => ({
      ...prevState,
      run_until_terminate: event.target.checked
    }))
  }

  const submitHandle = async () => {
    setOpenEditOrg(true)
    setEidtOrgName(clientDetails.name)
  }
  const updateOrgDetails = async () => {
    await dispatch(setLoaderSpinner(true))
    let orgDetails = clientDetails
    orgDetails.cancellation_period = orgDetails.cancellation_period && orgDetails.cancellation_period !== '' ? parseInt(orgDetails.cancellation_period) : ''
    orgDetails.parent_organization_id = orgDetails.account_type && orgDetails.account_type !== '' ? orgDetails.parent_organization_id : null

    if (agreementData && agreementData.agreement_data && agreementData.agreement_file_name !== '') {
      orgDetails.agreement_data = agreementData.agreement_data
    }
    if (orgDetails.setting && orgDetails.setting.fetch_data_for_months == '') {
      orgDetails.setting.fetch_data_for_months = 12
    }
    if (orgDetails.setting && orgDetails.setting.session_storage_for == '') {
      orgDetails.setting.session_storage_for = 7
    }
    setOpenEditOrg(false)
    orgDetails.certificate_modulizations = clientDetails.certificate
    if (orgDetails.name !== "" && clientDetails.email !== "") {
      const updateResponse = await dispatch(updateOrganization(orgDetails, orgDetails.id))
      if (updateResponse.status) {
        await dispatch(setLoaderSpinner(false))
        openFlash("tick", "Done!", updateResponse.message)
        setTimeout(() => window.location.reload(), 1000)
      } else {
        await dispatch(setLoaderSpinner(false))
        if (updateResponse.message === "Unauthorize") {
          history.push("/login")
        } else {
          await dispatch(setLoaderSpinner(false))
          openFlashError("", "Error", updateResponse.message)
        }
      }
    }
    else if (clientDetails.name === "") {
      await dispatch(setLoaderSpinner(false))
      openFlashError("", "Error", "Please Enter Company Name")
    }
    else {
      await dispatch(setLoaderSpinner(false))
      openFlashError("", "Error", "Please Enter Contact Person Email")
    }
  }

  const togglePopup = () => {
    setOpenEditOrg(false)
    setOpenEditBank(false)
    setOpenDeleteBank(false)
    setEidtOrgName('')
  }

  const closeFlash = () => {
    setFlashMessage(false);
  }

  const handleChangeType = (e) => {
    const { name, value } = e.target
    setBankFilters(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleBankSearch = (e) => {
    setBankSearch(e.target.value)
    if (e.target.value !== '') {
      let _banks = _.filter(BankList, function (f) {
        if (f.display_name.toLowerCase().match(e.target.value.toLowerCase())) {
          return f
        }
      })
      setBanks(_banks)
    } else {
      fetchBankList()
    }
  }

  const _updateBankCustomMessage = async (id) => {
    await dispatch(setLoaderSpinner(true))
    let found = customBankDetails.find(item => { return item.kreditz_provider_id === id })
    if (found && found.kreditz_provider_id !== undefined) {
      let obj = {
        type: bankFilters.type,
        locale: bankFilters.locale,
        country_id: bankFilters.country_id,
        organization_id: organization_id,
        message_type: found.message_type,
        custom_message: found.custom_message,
        kreditz_provider_id: found.kreditz_provider_id
      }
      const response = await dispatch(updateBankCustomMessage(obj, found.custom_id))
      if (response && response.status) {
        await dispatch(setLoaderSpinner(false))
        openFlash("tick", "Done!", 'Bank message updated')
        setTimeout(() => history.push('/organizations'), 1000)
      } else {
        await dispatch(setLoaderSpinner(false))
        openFlashError("", "Error", response.message)
      }
    }
  }

  function closeEditFlash() {
    setdisplayFlash(false);
    setFlashMessage(false);
  }

  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setEditFlashMessage(true);
    setEditFlashData(prevState => ({
      ...prevState,
      iconImage: iconImage,
      firstMsg: firstMsg,
      secondMsg: secondMsg
    }))
  }

  const openFlashError = (iconImage, firstMsg, secondMsg) => {
    setFlashMessage(true);
    setFlashData(prevState => ({
      ...prevState,
      iconImage: iconImage,
      firstMsg: firstMsg,
      secondMsg: secondMsg
    }))
  }

  const changeNamesFromKeys = (key) => {
    let keyMapping = {
      "customized_view": "Custom Landing View",
      "customized_result_view": "Custom Success View",
      "customized_consent": "Custom Consent View",
      "email_template_enabled": "Custom Email Template",
      "sms_template_enabled": "Custom SMS Template",
      "customer_enabled": "Consumer Enabled",
      "storage_forever": "Duration of data storage",
      "corporate_enabled": "Corporate Enabled",
      "ip_restricted_login": "Ip Restricted Login",
      "business_rules_enabled": "Business Rules Enabled",
      "dummy_account": "Dummy Account",
      "certificate_column_customizable": "Custom Request Log",
      "continuous_access_enabled": "Continuous Access Enabled",
      "early_response": "Early Response",
      "enable_cerificate_sharing": "Enable Certificate Sharing",
      "generic_bank_connection": "Multibank Connection - Generic",
      "two_factor_enabled": "Two Factor Enabled",
      "skip_used_banks": "Iframe - Hide connected bank",
      "enable_primary_type_customization": "Custom Income type - Categories",
      "case_id_as_client_reference_id": "Case Id as Client Reference Id",
      "average_income": "Average income - Boxes",
      "income_graph": "Income - Graph",
      "monthly_view": "Monthly view - Accordion",
      "source_of_income": "Sources of income/Primary income transactions/Income transactions - Boxes",
      "income_vs_spending": "Income vs Spending - Graph",
      "cashflow_net_graph": "Cashflow net - Graph",
      "income_ratio_salary_burn_rate": "Income ratio/Salary burn rate - Boxes",
      "debt_categories": "Debt categories - Boxes",
      "spending_graph": "Spending graph/Spending ratio/High cost - Boxes",
      "total_assets_with_allocation_boxes": "Total assets/Assets allocation(%) - Boxes",
      "assets_graph": "Assets - graph",
      "monthly_savings_boxes": "Assets ratio (%)/Monthly savings ratio (%)/Monthly savings - Boxes",
      "balance_before_salary_graph": "Balance before salary - Graph",
      "summary_of_debts": "Summary of debts/Debt involvement/Disposition of repayments %/Noteworthy liabilities - Boxes",
      "paidout_vs_repayments_graph": "Paid out vs Repayments (Accumulated) - Graph",
      "debts_categories_boxes": "Debt categories - Boxes",
      "credit_institutions": "Credit institutions",
      "overview_decision_box": "Overview of decision - Box",
      "credit_policy_rules_box": "Credit policy rules - Box",
      "credit_scoring_box": "Credit scoring - Box",
      "affordability_box": "Affordability - Box",
      "cashflow_12_months_graph_or_accordion": "Cashflow 12 months + MTD - Graph/Accordion",
      "average_outflow_boxes": "Average outflow - Boxes",
      "average_inflow_boxes": "Average inflow - Boxes",
      "credit_policy_rules_box_corporate": "Credit policy rules - Box",
      "cashflow_box": "Cashflow - Box",
      "BankDataFetched": "Bank Data Fetched",
      "BankDataProcessed": "Bank Data Processed",
      "AccountsDataPrepared": "Accounts Data Prepared",
      "AccountsData": "Accounts Data",
      "ConsentGiven": "Consent Given",
      "ProcessCancelled": "Process Cancelled",
      "ErrorOccurred": "Error Occurred",
      "name": "Company Name",
      "organization_number": "Organization Number",
      "company_contact": "Contact Person First and Last Name",
      "end_user_display_name": "End-user Display Name",
      "homepage_address": "Homepage Address",
      "email": "Contact Person Email",
      "sms_sender_name": "SMS Name",
      "password": "Password",
      "contact_email": "Contact email for end user-Emails",
      "contact_phone": "Contact phone number for end user-Emails",
      "customized_flow": "Dynamic Flow",
      "generate_client_reference_id": "Generate Client Reference Id",
      "joint_applicant_requests_activated": "Joint Applicant Flow",
      "display_env_on_manual_requests": "Display ENV On Manual Request",
      "default_status_true": "Default Status True",
      "manual_request": "Manual Request",
      "iframe": "Iframe Request",
      "api": "Api Request",
      "verify_transaction_count_on_accept": "Verify Transaction Count On Accept",
      "show_ssn": "Show Default Ssn-SE market",
      "show_type_on_request_form": "Show Type On Request Form",
      "show_other_on_request_form": "Show Other On Request Form"
    }
    return keyMapping[key] || capitalizeFirstLetter(key);
  }

  const capitalizeFirstLetter = key => key.split('_').map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(' ')

  const editBankList = () => {
    setOpenEditBank(false)
  }

  const deleteSelectedBank = async () => {
    setOpenDeleteBank(false)
    const response = await dispatch(removeServiceProvider(deleteBank.organization_provider_id))
    const { status, message } = response
    if (status) {
      const remainingBanks = banks.filter(bank => bank.organization_provider_id !== deleteBank.organization_provider_id)
      setBanks(remainingBanks)
      openFlash("tick", "Done!", message)
    }
  }

  return (
    <div className="main-body">
      <div className="main-section">
        <div className={`alert-popup alert-success-bar ${editFlashMessage ? setTimeout(() => setEditFlashMessage(false), 2000) & setdisplayFlash(false) & 'alert-shown' : 'alert-hidden'}`}>
          <FlashMessage
            iconImage={editFlashData.iconImage}
            firstMsg={editFlashData.firstMsg}
            secondMsg={editFlashData.secondMsg}
            closeFlash={() => closeEditFlash()}
          />
        </div>
        {
          flashMessage &&
          <div className={`alert-popup alert-success-bar ${flashMessage ? setTimeout(() => setFlashMessage(false), 3000) & 'alert-shown' : 'alert-hidden'}`}>
            <NewFlash
              data={flashData}
              closeFlash={() => closeFlash()}
            />
          </div>
        }
        {
          openEditOrg ?
            <EditModal
              firstTextUser={textConfirmation1}
              secondTextUser={textConfirmation2}
              // userId={deleteOrgId}
              name={editOrgName}
              confirm={updateOrgDetails}
              closeDelete={togglePopup}
            />
            : null
        }
        {
          openEditBank && <EditModalBank
            firstTextUser={textConfirmation1}
            secondTextUser={textConfirmation2}
            editBank={editBank}
            // userId={deleteOrgId}
            name={editOrgName}
            confirm={editBankList}
            closeDelete={togglePopup}
          />
        }
        {
          openDeleteBank ?
            <DeleteModal
              firstTextUser={textConfirmation1}
              secondTextUser={textConfirmation2}
              deleteBank={deleteBank}
              confirm={deleteSelectedBank}
              closeDelete={togglePopup}
            />
            : null
        }
        <h3 className="basic-head01">
          <div className="add-breadcrum">Edit Organization<p><a href="/organizations">Organization Management</a> <img src={arrow} alt="arrow" />Edit Organization</p></div>
        </h3>
        <div className="bg-white-org bg-20-top-zero">
          <h3 className="h3-basic-heading-create">Client details</h3>
          <div className="row create-client-table">
            {
              ["name", "organization_number"].map((keys, index) => <OptionOrgInputForm keys={keys} changeNamesFromKeys={changeNamesFromKeys} type={"text"} value={clientDetails[keys]} changeHandler={handleChange} key={index} />)
            }
            <EditOrgFormSelect label={"Choose Country"} name={""} value={clientDetails.country_id} handleChange={selectCountryHandler} firstOption={"Select"} selectOption={country} />
            {
              ["company_contact", "end_user_display_name", "homepage_address"].map((keys, index) => <OptionOrgInputForm keys={keys} changeNamesFromKeys={changeNamesFromKeys} type={"text"} value={clientDetails[keys]} changeHandler={handleChange} key={index} />)
            }
            <EditOrgFormSelect label={"Choose Access"} name={"organization_type"} value={clientDetails.organization_type} handleChange={handleChange} firstOption={"Select Organization Type"} selectOption={accessOptions} />
            {
              ["email", "sms_sender_name"].map((keys, index) => <OptionOrgInputForm keys={keys} changeNamesFromKeys={changeNamesFromKeys} type={"text"} value={clientDetails[keys]} changeHandler={handleChange} key={index} />)
            }
            <div className="col-md-3 form-group">
              <label>Contact Person Phone Number</label>
              <div className="d-flex">
                <div className="d-flex-select">
                  <select className="form-control org-excludeDropdown" id="sel1" value={clientDetails.phone_country_id} onChange={(e) => handleChange(e)} name="phone_country_id">
                    {country.map((_country, index) =>
                      <option
                        className='admin-option'
                        key={`admin - option - ${index + 1}`}
                        value={_country.id}>
                        {_country.phone_country_code}
                      </option>
                    )};
                  </select>
                </div>
                <input type="text" className="form-control country-input" placeholder="Contact Person Phone" name='phone' value={clientDetails.phone} onChange={(e) => handleChange(e)} />
              </div>
            </div>
            <EditOrgFormSelect label={"Customized Responses"} name={"customized_response"} value={clientDetails.customized_response} handleChange={handleChange} firstOption={""} selectOption={customizedResponseOptions} />
            <OptionOrgInputForm keys={"password"} changeNamesFromKeys={changeNamesFromKeys} type={"password"} value={clientDetails.password} changeHandler={handleChange} />
            {
              ["contact_email", "contact_phone"].map((keys, index) => <OptionOrgInputForm keys={keys} changeNamesFromKeys={changeNamesFromKeys} type={"text"} value={clientDetails[keys]} changeHandler={handleChange} key={index} />)
            }
            <EditOrgFormSelect label={"Status"} name={"active"} value={clientDetails.active} handleChange={handleChange} firstOption={""} selectOption={statusOptions} />
            <div className="col-md-3 form-group">
              {!clientDetails.is_parent_org &&
                <>
                  <label>Account Type</label>
                  <select className="form-control org-excludeDropdown" id="sel1" name='account_type' value={clientDetails.account_type} onChange={(e) => handleChange(e)}>
                    <option value='main_account'>Main account</option>
                    <option value='sub_account'>Sub-Account / Brand</option>
                    <option value='partner'>Partner</option>
                  </select>
                </>
              }
            </div>
            {
              clientDetails.account_type && clientDetails.account_type !== 'main_account' && (
                <div className="col-md-3 form-group">
                  <label>Select Main Account</label>
                  <ReactSelect
                    orgList={orgAccountList}
                    pageRender={"edit org"}
                    handleChange={handleChange}
                    setSelectedOrgSelect={setSelectedOrgSelect}
                    value={selectedOrgSelect}
                  />
                </div>
              )
            }
          </div>
        </div>
        <div className="bg-white-org bg-20-top-zero mtb-15">
          <div className="tab-check-list">
            <ul>
              <li><a href="#!" id="products" className={activeTab === 'products' ? 'active-ontab' : ''} onClick={() => setActiveTab('products')} >Products</a></li>
              <li><a href="#!" id="certificate" className={activeTab === 'certificate' ? 'active-ontab' : ''} onClick={() => setActiveTab('certificate')} >Certificate</a></li>
              <li><a href="#!" id="language" className={activeTab === 'language' ? 'active-ontab' : ''} onClick={() => setActiveTab('language')} >Language</a></li>
              <li><a href="#!" id="options" className={activeTab === 'options' ? 'active-ontab' : ''} onClick={() => setActiveTab('options')} >Options</a></li>
              <li><a href="#!" id="V4_Customizations" className={activeTab === 'V4Customizations' ? 'active-ontab' : ''} onClick={() => setActiveTab('V4Customizations')} >V4 Customizations</a></li>
              <li><a href="#!" id="banklist" className={activeTab === 'banklist' ? 'active-ontab' : ''} onClick={() => bankListHandler()} >Bank List</a></li>
              <li><a href="#!" id="notification" className={activeTab === 'notification' ? 'active-ontab' : ''} onClick={() => setActiveTab('notification')} >Notification</a></li>
              <li><a href="#!" id="prices" className={activeTab === 'prices' ? 'active-ontab' : ''} onClick={() => setActiveTab('prices')} >Prices</a></li>
              <li><a href="#!" id="agreementterms" className={activeTab === 'agreementterms' ? 'active-ontab' : ''} onClick={() => setActiveTab('agreementterms')} >Agreement & Terms</a></li>
            </ul>
          </div>
          {
            activeTab === 'products' && (
              <ProductTab
                setClientDetails={setClientDetails}
                clientDetails={clientDetails}
                capitalizeFirstLetter={capitalizeFirstLetter}
                submitHandle={submitHandle}
              />
            )
          }
          {
            activeTab === 'certificate' && (
              <CertificateTab
                setClientDetails={setClientDetails}
                clientDetails={clientDetails}
                capitalizeFirstLetter={capitalizeFirstLetter}
                submitHandle={submitHandle}
                setBankFiltersCorporate={setBankFiltersCorporate}
                bankFilterCountries={bankFilterCountries}
                setBankFilterCountries={setBankFilterCountries}
                setBankFilters={setBankFilters}
                bankFilterCountriesCorporate={bankFilterCountriesCorporate}
                setBankFilterCountriesCorporate={setBankFilterCountriesCorporate}
                country={country}
                handleSetting={handleSetting}
              />
            )
          }
          {
            activeTab === 'language' && (
              <LanguageTab
                setClientDetails={setClientDetails}
                clientDetails={clientDetails}
                submitHandle={submitHandle}
              />
            )
          }
          {
            activeTab === 'options' && (
              <OptionTab
                clientDetails={clientDetails}
                orgPartialName={orgPartialName}
                orgResponseFor={orgResponseFor}
                continuous_access_response_for_list={continuous_access_response_for_list}
                capitalizeFirstLetter={capitalizeFirstLetter}
                changeNamesFromKeys={changeNamesFromKeys}
                orgCustomFor={orgCustomFor}
                earlyResponseFor={earlyResponseFor}
                customPdfFor={customPdfFor}
                creditDecisionVista={creditDecisionVista}
                months={months}
                setClientDetails={setClientDetails}
                handleSetting={handleSetting}
                handleChange={handleChange}
                creditDecisionCorpVista={creditDecisionCorpVista}
                submitHandle={submitHandle}
                configurationSettings={configurationSettings} />
            )
          }
          {
            activeTab === 'V4Customizations' && (
              <V4CustomizationTab
                setClientDetails={setClientDetails}
                clientDetails={clientDetails}
                capitalizeFirstLetter={capitalizeFirstLetter}
                handleSetting={handleSetting}
                submitHandle={submitHandle}
                organizationList={organizationList}
                sharedOrganizationSelect={sharedOrganizationSelect}
              />
            )
          }
          {
            activeTab === 'banklist' && (
              <BankListTab
                clientDetails={clientDetails}
                bankFilters={bankFilters}
                setBankFilters={setBankFilters}
                languages={languages}
                bankFilterCountries={bankFilterCountries}
                bankFilterCountriesCorporate={bankFilterCountriesCorporate}
                country={country}
                banks={banks}
                setBankSearch={setBankSearch}
                bankSearch={bankSearch}
                setBanks={setBanks}
                fetchBankList={fetchBankList}
                setFoundElement={setFoundElement}
                setCustomBankDetails={setCustomBankDetails}
                customBankDetails={customBankDetails}
                showMessage={showMessage}
                foundElement={foundElement}
                organization_id={organization_id}
                setShowMessage={setShowMessage}
                _updateBankCustomMessage={_updateBankCustomMessage}
                searchIcon={searchIcon}
                serviceProviders={serviceProviders}
                openFlash={openFlash}
              />
            )
          }
          {
            activeTab === 'notification' && (
              <NotificationTab
                setClientDetails={setClientDetails}
                country={country}
                changeNamesFromKeys={changeNamesFromKeys}
                clientDetails={clientDetails}
                handleSetting={handleSetting}
                submitHandle={submitHandle}
                setOpenEditOrg={setOpenEditOrg}
                setEidtOrgName={setEidtOrgName}
              />
            )
          }
          {
            activeTab === 'prices' && (
              <PricesTab />
            )
          }
          {
            activeTab === 'agreementterms' && (
              <AgreementTab
                clientDetails={clientDetails}
                agreementDates={agreementDates}
                handleStartDateAgreement={handleStartDateAgreement}
                handleEndDateAgreement={handleEndDateAgreement}
                handleRunUntil={handleRunUntil}
                handleChange={handleChange}
                handleAttachmentChange={handleAttachmentChange}
                agreementData={agreementData}
                uploadIcon={uploadIcon}
                calendarIcon={calendarIcon}
                submitHandle={submitHandle}
              />
            )
          }
        </div>
      </div>
    </div>
  )
}

export default EditOrganization;