import CertificateInfoConnections from "./CertificateInfoConnections";
import CertificateActivityLog from "./CertificateActivityLog";
import { getcertlogssearchList } from "../../../Redux/Actions/CertificateLogs";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoaderSpinner } from "../../../Redux/Actions/LoaderSpinner";

const CertificateInfoView = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const orgId = history.location.state?.orgId;
  const certId = history.location.state?.certId;
  const selectedMarket = history.location.state?.selectedMarket;
  const selectedDateRange = history.location.state?.selectedDateRange;
  const selectedOrg = history.location.state?.selectedOrg;
  const selectedOrgSelect = history.location.state?.selectedOrgSelect;
  const showAcceptedOnly = history.location.state?.showAcceptedOnly;
  const [data, setData] = useState({});
  const fetchCertificateInfo = async (id) => {
    let query = "?find_by_param=" + "id" + "&find_by_value=" + certId + "&organization_id=" + orgId;
    await dispatch(setLoaderSpinner(true));
    const { status, message, data = null, } = await dispatch(getcertlogssearchList(query));
    setData(data)
    if (!status) {
      history.push("/login");
    }
    await dispatch(setLoaderSpinner(false));
  }
  useEffect(() => {
    fetchCertificateInfo()
  }, [])

  return (
    <div className="cert-info-container">
      {(data && Object.keys(data).length > 0) && <CertificateInfoConnections certId={certId} bank={data.bank} data={data} selectedMarket={selectedMarket} selectedDateRange={selectedDateRange} selectedOrg={selectedOrg} selectedOrgSelect={selectedOrgSelect} showAcceptedOnly={showAcceptedOnly} />}
      {(data && Object.keys(data).length > 0) && <CertificateActivityLog logData={data.event_logs} />}
    </div>
  )
}
export default CertificateInfoView;