import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ReportFilter from "./reportFilter";
import "./scss/report.scss";
import remove from "../../assets/images/remove.svg";
import download from "../../assets/images/icon_download.svg";
import { reportListAPI, getDownloadedUrl, deleteRecordAPI, addReportToAdminLog } from "../../Redux/Actions/Report";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import NewFlash from "../FlashMessage/NewFlash";
import DeleteModal from "./deleteModal";

const ReportList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedDateRange, setSelectedDateRange] = useState(
    `${getStartDate(new Date())} - ${getEndDate(new Date())}`
  );
  const [reportData, setReportData] = useState([]);
  const [reportTypes, setReportType] = useState([]);
  const [selectedReportType, setSelectedReportType] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [paginationData, setpaginationData] = useState({ current_page: 1, total_pages: "", total_count: "" });
  const [page, setPage] = useState(1);
  const [flashMessage, setFlashMessage] = useState(false);
  const [flashData, setFlashData] = useState({ iconImage: "", firstMsg: "", secondMsg: "" });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});

  useEffect(() => {
    fetchReportList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReportType, searchKey, page]);

  const fetchReportList = async () => {
    await dispatch(setLoaderSpinner(true));
    const params = `?report_date_range=${selectedDateRange}&report_type=${selectedReportType}&document_key=${searchKey}&page=${page}`;
    const { status, message, result } = await dispatch(reportListAPI(params));
    if (status) {
      setReportData(result.data);
      setReportType(result.report_types);
      setpaginationData(result.pagination_data);
    } else {
      if (message === "Unauthorize") {
        history.push("/login");
      }
    }
    await dispatch(setLoaderSpinner(false));
  };

  const getDownloadedUrlFromAPI = async (id) => {
    await dispatch(setLoaderSpinner(true));
    const { status, message, result } = await dispatch(getDownloadedUrl(id));
    if (status) {
      window.open(result.url, "_blank").focus();
      await dispatch(setLoaderSpinner(false));
    } else {
      if (message === "Unauthorize") {
        await dispatch(setLoaderSpinner(false));
        history.push("/login");
      } else if (!status) {
        setFlashMessage(true);
        setFlashData((prevState) => ({
          ...prevState,
          iconImage: "alert",
          firstMsg: "Error!",
          secondMsg: message,
        }));
        await dispatch(setLoaderSpinner(false));
      }
    }
  };

  const handleUpdate = async (data) => {
    const response = await dispatch(addReportToAdminLog(data));
    if (response.status) {
      if (response.message === "Unauthorize") {
        history.push("/login");
      } else {
        const result = reportData.findIndex((item) => item.id === data.id);
        reportData[result].id = data.id;
        reportData[result].report_action = data.report_action;
        reportData[result].document_key = data.document_key;
        reportData[result].report_type = data.report_type;
      }
    }
  };

  const submitHandle = (x) => {
    const data = {
      id: x.id,
      report_action: "Report downloaded",
      document_key: x.document_key,
      report_type: x.report_type,
    };
    getDownloadedUrlFromAPI(x.id);
    handleUpdate(data);
  };

  const getDuration = (data) => {
    if (!data.start_date || !data.end_date) {
      return "N/A";
    }
    let startDay = data.start_date.slice(8, 10);
    let startMonth = data.start_date.slice(5, 7);
    let startYear = data.start_date.slice(0, 4);

    let endDay = data.end_date.slice(8, 10);
    let endMonth = data.end_date.slice(5, 7);
    let endYear = data.end_date.slice(0, 4);
    let startDate = moment(`${startYear}.${startMonth}.${startDay}`, "YYYY.MM.DD");
    let endDate = moment(`${endYear}.${endMonth}.${endDay}`, "YYYY.MM.DD");

    var years = endDate.diff(startDate, "year");
    startDate.add(years, "years");

    var months = endDate.diff(startDate, "months");
    startDate.add(months, "months");

    var days = endDate.diff(startDate, "days");

    var y = years > 0 ? `${years} ${years > 1 ? "Years " : "Year "} ` : "";
    var m = months > 0 ? `${months} ${months > 1 ? "Months " : "Month "} ` : "";
    var d = days > 0 ? `${days} ${days > 1 ? "Days" : "Day"} ` : "";
    return y + m + d;
  };

  const getDocumentKey = (data) => {
    switch (data.report_type) {
      case "invoice":
        return data.document_key.split("invoice_")[1];
      case "conversion_rate":
        return data.document_key.split("conversion_rate_")[1];
      default:
        return data.document_key;
    }
  };

  const deleteRecord = async (id) => {
    setShowConfirmationModal(false);
    await dispatch(setLoaderSpinner(true));
    const { status, message } = await dispatch(deleteRecordAPI(id));
    if (status) {
      fetchReportList();
      setFlashMessage(true);
      setFlashData((prevState) => ({
        ...prevState,
        iconImage: "tick",
        firstMsg: "Success",
        secondMsg: message,
      }));
    } else {
      if (message === "Unauthorize") {
        await dispatch(setLoaderSpinner(false));
        history.push("/login");
      }
    }
  };

  const dispayConfirmationModel = (report) => {
    setShowConfirmationModal(true);
    setSelectedRecord(report);
  };

  const renderTable = () => {
    const bytesToKilobytes = (bytes) => {
      return (bytes / 1024).toFixed(2);
    };

    return (
      <table className="sms-recent-log-table" id="sms-recent-log-table" width="100%">
        <thead className="th-light">
          <tr>
            <th className="sms-th">Name</th>
            <th className="sms-th">Report Type</th>
            <th className="sms-th">Format</th>
            <th className="sms-th">Duration</th>
            <th className="sms-th">Size</th>
            <th className="sms-th">Generated on</th>
            <th className="sms-th">Action</th>
          </tr>
        </thead>
        <tbody>
          {reportData.map((data) => {
            return (
              <tr className={`report_${data.id}`} key={`report_${data.id}`}>
                <td className="sms-td" value="service">{getDocumentKey(data)}</td>
                <td className="sms-td" value="service">{data.report_type}</td>
                <td className="sms-td" value="service">{data.document_type}</td>
                <td className="sms-td" value="service">{getDuration(data)}</td>
                <td className="sms-td" value="service">{bytesToKilobytes(data.document_size)} KB</td>
                <td className="sms-td" value="service">{moment(data.created_at).format("YYYY-MM-DD")}</td>
                <td className="sms-td" value="service">
                  <span className="remove-icon">
                    <img src={download} alt="download" onClick={() => submitHandle(data)} />
                  </span>
                  <span className="remove-icon">
                    <img src={remove} alt="remove" onClick={() => dispayConfirmationModel(data)} />
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const handleCallback = (start, end, label) => {
    const startDateValue = moment(start._d).format("DD/MM/YYYY");
    const endDateValue = moment(end._d).format("DD/MM/YYYY");
    setSelectedDateRange(`${startDateValue}-${endDateValue}`);
  };

  function getStartDate(d) {
    return moment(new Date(d.setMonth(d.getMonth() - 1))).format("DD/MM/YYYY");
  }

  function getEndDate(d) {
    return moment(d).format("DD/MM/YYYY");
  }

  const handleSubmit = (start, end, label) => {
    fetchReportList();
  };

  const closeFlash = () => {
    setFlashMessage(false);
  };

  return (
    <div className="page-container">
      {flashMessage && (
        <div
          className={`alert-popup alert-success-bar ${flashMessage
            ? setTimeout(() => setFlashMessage(false), 3000) & "alert-shown"
            : "alert-hidden"
            }`}
        >
          <NewFlash data={flashData} closeFlash={() => closeFlash()} />
        </div>
      )}
      {showConfirmationModal && (
        <DeleteModal
          report={selectedRecord}
          document_key={getDocumentKey(selectedRecord)}
          setShowConfirmationModal={(value) => setShowConfirmationModal(value)}
          deleteRecord={(id) => deleteRecord(id)}
        />
      )}

      <div className="sms-log-container">
        <ReportFilter
          getStartDate={getStartDate}
          getEndDate={getEndDate}
          handleCallback={handleCallback}
          handleSubmit={handleSubmit}
          reportTypes={reportTypes}
          setSelectedReportType={(value) => setSelectedReportType(value)}
          searchKey={searchKey}
          setSearchKey={(value) => setSearchKey(value)}
        />
        <div className="sms-recent-log-table-header">
          <div className="report-table-container report-table-container-scroll-y">
            {renderTable()}
            <div className="pagination-wrapper">
              {paginationData.total_count > 10 && (
                <div className="report-pagination-bar">
                  <ReactPaginate
                    previousLabel="Previous"
                    activePage={Math.ceil(paginationData.current_page)}
                    nextLabel="Next"
                    breakLabel={"..."}
                    pageCount={Math.ceil(paginationData.total_pages)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={15}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                    onPageChange={(e) => setPage(e.selected + 1)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportList;
