import { Link } from "react-router-dom"
import './style.scss'
import icon_edit from '../../assets/images/edit.svg'
import icon_delete from '../../assets/images/delete_icon.svg'
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import BannerDeleteModal from "./BannersDeleteModal"
import { getBanners } from "../../Redux/Actions/Banners"
import { setLoaderSpinner } from '../../Redux/Actions/LoaderSpinner';

const TableEntry = ({ data, ...props }) => {
  const [visibilityToggle, setVisibilityToggle] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const productFormat = (product) => {
    switch (product) {
      case "public_website":
        return "Public Website"
      case "vista":
        return "Vista"
      case "vista_request_log":
        return "Vista - Request log"
      default:
        return "unknown"
    }
  }
  const toggleVisibility = () => {
    setVisibilityToggle(!visibilityToggle);
  }

  const expandData = () => {
    setExpanded(!expanded);
  }
  return (

    <tr className={`banners-table-hover banners-table-row banners-grid-container ${expanded && !visibilityToggle ? "banners-table-expanded" : ""}`} onClick={expandData}>
      <td className="banners-table-data-overflow banners-template" title={data.template}>{data.template}</td>
      <td className="banners-table-data-overflow banners-title" title={data.title}>{data.title}</td>
      <td className={`banners-table-data-overflow banners-text`}>{data.message}</td>
      <td className="banners-product">{productFormat(data.product)}</td>
      <td className="banners-enabled">
        {data.enabled ? <span className="banners-enabled-text banners-enabled-yes  text-center">Yes</span> : <span className="banners-enabled-text banners-enabled-no text-center">No</span>}
      </td>
      <td className="banners-actions justify-content-between">
        <Link className="banners-btns-actions" to={`/banners_edit/${data.id}`}>
          <img src={icon_edit} alt="" />
        </Link>
        <button className="banners-btns-actions" onClick={toggleVisibility}>
          <img src={icon_delete} alt="" />
        </button>
        <BannerDeleteModal data={data} visibleToggle={toggleVisibility} fetchBanners={() => { props.fetchBanners() }} visible={visibilityToggle} /></td>
    </tr >

  )
}

const Table = ({ bannerDataList, ...props }) => {
  if (!Array.isArray(bannerDataList)) {
    return (<>
      <tr key={"tableError"}><td><span className="text-warning">Error</span></td></tr>
    </>)
  }
  const tableContent = bannerDataList.map(((d, key) => { return <TableEntry data={d} fetchBanners={() => { props.fetchBanners() }} key={key} /> }))
  return tableContent;
}


const BannersList = () => {
  const [bannerData, setBannerData] = useState();
  const headerHTML = (<div className="banners-header-container">
    <h3>Banners overview</h3>
    <Link to="/banners_create">
      <button className="btn btn-primary" type="button">Create Template</button>
    </Link>

  </div>
  );

  const dispatch = useDispatch();
  useEffect(() => {

    fetchBanners();


  }, []);

  const fetchBanners = async () => {
    await dispatch(setLoaderSpinner(true));

    const res = await dispatch(getBanners());
    setBannerData(res.data.banners);
    await dispatch(setLoaderSpinner(false));
    return res.data.banners;
  }




  if (!bannerData) {
    return (
      <div className="page-container">
        {headerHTML}
        <div className="container bg-white p-4 d-flex justify-content-center mw-100">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>

    )
  }
  if (bannerData.length == 0) {
    return (<div className="page-container">
      {headerHTML}
      <div className="container bg-white p-4 d-flex justify-content-center mw-100">
        <span className="text-muted">No banners exists</span>
      </div>
    </div>)
  }


  return (
    <>
      <div className="page-container">
        {headerHTML}
        <div className="container mw-100 bg-white p-4">
          <table className="table text-nowrap banners-table d-flex flex-column text-start">

            <thead className="banners-thead">
              <tr className="banners-grid-container">
                <th className="banners-template">Template</th>
                <th className="banners-title">Title</th>
                <th className="banners-text">Message</th>
                <th className="banners-product">Product</th>
                <th className="banners-enabled">Enabled</th>
                <th className="banners-actions">Action</th>
              </tr>

            </thead>

            <tbody>
              <Table bannerDataList={bannerData} fetchBanners={fetchBanners} />
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default BannersList;

