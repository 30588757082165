import { useState, useEffect } from "react";
import TotalAcceptedRequestsChart from "./TotalAcceptedRequestsChart";
import ConsumerRequestsChart from "./ConsumerRequestsChart";
import CorporateRequestsChart from "./CorporateRequestsChart";
import { setLoaderSpinner } from "../../../Redux/Actions/LoaderSpinner";
import { getActiveCustomersData } from "../../../Redux/Actions/Statistics";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";

const ActiveCustomers = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [activeCustomers, setActiveCustomers] = useState([])

  function getStartDate(d) {
    return moment(d).startOf('month').format("DD/MM/YYYY")
  }

  function getEndDate(d) {
    return moment(d).format("DD/MM/YYYY");
  }

  const startDate = getStartDate(new Date())
  const endDate = getEndDate(new Date())
  const [selectedDateRange, setSelectedDateRange] = useState(`${startDate}-${endDate}`);

  useEffect(() => {
    fetchActiveCustomersData()
  }, [])

  const fetchActiveCustomersData = async () => {
    const params =
      "?statistics_date_range=" + selectedDateRange;
    await dispatch(setLoaderSpinner(true));
    const { status, data } = await dispatch(getActiveCustomersData(params));
    setActiveCustomers(data)
    if (!status) {
      history.push("/login");
    }
    await dispatch(setLoaderSpinner(false));
  };

  const handleCallback = (start, end, label) => {
    const startDateValue = moment(start._d).format("DD/MM/YYYY");
    const endDateValue = moment(end._d).format("DD/MM/YYYY");
    setSelectedDateRange(`${startDateValue}-${endDateValue}`);
  };

  let totalAcceptedRequestsArray = [];
  let consumerReqsArray = [];
  let corporateReqsArray = [];


  activeCustomers.map((x) => {
    totalAcceptedRequestsArray.push({ name: x.name, y: x.accepted_requests, type: x.type })
  })

  let sortedRequestsArray = totalAcceptedRequestsArray.sort((a, b) => {
    return b.y - a.y
  })

  sortedRequestsArray.map((x) => {
    if (x.type === "customer") {
      consumerReqsArray.push({ name: x.name, y: x.y })
    }
    else if (x.type === "corporate") {
      corporateReqsArray.push({ name: x.name, y: x.y, type: x.type })
    }
  })

  const handleSubmit = (event) => {
    fetchActiveCustomersData()
  };

  return (
    <div className="statistics-container">
      <div className="lower-header">
        <h3>Active customers</h3>
        <div className="flex-header customer-activity-header">
          <DateRangePicker
            onCallback={handleCallback}
            initialSettings={{ startDate: getStartDate(new Date()), endDate: getEndDate(new Date()), locale: { format: "DD/MM/YYYY" } }}
            onApply={handleSubmit}
          >
            <input
              type="text"
              id="date-range-picker"
              className="date-form-box"
            />
          </DateRangePicker>
          <div className="customer-activity-icon">
            <FontAwesomeIcon icon={faCalendar} size="1x" color="#e8edfa" />
          </div>
        </div>
      </div>
      <section className="accepted-requests-section active-customers-section">
        <div className="active-customer-charts">
          {totalAcceptedRequestsArray && <TotalAcceptedRequestsChart totalAcceptedRequestsArray={sortedRequestsArray} />}
        </div>
        <div className="active-customer-charts">
          {consumerReqsArray && <ConsumerRequestsChart consumerReqsArray={consumerReqsArray} />}
          {corporateReqsArray && <CorporateRequestsChart corporateReqsArray={corporateReqsArray} />}
        </div>
      </section>
    </div>
  )
}

export default ActiveCustomers;