import React from 'react'
import { useEffect } from 'react';
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import LiveDataMarkets from '../../Components/LiveData/LiveDataMarkets/LiveDataMarkets';
import GenericBarChart from '../../Components/LiveData/GenericBarChart'
import "./livedata.css";


const LiveDataMarketsPage = () => {

    useEffect(() => {
        document.title = "Kreditz | Admin portal - Live Markets Data";
    }, []);

    return (
        <>
            <div className="wrapper">
                <Header />
                <div className="main-container">
                    <Sidebar />
                    <LiveDataMarkets />
                </div>
            </div>
        </>
    )
}

export default LiveDataMarketsPage

