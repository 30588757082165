import React from "react";
import "./listspinner.css";

const ListSpinner = ({ loading= null }) => {
	return(
		<>
		{
			loading ? 
				(<div className="list-loader"></div>)
  		: null
		}
		</>
  )
}

export default ListSpinner;