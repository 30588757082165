const ModesTable = (props) => {

  const modesData = [];

  props.modesData.map((data) => {
    if (data.y !== 0) {
      modesData.push({ name: data.name, value: data.y })
    }
  })

  function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  return (
    <table
      className="table providers-modes-table"
      width="100%"
    >
      <thead
        className="providers-modes-thead"
      >
        <tr>
          <th className="table-head-providers-modes">
            Modes
          </th>
          <th className="table-head-providers-modes providers-modes-table-right">
            Count
          </th>
        </tr>
      </thead>
      <tbody>
        {modesData.map((data, index) => (
          <tr className="providers-modes-tr" key={index}>
            <td className="providers-modes-td">{data.name}</td>
            <td className="providers-modes-td providers-modes-table-right">{numberWithSpaces(data.value)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ModesTable;