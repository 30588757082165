import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscribersList, deleteSubscriber, getBasicValues } from '../../Redux/Actions/TestCenter';
import { getdisplayFlash, setdisplayFlash } from '../../Utils/Common';
import FlashMessage from '../FlashMessage/FlashMessage';
import SubscriberDeleteModal from './Modal';

// LIBRARIES
import moment from 'moment';

// ASSETS
import dropdownIcon from './assets/kreditz-dropdown-icon-grey.svg';
import trashIconMain from './assets/kreditz-trash-icon-main.png';
import editIcon from './assets/kreditz-sign-icon.png';

// STYLES
import './styles/ActiveSubscribers.css';


const ActiveSubscribers = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const subscribersData = useSelector(state => state.test_centers?.subscribers_list);
  const countries = useSelector(state => state.test_centers.countries);

  const [flashMessage, setFlashMessage] = useState(getdisplayFlash());
  const [iconImage, setIconImage] = useState('tick');
  const [firstMsg, setFirstMsg] = useState('Done!');
  const [secondMsg, setSecondMsg] = useState('Subscriber has been edited successfully');
  const [, setSelectedFullName] = useState('');
  const [, setSelectedSSN] = useState('');
  const [, setSelectedEmail] = useState('');
  const [, setSelectedPhoneNumber] = useState('');
  const [, setSelectedCertificateType] = useState('');
  const [, setSelectedCountryID] = useState('');
  const [, setSelectedLanguage] = useState('');
  const [, setSelectedProduct] = useState('');
  const [, setSelectedRequest] = useState('');
  const [, setSelectedTime] = useState('');
  const [, setSelectedStartDate] = useState('');
  const [, setSelectedEndDate] = useState('');
  const [, setIsContinuous] = useState(false);
  const [, setSelectedSubscriberID] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [subscriberName, setSubscriberName] = useState('');
  const [subscriberSource, setSubscriberSource] = useState('');
  const [subscriberProduct, setSubscriberProduct] = useState('');
  const [subscriberType, setSubscriberType] = useState('');
  const [subscriberMarket, setSubscriberMarket] = useState('');
  const [subscriberStatusActivity, setSubscriberStatusActivity] = useState('');
  const [currentSubscribers, setCurrentSubscribers] = useState([]);

  const [subscribers, setSubscribers] = useState(subscribersData)

  const fetchSubsribersData = async () => {
    let query = ''
    const data = await dispatch(getSubscribersList(query))
    await dispatch(getBasicValues())
    if (data.status) {
      setSubscribers([...data.data.subscribers])
      setCurrentSubscribers([...data.data.subscribers]);
    }
  }

  const subscriberModalsInitialState = {};
  subscribers?.forEach(({ id }) => {
    subscriberModalsInitialState[id] = false;
  })
  const [subscriberDeleteModalOpen, setSubscriberDeleteModalOpen] = useState(subscriberModalsInitialState);

  const handleOpen = (id) => setSubscriberDeleteModalOpen({
    ...subscriberDeleteModalOpen,
    [id]: true
  });
  const handleClose = () => setSubscriberDeleteModalOpen(false);

  const expandFilters = () => {
    filtersOpen ? setFiltersOpen(false) : setFiltersOpen(true);
  };

  const highlight = () => {
    isActive ? setIsActive(false) : setIsActive(true);
  };

  const expandHandler = () => {
    expandFilters();
    highlight();
  };

  const editSubscriber = (id) => {
    const subscriber = subscribers.find((subscriber) => subscriber.id === id);
    setSelectedSubscriberID(id);
    setSelectedFullName(subscriber.full_name);
    setSelectedSSN(subscriber.ssn);
    setSelectedEmail(subscriber.email);
    setSelectedPhoneNumber(subscriber.phone);
    setSelectedProduct(subscriber.product);
    setSelectedCountryID(subscriber.country_id);
    setSelectedLanguage(subscriber.language);
    setSelectedCertificateType(subscriber.request_type);
    setSelectedRequest(subscriber.request_per_day);
    setSelectedTime(subscriber.request_times);
    setSelectedStartDate(subscriber.start_date);
    setSelectedEndDate(subscriber.end_date);
    setIsContinuous(subscriber.continuously);
    history.push({
      pathname: '/editSubscriber',
      state: {
        subscriber,
        basicValue: props.basicValue
      }
    });
  };

  const deleteSelectedSubscriberIDHandler = async (id) => {
    setSelectedSubscriberID(id);
    const { requestSuccess } = await dispatch(deleteSubscriber(id));
    if (requestSuccess) {
      setSubscriberDeleteModalOpen(false)
      fetchSubsribersData()
      history.push({ pathname: '/activeSubscribers' });
    }
  };

  const deleteSubscriberHandler = (id) => {
    handleOpen(id);
    setSelectedSubscriberID(id);
  };

  useEffect(() => {
    fetchSubsribersData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeFlash = () => {
    setdisplayFlash(false);
    setFlashMessage(false);
  };

  const openFlash = () => {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  };

  const names = subscribers?.map((subscriber) => subscriber.full_name);
  const filteredNames = [...new Set(names)];

  const sources = subscribers?.map((subscriber) => subscriber.send_by);
  const filteredSources = [...new Set(sources)];

  const products = subscribers?.map((subscriber) => subscriber.request_type);
  const filteredProducts = [...new Set(products)];

  const types = subscribers?.map((subscriber) => subscriber.product);
  const filteredTypes = [...new Set(types)];

  const subscriberCountryIDs = subscribers?.map(({ country_id }) => country_id);
  const countryNamesToShow = countries
    .filter(({ id }) => subscriberCountryIDs?.includes(id))
    .map(({ name }) => `${name.charAt(0).toUpperCase()}${name.slice(1)}`);

  const selectStatusActivityHandler = (e) => {
    const currentStatusActivity = e.target.value;
    setSubscriberStatusActivity(currentStatusActivity);
  };

  const updateFormHandler = (e) => {
    e && e.preventDefault();
    let subscribersToDisplay = subscribers;
    if (subscriberName) {
      subscribersToDisplay = subscribersToDisplay.filter((subscriber) => subscriber.full_name === subscriberName);
    }

    if (subscriberSource) {
      subscribersToDisplay = subscribersToDisplay.filter((subscriber) => subscriber.send_by === subscriberSource);
    }

    if (subscriberType) {
      subscribersToDisplay = subscribersToDisplay.filter((subscriber) => subscriber.product === subscriberType);
    }

    if (subscriberMarket) {
      subscribersToDisplay = subscribersToDisplay.filter((subscriber) => countries.find(({ id }) => id === subscriber.country_id).name === subscriberMarket.toLowerCase());
    }

    if (subscriberStatusActivity) {
      const inactiveSubscriberIDs = [];
      const todayDate = moment(new Date());
      subscribers?.forEach(subscriber => {
        if (!subscriber.continuously) {
          const subscriberEndDate = moment(new Date(subscriber.end_date));
          const difference = todayDate.diff(subscriberEndDate, 'days');
          if (difference > 0) {
            inactiveSubscriberIDs.push(subscriber.id);
          }
        }
      });

      if (subscriberStatusActivity === 'inactiveSubscriber') {
        subscribersToDisplay = subscribersToDisplay.filter((subscriber) => inactiveSubscriberIDs?.includes(subscriber.id));
      } else {
        subscribersToDisplay = subscribersToDisplay.filter((subscriber) => !inactiveSubscriberIDs?.includes(subscriber.id));
      }
    }

    setCurrentSubscribers(subscribersToDisplay);
  };

  return (
    <div className='tc-page-container'>
      <div className={`alert-popup alert-success-bar ${flashMessage ? setTimeout(() => setFlashMessage(false), 3000) & setdisplayFlash(false) & 'alert-shown' : 'alert-hidden'}`}>
        <FlashMessage
          iconImage={iconImage}
          firstMsg={firstMsg}
          secondMsg={secondMsg}
          openFlash={() => openFlash()}
          closeFlash={() => closeFlash()}
        />
      </div>
      <div className='tc-table-wrapper'>
        <div className='tc-top'>
          <h3 className='as-h3' onClick={() => history.push('/testCenter')}>Test Center</h3>
          <div className='tc-btns'>
            <button className='as-tests-btn' onClick={() => history.push('/testCenter')}>Tests</button>
            <button className='as-subs-btn' type='button'>Subscribers</button>
            <div className='create-subs-btn-div'>
              <button
                className='create-subs-btn'
                onClick={() => history.push({ pathname: '/createSubscriber' })}>
                Create a Subscriber
              </button>
            </div>
          </div>
        </div>
        <main className='tc-white-bg'>
          <aside className='bottom-row'>
            <h4>Active Subscribers</h4>
            <div className='as-filters-search'>
              <div className='as-filters-wrapper'>
                <button
                  className={isActive ?
                    'filters-btn-secondary' :
                    'filters-btn-primary'}
                  onClick={expandHandler}>
                  Filters
                </button>
              </div>
              <input
                id='tc-search'
                placeholder='Enter name'
                autoComplete='off'
                type='text'
              />
            </div>
          </aside>

          {filtersOpen ? (
            <div className='as-filters-container'>
              <div className='as-input-wrapper'>
                <p className='as-filters-p'>Name</p>
                <select
                  id='tc-select'
                  className='tc-input'
                  value={subscriberName}
                  onChange={(e) => {
                    setSubscriberName(e.target.value === 'selectName' ? '' : e.target.value)
                  }}>
                  <option value='selectName'>Select</option>
                  {filteredNames.map((name) =>
                    <option
                      key={`name - option - ${name + 1} `}
                      value={name}>
                      {name}
                    </option>
                  )};
                </select>
                <img src={dropdownIcon} className='as-dropdown-icon' alt='dropdown icon' />
              </div>
              <div className='as-input-wrapper'>
                <p className='as-filters-p'>Source</p>
                <select
                  id='tc-select'
                  className='tc-input'
                  value={subscriberSource}
                  onChange={(e) => {
                    setSubscriberSource(e.target.value === 'selectSource' ? '' : e.target.value)
                  }}>
                  <option value='selectSource'>Select</option>
                  {filteredSources.map((source) =>
                    <option
                      key={`source - option - ${source + 1} `}
                      value={source}>
                      {source}
                    </option>
                  )};
                </select>
                <img src={dropdownIcon} className='as-dropdown-icon' alt='dropdown icon' />
              </div>
              <div className='as-input-wrapper'>
                <p className='as-filters-p'>Product</p>
                <select
                  id='tc-select'
                  className='tc-input'
                  value={subscriberProduct}
                  onChange={(e) => {
                    setSubscriberProduct(e.target.value === 'selectProduct' ? '' : e.target.value)
                  }}>
                  <option value='selectProduct'>Select</option>
                  {filteredProducts.map((product) =>
                    <option
                      key={`product - option - ${product + 1} `}
                      value={product}>
                      {product
                        ?.replace('customer', 'Customer')
                        .replace('corporate', 'Corporate')
                      }
                    </option>
                  )};
                </select>
                <img src={dropdownIcon} className='as-dropdown-icon' alt='dropdown icon' />
              </div>
              <div className='as-input-wrapper'>
                <p className='as-filters-p'>Type</p>
                <select
                  id='tc-select'
                  className='tc-input'
                  value={subscriberType}
                  onChange={(e) => {
                    setSubscriberType(e.target.value === 'selectType' ? '' : e.target.value)
                  }}>
                  <option value='selectTypes'>Select</option>
                  {filteredTypes.map((type) =>
                    <option
                      key={`type - option - ${type + 1} `}
                      value={type}>
                      {type
                        ?.replace('certificate', 'Certificate')
                        .replace('account_only', 'Account only')
                      }
                    </option>
                  )};
                </select>
                <img src={dropdownIcon} className='as-dropdown-icon' alt='dropdown icon' />
              </div>
              <div className='as-input-wrapper'>
                <p className='as-filters-p'>Market</p>
                <select
                  id='tc-select'
                  className='tc-input'
                  value={subscriberMarket}
                  onChange={(e) => {
                    setSubscriberMarket(e.target.value === 'selectMarket' ? '' : e.target.value)
                  }}>
                  <option value='selectMarkets'>Select</option>
                  {countryNamesToShow.map((countryName) =>
                    <option
                      key={`coutry - option - ${countryName + 1} `}
                      value={countryName}>
                      {countryName}
                    </option>
                  )};
                </select>
                <img src={dropdownIcon} className='as-dropdown-icon' alt='dropdown icon' />
              </div>
              <div className='as-input-wrapper'>
                <p className='as-filters-p'>Request Accepted</p>
                <select
                  id='as-select'
                  className='as-select'
                  value={subscriberStatusActivity}
                  onChange={selectStatusActivityHandler}>
                  <option value='Select'>Select</option>
                  <option value='activeSubscriber'>Active</option>
                  <option value='inactiveSubscriber'>Inactive</option>
                </select>
                <img src={dropdownIcon} className='as-dropdown-icon' alt='dropdown icon' />
              </div>
              <div className='as-input-wrapper'>
                <button
                  className='tc-apply-btn'
                  type='button'
                  onClick={updateFormHandler}>
                  Apply
                </button>
              </div>
            </div>
          ) : null}

          <div className='tc-table'>
            <table className='table' width='100%'>
              <thead className='tc-thead'>
                <tr>
                  <th className='as-name'>Name</th>
                  <th className='as-email'>Email</th>
                  <th className='as-product'>Product</th>
                  <th className='as-type'>Type</th>
                  <th className='as-market'>Market</th>
                  <th className='as-from-to'>From - To</th>
                  <th className='as-req'>Request per day</th>
                  <th className='as-action'>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentSubscribers?.map((subscriber) => {
                  return (
                    <tr className='td-main' key={`subscriber${subscriber.id}`}>
                      <td className='td-as-name'>{subscriber.full_name}</td>
                      <td className='td-as-email'>{subscriber.email}</td>
                      <td className='td-as-product'>
                        {subscriber.request_type
                          ?.replace('customer', 'Customer')
                          .replace('corporate', 'Corporate')}
                      </td>
                      <td className='td-as-type'>
                        {subscriber.product
                          ?.replace('certificate', 'Certificate')
                          .replace('account_only', 'Account only')}
                      </td>
                      <td className='td-as-market'>
                        {countries.find((country) => country.id === subscriber.country_id)?.name
                          .replace('denmark', 'Denmark')
                          .replace('finland', 'Finland')
                          .replace('germany', 'Germany')
                          .replace('india', 'India')
                          .replace('netherland', 'Netherland')
                          .replace('norway', 'Norway')
                          .replace('portugal', 'Portugal')
                          .replace('spain', 'Spain')
                          .replace('united kingdom', 'United Kingdom')
                          .replace('united states', 'United States')
                          .replace('sweden', 'Sweden')}
                      </td>
                      <td className='td-as-from-to'>
                        {(() => {
                          if (subscriber.continuously) {
                            return (
                              <>
                                <p className='as-continuously-p'>
                                  Continuously
                                </p>
                              </>
                            );
                          };

                          const todayDate = moment(new Date());
                          const subscriberEndDate = moment(new Date(subscriber.end_date));
                          const difference = todayDate.diff(subscriberEndDate, 'days');
                          if (difference > 0) {
                            return (
                              <>
                                <p className='as-inactive-p'>
                                  Inactive
                                </p>
                              </>
                            );
                          };

                          return (
                            <>
                              {moment(subscriber.start_date).format("DD/MM/YYYY")}
                              <span> to </span>
                              {moment(subscriber.end_date).format("DD/MM/YYYY")}
                            </>
                          )
                        })()}
                      </td>
                      <td className='td-as-req'>
                        {subscriber.request_per_day}
                        <span> | </span>
                        {(() => {
                          const times = Object.values(subscriber.request_times);
                          const maxTimesSize = 2;
                          const toBeContinued = ' ...';
                          if (times.length > 2) {
                            return times.slice(0, maxTimesSize).join(', ') + `${toBeContinued}`;
                          }
                          return times.join(', ');
                        })()}
                      </td>
                      <td className='td-as-action'>
                        <div className='as-icons'>
                          <img
                            src={editIcon}
                            onClick={() => editSubscriber(subscriber.id)}
                            className='as-edit-icon'
                            alt='trash icon' />
                          <img
                            src={trashIconMain}
                            onClick={() => {
                              deleteSubscriberHandler(subscriber.id)
                            }
                            }
                            className='as-trash-icon'
                            alt='trash icon'
                          />
                          {subscriberDeleteModalOpen[subscriber.id] ?
                            <SubscriberDeleteModal
                              isOpen={true}
                              handleClose={handleClose}
                              onClose={handleClose}
                              onDelete={() => deleteSelectedSubscriberIDHandler(subscriber.id)}
                            />
                            : null}
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ActiveSubscribers;
