import "./certificateInformation.scss"

const CertificateSwitcher = ({ props, showAcceptedOnly }) => {
  return (
    <>
      <input className="react-switch-checkbox" id={`react-switch-new`} type="checkbox" onChange={props} checked={showAcceptedOnly} />
      <label className="react-switch-label" htmlFor={`react-switch-new`}>
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default CertificateSwitcher;
