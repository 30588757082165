import React, { useState, useEffect } from "react";
import StackedBarChart from '../GenericBarChart';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getOrganizationLiveData, getLiveDataFilter } from "../../../Redux/Actions/LiveData";
import "./LiveDataOrganisations.scss";
import { setLoaderSpinner } from "../../../Redux/Actions/LoaderSpinner";

const LiveDataOrganisations = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const defaultCountry = 'Sweden';
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);

  const fetchCountries = async () => {
    try {
      await dispatch(setLoaderSpinner(true));
      const countriesResponse = await dispatch(getLiveDataFilter());
      if (countriesResponse && countriesResponse.status === true) {
        let filteredCountries = countriesResponse.data.countries.filter(country => (
          ['sweden', 'norway', 'denmark', 'finland', 'germany', 'united kingdom', 'belgium', 'netherlands', 'spain'].includes(country.name.toLowerCase())
        ));

        setCountries(filteredCountries);
        const sweden = filteredCountries.find(c => c.name.toLowerCase() === defaultCountry.toLowerCase());
        if (sweden) {
          setSelectedCountry(sweden.id);
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
      await dispatch(setLoaderSpinner(false));
    }
  };

  // Fetches organization data based on countryId
  const fetchOrganizationLiveData = async (countryId = '') => {
    try {
      await dispatch(setLoaderSpinner(true));
      const response = await dispatch(getOrganizationLiveData(countryId));
      if (response && response.status === true) {
        setData(response.data.request_count_by_organization);

        const newCountries = response.data.request_count_by_organization
          .map(item => item.country)
          .filter(country => country && !countries.find(c => c.id === country.id));

        if (newCountries.length > 0) {
          setCountries(prevCountries => [...prevCountries, ...newCountries]);
        }
      } else if (response.message === 'Unauthorized') {
        history.push('/login');
      }
    } catch (error) {
    } finally {
      setLoading(false);
      await dispatch(setLoaderSpinner(false));
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry && !initialLoad) {
      fetchOrganizationLiveData(selectedCountry);
      const intervalId = setInterval(() => fetchOrganizationLiveData(selectedCountry), 60000);
      return () => clearInterval(intervalId);
    }
    setInitialLoad(false);
  }, [selectedCountry, initialLoad]);

  const handleCountryChange = async (event) => {
    const countryId = event.target.value;
    setSelectedCountry(countryId);
  };

  return (
    <div className="page-container live-data-container">
      <div className="ld-container">
        <h3 className="ld-header">Organizations - Accepted Request</h3>
        <div className="ld-search-container">
          <form className="ld-form-container" id="ld-form-container">
            <div className="custom-select">
              <select
                className="ld-form-input-field capitalize"
                id="ld-select"
                value={selectedCountry}
                onChange={handleCountryChange}
              >
                {countries.map((country, index) => (
                  <option key={index} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </form>
        </div>
        {data ? <StackedBarChart key={selectedCountry} data={data} /> : null}
      </div>
    </div>
  );
}

export default LiveDataOrganisations;

