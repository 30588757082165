import React from 'react';
import './ServiceSettingsModal.css'
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import removeCredentials from "../../assets/images/icon_minus.png";
import addCredentials from "../../assets/images/icon_close_sms.png";
import { CreateServiceSettings, getServiceSettingsFilter } from "../../Redux/Actions/ServiceSettings";
import _ from "lodash"

const ServiceSettingsModal = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [orggId, setOrggId] = useState([]);
  const [servicesId, setServicesId] = useState([]);
  const [countris, setCountris] = useState([]);
  const [certificateType, setCertificateType] = useState([]);
  const [env, setEnv] = useState([]);
  const [active, setActive] = useState([]);
  const [labelization, setLabelization] = useState([]);

  const [selectedOrdID, setSelectedOrdId] = useState("");
  const [selectedServiceId, setSelectedServiceId] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCertificateType, setSelectedCertificateType] = useState("");
  const [selectedEnv, setSelectedEnv] = useState("");
  const [status, setStatus] = useState("");
  const [selectedLabelization, setSelectedLabelization] = useState("");
  const [selectedBankAuthentication, setSelectedBankAuthentication] = useState("");
  const [brokerApplication, setBrokerApplication] = useState("")
  const [ssnValidation,setSsnValidation]=useState("")
  const [createData, setCreateData] = useState([]);
  const [service, setService] = useState([{ key: "client_id", value: props?.data?.credentials.client_id }, { key: "client_secret", value: props?.data?.credentials.client_secret }, { key: "pem_file_key", value: props?.data?.credentials.pem_file_key }]);
  const [credentialsKey, setCredentialsKey] = useState('');
  const [credentialsValue, setCredentialsValue] = useState('');
  const [userRole, setUserRole] = useState(localStorage.role)

  useEffect(() => {
    fetchServiceSettingCreate();
  }, []);

  const fetchServiceSettingCreate = async (props) => {
    const { data } = await dispatch(getServiceSettingsFilter());
    const { organizations_list, services_list, countries_list } = data;
    setOrggId(organizations_list);
    setServicesId(services_list);
    setCountris(countries_list)
  };

  const submitHandle = async () => {
    let credentialsData = {};
    service.map(item => {
      if (item.value !== '' || item.value === true) {
        credentialsData = {
          ...credentialsData, [item.key]: item.value
        }
      }
    })
    const data = {
      organization_id: selectedOrdID,
      service_id: selectedServiceId,
      country_id: selectedCountry,
      active: JSON.parse(status.replace(/ /g, "")),
      certificate_type: selectedCertificateType,
      env: selectedEnv,
      labelization: JSON.parse(selectedLabelization.replace(/ /g, "")),
      bank_authentication_approach: selectedBankAuthentication,
      broker_application: JSON.parse(brokerApplication.replace(/ /g, "")),
      ssn_validation: JSON.parse(ssnValidation.replace(/ /g, "")),
      credentials: credentialsData
    }
    const response = await dispatch(CreateServiceSettings(data))
    // fetchServiceSettingCreate();
    if (response.status) {
      if (response.message === "Unauthorize") {
        history.push("/login")
      } else {
        // setCreateData(response?.data);
        if (response.message === "success") {
          props.openFlash("tick", "Done!", response.renderMessage);
        } else {
          props.openFlash("alert", "Error!", response.renderMessage)
        }
      }
    }
    props.closeDelete(false);
  }

  const handleServiceKey = (e, index) => {
    const { name, value } = e.target;
    const list = [...service];
    list[index][name] = value;
    setService(list);
    setCredentialsKey(value);
  };

  const handleServiceValue = (e, index) => {
    const { name, value } = e.target;
    const list = [...service];
    list[index][name] = value;
    setService(list);
    setCredentialsValue(value);
  };

  const handleServiceAdd = () => {
    if (service.length < 4) {
      setService([...service, { key: "", value: "" }]);
    }
  };

  const handleServiceRemove = (index) => {
    const list = [...service];
    let newList = _.pull(list, list[index])
    setService(newList);
  };

  function humanize(str) {
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  return (
    <div className='EditPopup'>
      <div className='EditPopup_inner'>
        <h3>Create Service Setting</h3>
        <form>
          <div className="row">
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>Organization</label>
                <select className="select-filter-service-settings" onChange={(e) => setSelectedOrdId(e.target.value)} required>
                  <option value="">Select</option>
                  {
                    orggId && orggId.map((oid, index) => (
                      <option value={oid.id} key={`orggId-option-${index + 1}`}>
                        {oid.name} ({oid.id})
                      </option>
                    ))
                  }
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>Service</label>
                <select className="select-filter-service-settings" onChange={(e) => setSelectedServiceId(e.target.value)} required>
                  <option value="">Select</option>
                  {
                    servicesId && servicesId.map((service, index) => (
                      <option value={service.id} key={`servicesId-option-${index + 1}`}>
                        {humanize(service.name)}
                      </option>
                    ))
                  }
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>Country</label>
                <select className="select-filter-service-settings" onChange={(e) => setSelectedCountry(e.target.value)} required>
                  <option value="">Select</option>
                  {
                    countris && countris.map((_country, index) => (
                      <option value={_country.id} key={`countris-option-${index + 1}`}>
                        {humanize(_country.name)}
                      </option>
                    ))
                  }
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>Certificate Type</label>
                <select className="select-filter-service-settings" onChange={(e) => setSelectedCertificateType(e.target.value)} required>
                  <option value="">Select</option>
                  <option value="customer">Customer</option>
                  <option value="corporate">Corporate</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>ENV</label>
                <select className="select-filter-service-settings" onChange={(e) => setSelectedEnv(e.target.value)} required>
                  <option value="">Select</option>
                  <option value="testing">Testing</option>
                  <option value="production">Production</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>Active</label>
                <select className="select-filter-service-settings" onChange={e => setStatus(e.target.value)} required>
                  <option value="">Select</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>Labelization</label>
                <select className="select-filter-service-settings" onChange={e => setSelectedLabelization(e.target.value)} required>
                  <option value="">Select</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>Bank Authentication Approach</label>
                <select className="select-filter-service-settings" onChange={e => setSelectedBankAuthentication(e.target.value)} required>
                  <option value="">Select</option>
                  <option value="decoupled">Decoupled</option>
                  <option value="redirect">Redirect</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>Broker Application</label>
                <select className="select-filter-service-settings" onChange={e => setBrokerApplication(e.target.value)} required>
                  <option value="">Select</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="setting-filter-container">
                <label>Ssn Validation</label>
                <select className="select-filter-service-settings" onChange={e => setSsnValidation(e.target.value)} required>
                  <option value="">Select</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>
            {userRole === 'admin' &&
              <div className="col-md-12">
                <div className="setting-filter-container">
                  <label>Credentials <span><img src={addCredentials} alt="plus" type="button" onClick={handleServiceAdd} /></span>
                    {/*{ 
                  service.length - 1 === index && service.length < 4 && (<button><img src={addCredentials} alt="plus" type="button" onClick={handleServiceAdd}/></button>)
                }*/}
                  </label>
                  {service.map((singleService, index) => (
                    <div key={index} className="services">
                      <div className="add-credentials">
                        <select className="select-filter-service-settings credentials-key" name="key" id="service" value={singleService.key} onChange={(e) => handleServiceKey(e, index)} required>
                          <option value="">Select</option>
                          <option value="client_id">Client Id</option>
                          <option value="client_secret">Client Secret</option>
                          <option value="pem_file_key">Pem File Key</option>
                        </select>
                        <input type="text" name="value" placeholder="Value" id="service" className="credentials-value" value={singleService.value} onChange={(e) => handleServiceValue(e, index)} />
                        {
                          service.length !== 0 && (<span> <img src={removeCredentials} alt="plus" type="button" onClick={() => handleServiceRemove(index)} /></span>)
                        }
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            }
          </div>
          <div className="modal-btns">
            <button className='closeDelete-update' style={{ marginLeft: '0px' }} onClick={props.closeDelete}>Cancel</button>
            <button className='confirm-update' type='button' onClick={submitHandle}>Confirm</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ServiceSettingsModal;