import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
import { getComparisonReportList } from "../../Redux/Actions/ComparisonReport";
import { useHistory } from "react-router-dom";
import calendarIconWhite from "../../assets/images/calendar_icon_white.svg";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import PaginationComparison from "./Pagination";
import SearchFilter from "../../Components/ComparisonReport/SearchFilter";
import { getAllCountry } from "../../Redux/Actions/Countries";

import "./comparison.css";

const date = new Date();
function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}
const START_DATE = moment(
  new Date(date.getFullYear(), date.getMonth(), 1)
).format("DD/MM/YYYY");
const END_DATE = moment(
  new Date(
    date.getFullYear(),
    date.getMonth(),
    daysInMonth(date.getMonth() + 1, date.getFullYear())
  )
).format("DD/MM/YYYY");

const START_DATE_TWO = moment(
  new Date(date.getFullYear(), date.getMonth(), 1)
).format("DD/MM/YYYY");
const END_DATE_TWO = moment(
  new Date(
    date.getFullYear(),
    date.getMonth(),
    daysInMonth(date.getMonth() + 1, date.getFullYear())
  )
).format("DD/MM/YYYY");

const Comparison = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [countStatus, setCountStatus] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState(
    `${START_DATE}-${END_DATE}`
  );
  const [selectedDateRangeTwo, setSelectedDateRangeTwo] = useState(
    `${START_DATE_TWO}-${END_DATE_TWO}`
  );

  const [comparisonTableData, setComparisonTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [acceptedCountVisible, setAcceptedCountVisible] = useState(true);
  const [requestedCount, setRequestedCount] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedVia, setSelectedVia] = useState("");
  const [acceptedResponse, setAcceptedResponse] = useState([])
  const [requestedResponse, setRequestedResponse] = useState([])
  const [countries, setCoutries] = useState([])
  const [isSearched, setIsSearched] = useState(false)

  //-- Search Filter by Organizasion id -- //
  const filterArticles = (searchValue) => {
    if (searchValue === "" && requestedCount === false) {
      return acceptedResponse;
    } else if (requestedCount && searchValue === "") {
      return requestedResponse
    }
    return comparisonTableData.filter((currentItemsPerPage) =>
      currentItemsPerPage.name
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    );
  };

  const filteredArticles = filterArticles(searchValue);
  //--End of search filter --//

  useEffect(() => {
    fetchComparisonReportData();
    fetchCountries();
  }, []);


  const fetchCountries = async () => {
    const { data } = await dispatch(getAllCountry());
    const { countries_list } = data;
    setCoutries(countries_list);
  };

  const indexOfLastTableItem = currentPage * itemsPerPage;
  const indexOfFirstTableItem = indexOfLastTableItem - itemsPerPage;
  let itemsPerOnePage;
  if (isSearched) {
    if (filteredArticles.length < 10) {
      itemsPerOnePage = filteredArticles
    } else {
      itemsPerOnePage = filteredArticles?.slice(
        indexOfFirstTableItem,
        indexOfLastTableItem
      );
    }
  } else {
    itemsPerOnePage = filteredArticles?.slice(
      indexOfFirstTableItem,
      indexOfLastTableItem,
    );
  }
  const nPages = filteredArticles && Math.ceil(filteredArticles.length / itemsPerPage);

  const fetchComparisonReportData = async () => {
    const params =
      "?date_from=" +
      selectedDateRange +
      "&date_with=" +
      selectedDateRangeTwo +
      "&product=" +
      selectedVia +
      "&country_id=" +
      selectedCountry +
      "&type=" +
      selectedType +
      '&query=' +
      searchValue
      ;

    await dispatch(setLoaderSpinner(true));
    const { status, message } = await dispatch(getComparisonReportList(params));

    const { accepted_response, requested_response } = message;

    //-- Sorting accepted data from and with to descending order --//
    let acceptedForWith = accepted_response.map((index) => {
      if (index.accepted_count_with >= index.accepted_count_for) {
        return { ...index, highest: index.accepted_count_with }
      }
      else if (index.accepted_count_for > index.accepted_count_with) {
        return { ...index, highest: index.accepted_count_for }
      }
    })

    let sortedAccepted = acceptedForWith.sort((function (a, b) { return b.highest - a.highest }))

    let requestedForWith = requested_response.map((index) => {
      if (index.requested_count_with >= index.requested_count_for) {
        return { ...index, reqhighest: index.requested_count_with }
      }
      else if (index.requested_count_for > index.requested_count_with) {
        return { ...index, reqhighest: index.requested_count_for }
      }
    })

    let sortedRequested = requestedForWith.sort((function (a, b) { return b.reqhighest - a.reqhighest }))
    //-- End of sorting --//

    setAcceptedResponse(sortedAccepted);
    setRequestedResponse(sortedRequested);
    setComparisonTableData(accepted_response);


    if (!status) {
      history.push("/login");
    }
    await dispatch(setLoaderSpinner(false));
  };

  const toggleCountStatus = () => {
    setCountStatus(!countStatus);
  };

  //-- Accepted / Requests count --//
  const handleAcceptedTableContent = () => {
    setAcceptedCountVisible(true);
    setRequestedCount(false);
  };

  const handleRequestedTableContent = () => {
    setRequestedCount((isVisible) => !isVisible);
    setAcceptedCountVisible(false);
  };

  //--Apply btn --
  const handleApplySubmit = (e) => {
    setCurrentPage(1)
    fetchComparisonReportData();
  };

  //-- Date picker --
  function getStartDate(d) {
    return moment(new Date(d.getFullYear(), d.getMonth() - 1, 1)).format(
      "DD/MM/YYYY"
    );
  }

  function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }
  function getEndDate(d) {
    var lastDay = new Date(
      d.getFullYear(),
      d.getMonth() - 1,
      daysInMonth(d.getMonth() - 1, d.getFullYear())
    );

    return moment(lastDay).format("DD/MM/YYYY");
  }

  function getStartDateTwo(d) {
    return moment(new Date(d.getFullYear(), d.getMonth(), 1)).format("DD/MM/YYYY");
  }
  function getEndDateTwo(d) {
    var lastDay = new Date(
      d.getFullYear(),
      d.getMonth(),
      daysInMonth(d.getMonth() - 1, d.getFullYear())
    );

    return moment(lastDay).format("DD/MM/YYYY");
  }

  const handleCallback = (start, end, label) => {
    const startDateValue = moment(start._d).format("DD/MM/YYYY");
    const endDateValue = moment(end._d).format("DD/MM/YYYY");
    setSelectedDateRange(`${startDateValue} - ${endDateValue}`);
    const dateOne = document.querySelector(".dateOne");
    dateOne.innerHTML = `${startDateValue.replaceAll(
      "/",
      "-"
    )} - ${endDateValue.replaceAll("/", "-")}`;
  };

  const handleCallbackTwo = (start, end) => {
    const startDateValue = moment(start._d).format("DD/MM/YYYY");
    const endDateValue = moment(end._d).format("DD/MM/YYYY");
    setSelectedDateRangeTwo(`${startDateValue}-${endDateValue}`);
    const dateTwo = document.querySelector(".dateTwo");
    dateTwo.innerHTML = `${startDateValue.replaceAll(
      "/",
      "-"
    )} - ${endDateValue.replaceAll("/", "-")}`;
  };

  const requestCountHandler = () => {
    setCurrentPage(1)
    setComparisonTableData(requestedResponse)
  }

  const acceptedCountHandler = () => {
    setCurrentPage(1)
    setComparisonTableData(acceptedResponse)
  }

  return (
    <>
      <div className="page-container">
        <div className="comparsion-header-one">
          <div className="comparison-header-content">
            <h3>Comparison report </h3>
          </div>
          <div className="comparison-header-content">
            <div className="header-left-content">
              <div>
                <ul className="switch-accept-request-count">
                  <li
                    className={countStatus ? "activeAcceptedCount" : "inactive"}
                    style={{
                      marginLeft: "-32px",
                      borderRight: "1px solid #E3E7EE",
                      borderTopLeftRadius: "6px",
                      borderBottomLeftRadius: "6px",
                    }}
                    onClick={() => {
                      toggleCountStatus();
                      handleAcceptedTableContent();
                      acceptedCountHandler()
                    }}
                  >
                    Accepted Count
                  </li>
                  <li
                    className={
                      countStatus ? "inactive" : "activeRequestedCount"
                    }
                    style={{
                      borderTopRightRadius: "6px",
                      borderBottomRightRadius: "6px",
                    }}
                    onClick={() => {
                      toggleCountStatus();
                      handleRequestedTableContent();
                      requestCountHandler()
                    }}
                  >
                    Requests Count
                  </li>
                </ul>
              </div>
              <div>
                <SearchFilter
                  callback={(searchKey) => setSearchValue(searchKey)}
                  setIsSearched={setIsSearched}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="comparison-header">
          <div className="comparison-filter-section  select-filter-wrapper ">
            <label>Compare from</label>
            <DateRangePicker
              initialSettings={{
                startDate: getStartDate(new Date()),
                endDate: getEndDate(new Date()),
                locale: { format: "DD/MM/YYYY" },
              }}
              onCallback={handleCallback}
            >
              <input type="text" className="select-filter-comparison" />
            </DateRangePicker>
            <span className="calenderI">
              <img src={calendarIconWhite} alt="calendarIcon" />
            </span>
          </div>
          <div className="comparison-filter-section  select-filter-wrapper">
            <label>Compare with</label>
            <DateRangePicker
              initialSettings={{
                startDate: getStartDateTwo(new Date()),
                endDate: getEndDateTwo(new Date()),
                locale: { format: "DD/MM/YYYY" },
              }}
              onCallback={handleCallbackTwo}
            >
              <input type="text" className="select-filter-comparison" />
            </DateRangePicker>
            <span className="calenderI">
              <img src={calendarIconWhite} alt="calendarIconWhite" />
            </span>
          </div>
          <div className="comparison-filter-section select-filter-wrapper">
            <label>Country</label>
            <select
              className="select-filter-comparison capitalize-selection"
              onChange={(e) => setSelectedCountry(e.target.value)}
            >
              <option value="">All</option>
              {countries.map((country) => (
                <option value={country.id} key={country.id}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
          <div className="comparison-filter-section select-filter-wrapper">
            <label>Product</label>
            <select
              className="select-filter-comparison capitalize-selection"
              onChange={(e) => setSelectedVia(e.target.value)}
            >
              <option value="">All</option>
              <option value={"customer"}>customer</option>
              <option value={"corporate"}>corporate</option>
            </select>
          </div>
          <div className="comparison-filter-section select-filter-wrapper">
            <label>Type</label>
            <select
              className="select-filter-comparison capitalize-selection"
              onChange={(e) => setSelectedType(e.target.value)}
            >
              <option value="">All</option>
              <option value={"manual_request"}>Manual Request</option>
              <option value={"api"}>API</option>
              <option value={"iframe"}>Iframe</option>
            </select>
          </div>
          <div className="comparison-filter-section select-filter-wrapper">
            <button
              className="apply-filter-button-comparison"
              onClick={handleApplySubmit}
            >
              Apply
            </button>
          </div>
        </div>
        <div className="table-frame">
          <table width="100%">
            {/* <thead className="table-header-comparison-report "> */}
            <thead>
              <tr className="header-rows">
                <th
                  className="table-header-title comparison-td-column-1 header-org-id"
                  scope="col"
                >
                  Organizasion (ID)
                </th>
                <th
                  className="table-header-title dateOne table-column-two"
                  scope="col"
                >
                  {START_DATE.replaceAll("/", "-")}-
                  {END_DATE.replaceAll("/", "-")}
                </th>
                <th
                  className="table-header-title dateTwo table-column-three"
                  scope="col"
                >
                  {START_DATE_TWO.replaceAll("/", "-")} -{" "}
                  {END_DATE_TWO.replaceAll("/", "-")}
                </th>
                <th
                  className="table-header-title table-column-four"
                  scope="col"
                >
                  % +/-
                </th>
                <th
                  className="table-header-title table-column-five"
                  scope="col"
                >
                  Active (Y/N)
                </th>
                <th
                  className="table-header-title tb-col-6 header-col-6"
                  scope="col"
                >
                  <p className="errors-th-content">
                    Accepted requests
                    <br />
                    without errors
                  </p>
                </th>
              </tr>
            </thead>
          </table>
          <table className="table" width="100%" data={itemsPerOnePage}>
            <tbody className="accepted-count-table-content">
              {acceptedCountVisible &&
                itemsPerOnePage?.map((val, index) => (
                  <tr className="row comparison-table-row" key={index}>
                    <td
                      className="comparison-td-column-1 table-column-one"
                      key={Math.random()}
                    >
                      {val.name}
                    </td>
                    <td
                      className="comparison-td table-column-two"
                      key={Math.random()}
                    >
                      {val.accepted_count_for}
                    </td>
                    <td
                      className=" comparison-td table-column-three"
                      key={Math.random()}
                    >
                      {val.accepted_count_with}
                    </td>
                    <td className={val.percentage_rate < 0 ? "comparison-td table-column-four pre accepted-precent" : "comparison-td table-column-four pre precent"}
                      key={Math.random()}>
                      {Number(val.percentage_rate).toFixed(2) ? Number(val.percentage_rate).toFixed(2) : 0}
                    </td>
                    <td
                      className=" comparison-td table-column-five"
                      key={Math.random()}
                    >
                      {val.active ===
                        true ? (
                        <p className="active-status-true">Yes</p>
                      ) : (
                        <p className="active-status-false">No</p>
                      )}{" "}
                    </td>
                    <td
                      className="comparison-td-col-6 td-col-6"
                      key={Math.random()}
                    >
                      {
                        val.accepted_count_without_errors_requested
                      }
                    </td>
                  </tr>
                ))}
              {requestedCount &&
                itemsPerOnePage.map((val, index) => (
                  <tr className="row comparison-table-row" key={index}>
                    <td
                      className="comparison-td-column-1 table-column-one"
                      key={Math.random()}
                    >
                      {val.name}
                    </td>
                    <td
                      className="comparison-td table-column-two"
                      key={Math.random()}
                    >
                      {val.requested_count_for}
                    </td>
                    <td
                      className=" comparison-td table-column-three"
                      key={Math.random()}
                    >
                      {val.requested_count_with}
                    </td>
                    <td className={val.percentage_rate < 0 ? "comparison-td table-column-four pre accepted-precent" : "comparison-td table-column-four pre precent"}
                      key={Math.random()}>
                      {Number(val.percentage_rate).toFixed(2) ? Number(val.percentage_rate).toFixed(2) : '-'}
                    </td>
                    <td
                      className=" comparison-td table-column-five"
                      key={Math.random()}
                    >
                      {val.active ===
                        true ? (
                        <p className="active-status-true">Yes</p>
                      ) : (
                        <p className="active-status-false">No</p>
                      )}{" "}
                    </td>
                    <td
                      className="comparison-td-col-6 td-col-6"
                      key={Math.random()}
                    >
                      {
                        val.accepted_count_without_errors_requested
                      }
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div>
            {filteredArticles && filteredArticles.length > 10 ? (
              <PaginationComparison
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Comparison;
