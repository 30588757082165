import React, { useState } from 'react';
import '../EditModalBank/editModalBank.css'
import { useForm } from 'react-hook-form';

const DeactivateUserModal = ({ closeDelete, deactivateItem, deactivateUserHandler }) => {

  const handleSubmit = async (e) => {
    e.preventDefault()
    deactivateUserHandler()
    // const formData = new FormData();
    // const post = { ...data, file_name: data.file_name[0] }
    // formData.append('file_name', post.file_name)
    // props.confirm(formData)
    // reset()
  }
  return (
    <div className='EditPopup'>
      <div className='EditPopup_inner'>
        <div className="deactivate-header">
          <h3 className='deactivate-black'>Deactivate User</h3>
          <p>Your are about to deactivate this user.<br />Are you sure ?</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="setting-filter-container input-credentials">
            <div className="deactivate-user-data table-responsive">
              <table>
                <tr>
                  <td className="user-color">User Id</td>
                  <td>{deactivateItem?.id}</td>
                </tr>
                <tr>
                  <td className="user-color">Type</td>
                  <td>{deactivateItem?.role === "employee" ? "Administrative Officer" : "Administrator"}</td>
                </tr>
                <tr>
                  <td className="user-color">Name</td>
                  <td>{deactivateItem?.name}</td>
                </tr>
                <tr>
                  <td className="user-color">Email</td>
                  <td>{deactivateItem?.email}</td>
                </tr>
              </table>
            </div>
          </div>
          <div className="modal-btns">
            <button className='closeDelete-update' onClick={closeDelete} style={{ marginLeft: '0px' }}>Cancel</button>
            <button className='confirm-update deactivate-btn' type='submit'>Deactivate</button>
          </div>
        </form>
      </div>
    </div>
  )
}
export default DeactivateUserModal;