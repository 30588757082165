import React, { useEffect } from "react";
import Header from "../../Components/Header/header";
import Sidebar from "../../Components/Sidebar/sidebar";
import AccountUsers from "../../Components/Statistics/VistaAccountUsers/AccountUsers.jsx";
//import Conversion from "../../Components/KreditzProviders/KreditzProviders";
//import "./kreditzProviders.css";
const VistaAccoutnUsers = () => {
  // useEffect(() => {
  //   document.title = "Kreditz | Admin portal - Conversion Rate";
  // }, []);
  return (
    <div className="wrapper">
      <Header />
      <div className="main-container">
        <Sidebar />
        <AccountUsers />
      </div>
    </div>
  );
};

export default VistaAccoutnUsers;