import React, { useMemo } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./GenericBarChart.scss";

const StackedBarChart = (props) => {
  const isValidData = Array.isArray(props.data) && props.data.length > 0;

  // Calculate dynamic chart height based on the number of items
  const chartHeight = useMemo(() => {
    const baseHeight = 400;
    const incrementPerItem = 20;
    const extraItems = Math.max(0, props.data.length - 10);
    return baseHeight + (incrementPerItem * extraItems);
  }, [props.data]);

  const { categories, currentCount } = useMemo(() => {
    if (isValidData) {
      const sortedData = [...props.data].sort((a, b) => {
        const aValue = a.count || a.accepted_requests_count;
        const bValue = b.count || b.accepted_requests_count;
        return bValue - aValue;
      });

      return {
        categories: sortedData.map(item => item.organization_name || item.market),
        currentCount: sortedData.map(item => item.count || item.accepted_requests_count)
      };
    }
    return { categories: [], currentCount: [] };
  }, [props.data, isValidData]);


  const options = useMemo(() => ({
    chart: {
      type: 'bar',
      height: chartHeight,
      scrollablePlotArea: {
        minHeight: chartHeight,
        scrollPositionX: 1
      },
      marginTop: 0,
      marginBottom: 60,
      animation: true,
      events: {
        load: function () {
          let chart = this;
          let timeLabel = chart.renderer.label('', 0, 0, 'rect', 0, 0, true)
            .css({
              color: '#8591A6',
              fontSize: '20px',
              fontFamily: "SF Pro Display",
            })
            .add();

          setInterval(function () {
            let now = new Date();
            let hours = now.getHours();
            let minutes = now.getMinutes();
            let ampm = hours >= 12 ? 'PM' : 'AM';
            minutes = minutes < 10 ? '0' + minutes : minutes;
            let time = hours + ':' + minutes + ' ' + ampm;

            timeLabel.attr({
              text: time,
              x: chart.plotLeft + chart.plotWidth - timeLabel.width - 0,
              y: chart.plotTop + chart.plotHeight + 20
            });
          }, 0);
        }
      }
    },
    title: {
      text: null,
      margin: 0,
    },
    xAxis: {
      categories: categories,
      gridLineDashStyle: 'longDash',
      lineWidth: 0,
      labels: {
        style: {
          color: "#8591A6",
          fontFamily: "SF Pro Display",
          fontSize: "16px",
        },
        formatter: function () {
          return this.value.charAt(0).toUpperCase() + this.value.slice(1);
        }
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      allowDecimals: false,
      gridLineColor: "#CFD7E5",
      gridLineDashStyle: "longDash",
      gridLineWidth: 0.5,
      offset: -10,
    },
    plotOptions: {
      bar: {
        minPointLength: 0,
        animation: true,
        groupPadding: 10.25,
        pointPadding: 0.1,
        pointWidth: 10,
        borderRadius: 0,
        dataLabels: {
          enabled: true,
          inside: false,
          format: '{point.y}',
          color: "grey",
          style: {
            fontWeight: "normal",
            fontSize: "13px",
            fontFamily: "SF Pro Display",
          },
          defer: 2000
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        animation: true,
        data: currentCount,
        color: "#3e85f9",
        fontFamily: "SF Pro Display",
        borderRadiusTopLeft: "0px",
        borderRadiusTopRight: "0px",
        tooltip: {
          headerFormat: "<span>{point.key} requests:<br></br></span>",
          pointFormat: '<span class="highcharts-tooltip-live-data">{point.y}</span>',
        },
        showInLegend: false,
      }
    ]
  }), [categories, currentCount, chartHeight]);

  return (
    <div id="bar-chart-container">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        key={JSON.stringify(currentCount)}
      ></HighchartsReact>
    </div>
  );
};

export default StackedBarChart;
