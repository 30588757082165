import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import ClientViewGraph, { MONTH } from "./clientViewGraph";
import "./Scss/dashboard.scss";
import DashboardFilter from "./dashboardFilter";
import RecordCount from "./recordCount";
import List from "./list";
import { getOrganizationDetails } from '../../Redux/Actions/Dashboard';
import moment from "moment";
import { setLoaderSpinner } from '../../Redux/Actions/LoaderSpinner';

const date = new Date();
function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}
const START_DATE = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('DD/MM/YYYY');
const END_DATE = moment(new Date(date.getFullYear(), date.getMonth(), daysInMonth(date.getMonth() + 1, date.getFullYear()))).format('DD/MM/YYYY')


const ClientPage = (props) => {
  const dispatch = useDispatch();
  const [orgId, setOrgId] = useState(props.orgId)
  const [totalRecordCount, settotalRecordCount] = useState({});
  const [page, setPage] = useState(1);
  const [organizationUserList, setOrganizationUserList] = useState([])
  const [paginationData, setpaginationData] = useState({ current_page: 1, total_pages: '', total_count: '', sort_column: 'accepted', sort_order: 'desc' })
  const [searchKey, setSearchKey] = useState("");
  const [organization, setOrganization] = useState({});
  const [acceptedRequest, setAcceptedRequest] = useState({})
  const [sentRequests, setSentRequests] = useState({})
  const [selectedDateRange, setSelectedDateRange] = useState(`${START_DATE}-${END_DATE}`);
  const [dateFilter, setDateFilter] = useState(false)

  useEffect(() => {
    getClientViewDetails();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, paginationData.sort_column, paginationData.sort_order, selectedDateRange, searchKey, orgId]);


  const getClientViewDetails = async (date_range = null) => {
    var date = date_range || MONTH
    const data = "?page=" + page + "&date_range=" + date + "&id=" + orgId + "&query=" + searchKey
    await dispatch(setLoaderSpinner(true))
    const res = await dispatch(getOrganizationDetails(data))
    if (res.status) {
      const { total_requests, total_accepted_requests, total_cancelled_requests, total_deleted_requests, total_past_due_date_requests, total_pending_requests } = res.data
      settotalRecordCount({ total_requests: total_requests, total_accepted_requests: total_accepted_requests, total_pending_requests: total_pending_requests, total_cancelled_requests: total_cancelled_requests, total_deleted_requests: total_deleted_requests, total_past_due_date_requests: total_past_due_date_requests })
      setOrganizationUserList(res.data.total_organization_with_request)
      setAcceptedRequest(res.data.graph_data.accepted_requests)
      setSentRequests(res.data.graph_data.sent_requests)
      setOrganization(res.data.organization)
      setpaginationData(prevState => ({
        ...prevState,
        current_page: res.data.current_page,
        total_count: res.data.total_count,
        total_pages: res.data.total_pages
      }))
    }
    await dispatch(setLoaderSpinner(false))
  }

  const getDataByDate = () => {
    setDateFilter(true)
    getClientViewDetails(selectedDateRange)
  }
  const applySorting = (type) => {
    const sortOrder = paginationData.sort_column === type ? paginationData.sort_order === 'asc' ? 'desc' : 'asc' : 'asc'
    setpaginationData(prevState => ({
      ...prevState,
      sort_column: type,
      sort_order: sortOrder
    }))
  }

  return (
    <div className="dashboard-page-container">
      <DashboardFilter organization={organization} selectedDateRange={selectedDateRange} setDateRange={(value) => setSelectedDateRange(value)} handleSubmit={() => getDataByDate()} />
      <div className="ds-container">
        <div className="column1">
          <ClientViewGraph
            accepted_requests={acceptedRequest}
            sent_requests={sentRequests}
            getClientViewDetails={(date) => getClientViewDetails(date)}
            dateFilter={dateFilter}
            setDateFilter={(value) => setDateFilter(value)} />
        </div>
        <div className="column4">
        </div>
      </div>

      <div className="ds-second">
        <div className="ds-second-top">
          <RecordCount totalRecordCount={totalRecordCount} />
        </div>
        <div className="ds-second-bottom">
          <List searchKey={searchKey}
            setOrgId={(newOrgId) => setOrgId(newOrgId)}
            orgId={orgId}
            searchKeyValue={(value) => setSearchKey(value)}
            organizationUserList={organizationUserList}
            selectPageHandler={(e) => setPage(e.selected + 1)}
            paginationData={paginationData} applySorting={applySorting} />
        </div>
      </div>
    </div>
  )
}

export default ClientPage;