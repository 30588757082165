import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { setLoaderSpinner } from '../../Redux/Actions/LoaderSpinner';
import { getTotalClientsData } from "../../Redux/Actions/TotalClients";
import ReactPaginate from 'react-paginate'
import moment from "moment";

const NewClientsThisYear = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentYear, setCurrentYear] = useState(0);
  const [clientsData, setClientsData] = useState([]);
  const itemsPerPage = 30;
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    fetchClientsList();
    calculateCurrentYear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchClientsList = async () => {
    await dispatch(setLoaderSpinner(true))
    const data = await dispatch(getTotalClientsData())
    await dispatch(setLoaderSpinner(false))
    if (data.status) {
      setClientsData(data.data) //.total_clients)
    } else {
      setClientsData([]);
      history.push('/login');
    }
  }

  const filteredClientsData = clientsData.filter((client) => {
    const clientYear = moment(client.created_at).year();
    return clientYear === currentYear;
  });

  const sortedClientsData = filteredClientsData.sort((a, b) => {
    const dateA = new Date(a.last_activity_at);
    const dateB = new Date(b.last_activity_at);
    return dateB - dateA;
  });

  const offset = currentPage * itemsPerPage;
  const currentPageData = sortedClientsData.slice(offset, offset + itemsPerPage);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  const handleOptionChange = (event) => {
    const selectedOption = event.target.value;

    switch (selectedOption) {
      case 'Total clients':
        history.push('/total_clients');
        break;
      case 'Clients current week':
        history.push('/new_clients_week');
        break;
      case 'Clients current month':
        history.push('/new_clients_month');
        break;
      case 'Clients current year':
        history.push('/new_clients_year');
        break;
      default:
        break;
    }
  };

  const calculateCurrentYear = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    setCurrentYear(currentYear);
  };

  const findDuplicates = (data) => {
    const counts = {};
    data.forEach((item) => {
      if (counts[item.parent_organization_id]) {
        counts[item.parent_organization_id]++;
      } else {
        counts[item.parent_organization_id] = 1;
      }
    });
    return counts;
  };

  const duplicatesCount = findDuplicates(sortedClientsData);

  return (
    <div className=" page-container">
      <h3>New clients - current year - {currentYear}</h3>
      <div className="select-container-clients">
        <select onChange={handleOptionChange} defaultValue="Clients current year">
          <option value="Total clients">Total clients</option>
          <option value="Clients current week">Clients - current week</option>
          <option value="Clients current month">Clients - current month</option>
          <option value="Clients current year">Clients - current year</option>
        </select>
      </div>
      <div className="bg-white-organizations bg-20-top" style={{ margin: "20px 0 0 0" }}>
        <table className="table total-clients-table-new" width="100%">
          <thead>
            <tr className="total-clients-table-head table-header-clients-year">
              <td>ID</td>
              <td>Organization</td>
              <td>Contact person</td>
              <td>Total users</td>
            </tr>
          </thead>
        </table>
        {sortedClientsData.length > 0 ? (
          <>
            <div className="organizations-thead-scroll-y" style={{ maxHeight: "400px", overflowY: "auto" }}>
              <table className="table table-body-clients-year" width="100%">
                <tbody>
                  {currentPageData.map((item, id) => (
                    <tr id={id} style={duplicatesCount[item.parent_organization_id] > 1 ? { backgroundColor: '#f4f6fb' } : {}}>
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                      <td>{item.company_contact}</td>
                      <td>{item.total_users}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <p></p>
        )}
        <div className="pagination-box">
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            breakLabel={"..."}
            pageCount={Math.ceil(sortedClientsData.length / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div >
  )
}
export default NewClientsThisYear;
