import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import Highcharts from 'highcharts';
import highchartsMap from 'highcharts/modules/map';
import HighchartsReact from 'highcharts-react-official';
import worldMapData from './maps/worldwide.geo.json';
import europeMapData from './maps/europe.geo.json';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import './Worldmap.scss';
import ShowDetails from './misc/ShowDetails';

highchartsMap(Highcharts);

const WorldmapChart = React.memo(({ mapType, data }) => {
  const containerRef = useRef(null);
  const chartComponentRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [chartHeight, setChartHeight] = useState(window.innerHeight * 0.8);
  const mapData = useMemo(() => {
    return mapType === 'Europe' ? europeMapData : worldMapData;
  }, [mapType]);

  const updateChartSize = useCallback(() => {
    const chart = chartComponentRef.current?.chart;
    chart?.setSize(
      isFullscreen ? window.innerWidth : containerRef.current.clientWidth,
      isFullscreen ? window.innerHeight : chartHeight
    );
  }, [isFullscreen, chartHeight]);

  const clearMapBubbles = () => {
    const chart = chartComponentRef.current?.chart;
    const mapBubbleSeries = chart?.series?.find(series => series.type === 'mapbubble');
    if (mapBubbleSeries) {
      mapBubbleSeries.setData([], true);
    }
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      containerRef.current.requestFullscreen().catch((err) => { });
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const zoomIn = () => {
    const chart = chartComponentRef.current?.chart;
    chart?.mapZoom(0.5);
  };

  const zoomOut = () => {
    const chart = chartComponentRef.current?.chart;
    chart?.mapZoom(2);
  };

  useEffect(() => {
    const handleResize = () => {
      setChartHeight(window.innerHeight * 0.8);
      clearMapBubbles();
      updateChartSize();
    };
  
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
      clearMapBubbles();
      updateChartSize();
    };
  
    window.addEventListener('resize', handleResize);
    document.addEventListener('fullscreenchange', handleFullscreenChange);
  
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [updateChartSize]);

  useEffect(() => {
    updateChartSize();
  }, [isFullscreen, chartHeight, data, updateChartSize]);

  //Bubble animation
  useEffect(() => {
    const chart = chartComponentRef.current?.chart;
    if (chart) {
      const mapBubbleSeries = chart?.series?.find(series => series.type === 'mapbubble');

      if (mapBubbleSeries) {
        mapBubbleSeries.update({
          data: (data || []).map(item => ({ ...item, z: item.z })),
          animation: {
            duration: 1000,
            easing: 'easeOut'
          }
        }, true);

        const animationTimeoutId = setTimeout(() => {
          if (mapBubbleSeries.points) {
            mapBubbleSeries.points.forEach(point => {
              point.graphic?.element.classList.add('my-animated-bubble');
            });
          }
        }, 1000);

        const removalTimeoutId = setTimeout(() => {
          if (mapBubbleSeries.points) {
            mapBubbleSeries.points.forEach(point => {
              point.graphic?.element.classList.remove('my-animated-bubble');
            });
            mapBubbleSeries.setData([], true);
          }
        }, 5000);

        return () => {
          clearTimeout(animationTimeoutId);
          clearTimeout(removalTimeoutId);
        };
      }
    }
  }, [data]);

  useEffect(() => {
    let timeoutId;
    if (chartComponentRef.current && chartComponentRef.current.chart) {
      const chart = chartComponentRef.current.chart;
      const series = chart?.series?.find(series => series.type === 'mapbubble');
      if (series) {
        timeoutId = setTimeout(() => {
          series.setData([], true);
        }, 2200);
      }
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [data]);

  useEffect(() => {
    clearMapBubbles();
  }, [mapType]);

  const mapOptions = useMemo(() => ({
    chart: {
      map: mapData,
      height: chartHeight,
      animation: false,
      backgroundColor: {
        radialGradient: {
          cx: 0.5,
          cy: 0.5,
          r: 0.5
        },
        stops: [
          [0, '#4b2c32'],
          [0.5, '#31283e'],
          [1, '#13151a']
        ]
      }
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: null
    },
    credits: {
      enabled: false
    },
    mapNavigation: {
      enableMouseWheelZoom: true,
      enableDoubleClickZoom: true,
      enableDoubleClickZoomTo: true,
      enableTouchZoom: true,
    },
    series: [{
      color: '#49364b',
      data: mapData.features.map(feature => ({
        'iso-a2': feature.properties['iso-a2']
      })),
      mapData: mapData,
      joinBy: 'iso-a2',
      name: '',
      dataLabels: {
        enabled: false,
      },
      showInLegend: false,
    }, {
      type: 'mapbubble',
      data: (data || []).map(item => ({ ...item, z: item.z })),
      joinBy: ['iso-a2', 'iso-a2'],
      minSize: '12rem',
      maxSize: '12rem',
      color: '#0DFF08',
      showInLegend: false,
      tooltip: {
        pointFormat: '{point.name}'
      },
      dataLabels: {
        enabled: true,
        format: '{point.name}',
        style: {
          color: 'white',
          textOutline: 'none'
        },
        y: -14
      }
    }]
  }), [mapData, chartHeight, data]);

  const mapControlsStyle = {
    position: 'absolute',
    top: isFullscreen ? '85%' : '65%',
    right: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 1040,
  };

  const getButtonStyle = (buttonNumber) => ({
    width: '1.9rem',
    height: '1.9rem',
    marginBottom: '0.5rem',
    marginTop: buttonNumber === 1 ? (isFullscreen ? '85%' : '6rem') : '0'
  });

  return (
    <div ref={containerRef} className="position-relative" id="bar-chart-container">
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'mapChart'}
        options={mapOptions}
        ref={chartComponentRef}
      />
      <div className="map-legend">
        <ShowDetails />
      </div>
      <div className="map-controls" style={mapControlsStyle}>
        <button onClick={zoomIn} className="btn btn-secondary" style={getButtonStyle(1)}>
          <FontAwesomeIcon icon={faPlus} className="genericMap-small-icon" />
        </button>
        <button onClick={zoomOut} className="btn btn-secondary" style={getButtonStyle(2)}>
          <FontAwesomeIcon icon={faMinus} className="genericMap-small-icon" />
        </button>
        <button onClick={toggleFullScreen} className="btn btn-secondary" style={getButtonStyle(3)}>
          <FontAwesomeIcon icon={faExpand} className="genericMap-small-icon" />
        </button>
      </div>
    </div>
  );
});

export default WorldmapChart;