import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getdisplayFlash, setdisplayFlash } from "../../Utils/Common";
import {resetpassword} from "../../Redux/Actions/Auth";
import FlashMessage from "../FlashMessage/FlashMessage";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { resetPassword } from "../../Redux/Actions/Auth";
import logo from "../../assets/images/logo_new.svg";
import "./resetPassword.css";

const ResetPassword = () => {
	const history = useHistory();
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [resetButton , setResetButton] = useState(false);

  // FLASH MESSAGE STATE
  const [flashMessage, setFlashMessage] = useState(getdisplayFlash());
  const [iconImage, setIconImage] = useState("");
  const [firstMsg, setFirstMsg] = useState("");
  const [secondMsg, setSecondMsg] = useState("");

  // TOGGLE PASSWORD
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [newPasswordAgainShown, setNewPasswordAgainShown] = useState(false);

  
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code');

    //PASSWORD VALIDATION
  const schema = yup.object({
    password: yup
      .string()
      .min(8, "Your password must be at least 8 characters long")
      .max(64, "The password can't be longer than 64 characters")
      .matches(
        /[a-z]/,
        "Your password must contain a minimum of one lower case letter"
      )
      .matches(/[A-Z]/, "The password must contain one capital letter")
      .matches(/[0-9]/, "Your password must contain at least one number")
      .matches(/^(\S+$)/, "The password can't contain any blank spaces")
      .required()
      .matches(
        /^[aA-zZ0-9#!%&/()åÅäÄöÖ\s]+$/,
        "The password can't contain other special characters than: #!%&/()"
      )
      .matches(
        /^((?!]).)*$/,
        "The password can't contain other special characters than: #!%&/()"
      )
      .matches(
        /^[^^[`\\_]+$/,
        "The password can't contain other special characters than: #!%&/()"
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
 
  const onSubmit = async () => {
    const data = { code, password };
    if(password == password_confirmation ){
    	const { status, message } = await dispatch(resetPassword(data));
      if (status) {
        setdisplayFlash(true);
        history.push("/login");
      } else {
        openFlash("alert", "Error!", message);
      }
    }
  } 
	 
	function closeFlash() {
    setdisplayFlash(false);
    setFlashMessage(false);
  }

  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  }
  
	return(		
		<div>
  		<div
        className={`alert-popup alert-success-bar ${
          flashMessage
            ? setTimeout(() => setFlashMessage(false), 3000) &
              setdisplayFlash(false) &
              "alert-shown"
            : "alert-hidden"
        }`}
      >
      <FlashMessage
        iconImage={iconImage}
        firstMsg={firstMsg}
        secondMsg={secondMsg}
        closeFlash={() => closeFlash()}
      />
      </div>
  		<div className="login_form">
  			<div className="outer-login">
  				<div className="inner-login">
  					<div className="logo">
  	          <img src={logo} alt="logo_new" style={{ width: "150" }} />
  					</div>
  					<h4>Reset Password</h4>
  					<p className="forget-title"><span>RESET YOUR PASSWROD</span></p>
  					<form className="login-form">
  						<input
                {...register("password")}
                required
                data-lpignore="true"
                type={newPasswordShown ? "text" : "password"}
                className="form-control"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
                {errors && (
                  <p className="error-msg">{errors.password?.message}</p>
                )}

                {/[0-9]/.test(password) ||
                /[a-z]/.test(password) ||
                /[A-Z]/.test(password)
                  ? (/[0-9]/.test(password) &&
                    !/[A-Z]/.test(password) &&
                    !/[a-z]/.test(password) ? (
                      <p className="error-msg">
                        Your password must contain a minimum of one capital
                        letter and one lower case letter
                      </p>
                    ) : null) ||
                    (/[a-z]/.test(password) &&
                    !/[0-9]/.test(password) &&
                    !/[A-Z]/.test(password) ? (
                      <p className="error-msg">
                        Your password must contain a minimum of one capital
                        letter and one number
                      </p>
                    ) : null) ||
                    (/[A-Z]/.test(password) &&
                    !/[0-9]/.test(password) &&
                    !/[a-z]/.test(password) ? (
                      <p className="error-msg">
                        Your password must contain a minimum of one lower case
                        letter and one number
                      </p>
                    ) : null) ||
                    (/[A-Z]/.test(password) &&
                    /[0-9]/.test(password) &&
                    !/[a-z]/.test(password) ? (
                      <p className="error-msg">
                        Your password must contain a minimum of one lower case
                        letter
                      </p>
                    ) : null) ||
                    (/[a-z]/.test(password) &&
                    /[0-9]/.test(password) &&
                    !/[A-Z]/.test(password) ? (
                      <p className="error-msg">
                        Your password must contain a minimum of one capital
                        letter
                      </p>
                    ) : null) ||
                    (/[A-Z]/.test(password) &&
                    /[a-z]/.test(password) &&
                    !/[0-9]/.test(password) ? (
                      <p className="error-msg">
                        Your password must contain a minimum of one number
                      </p>
                    ) : null)
                  : null}
  						<input
                required
                data-lpignore="true"
                type={newPasswordAgainShown ? "text" : "password"}
                className="form-control"
                placeholder="Enter password"
                value={password_confirmation}
                onChange={(e) => setPassword_confirmation(e.target.value)}
              />
              {password !== password_confirmation && (
                <p className="error-msg">
                  Re-entered password must match new password
                </p>
              )}
  						<button type="submit" onClick={handleSubmit(onSubmit)}>reset</button>
  						<div className="forget-link">
  							<a href="/login">login page</a>
  						</div>
  					</form>
  				</div>
  			</div>
  		</div>
		</div>
	)
}

export default ResetPassword;