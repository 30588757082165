import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const AcceptedRequestsTrendGraph = (props) => {

  let currentYearRequests = [];
  Object.values(props.currentYearRequests).map((x) => {
    return currentYearRequests.push(x);
  });

  let previousYearRequests = [];
  Object.values(props.previousYearRequests).map((x) => {
    return previousYearRequests.push(x);
  });

  let currentYearRequests2 = [...currentYearRequests];
  let previousYearRequests2 = [...previousYearRequests];

  if (props.slicedNumber > 0) {
    previousYearRequests2 = previousYearRequests2.slice(0, props.slicedNumber)
    currentYearRequests2 = currentYearRequests2.slice(0, props.slicedNumber)
  }

  let currentNum = 0;
  currentYearRequests2.map((x) => {
    currentNum += x
  })

  let prevNum = 0;
  previousYearRequests2.map((x) => {
    prevNum += x
  })

  let growth = currentNum / prevNum;
  const trendData = [];

  for (let x = 0; x < currentYearRequests.length; x++) {
    for (let y = 0; y < previousYearRequests.length; y++) {
      if (x === y) {
        if (currentYearRequests[x] !== 0) {
          trendData.push(Math.ceil((currentYearRequests[x] + previousYearRequests[y]) / 2))
        }
        else if (currentYearRequests[x] === 0) {
          trendData.push(Math.ceil(previousYearRequests[y] * growth))
        }
      }
    }
  }

  //Trend data
  //For months where requests exists for both previous and current year, trend data is (current year + previous year) / 2.
  //If no data exists for a month in current year, trend data for that month is the growth in percentage for the months where both 
  //requests exists, multiplied with the requests for the same month from previous year.

  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const options = {
    chart: {
      type: props.graphType,
      spacing: [15, 0, 15, 15],
      style: {
        fontFamily: "'Noto Sans', sans-serif",
        color: "#8591A6",
      },
    },
    title: {
      text: "",
      margin: 40,
    },

    legend: {
      itemStyle: {
        fontFamily: "SF Pro Display",
        color: "#596980",
        fontWeight: "regular",
      },
      symbolRadius: 0
    },
    tooltip: {
      animation: false,
      backgroundColor: "#ffffff",
      style: {
        color: "#333333",
      },
    },
    accessibility: {
      enabled: false,
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        pointWidth: 15,
        groupPadding: 0.25,
      },
      line: {
        dashStyle: "LongDash",
      },
      series: {
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: "bold"
          },
          format: '{point.y:,.0f}',
          formatter: function () {
            return Highcharts.numberFormat(this.y, 0, ' ');
          },
        },
        minPointLength: 2,
        groupPadding: 0.4,
        pointPadding: 0.2,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    colors: ["#3e85f9", "#43BF58", "orange"],
    xAxis: {
      categories: months,
      lineWidth: 0,
      labels: {
        style: {
          color: "#8591A6",
        },
      },
    },
    yAxis: {
      min: 0,
      offset: -10,
      title: {
        text: "",
        x: -20,
        style: {
          color: "#8591A6",
        },
      },
      labels: {
        format: '{value}',
        style: {
          color: "#8591A6",
        },
        formatter: function () {
          return Highcharts.numberFormat(this.value, 0, ' ');
        },
      },
      gridLineColor: "#CFD7E5",
      gridLineDashStyle: "longDash",
      gridLineWidth: 0.5,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        id: "previous-year",
        name: props.previousYear,
        data: previousYearRequests,
        borderRadiusTopLeft: "10px",
        borderRadiusTopRight: "10px",
        tooltip: {
          headerFormat:
            "<span>{point.key} " + props.previousYear + "<br></br></span>",
          pointFormat:
            '<span class="highcharts-tooltip-live-data">{point.y}</span>',
        },
        categories: "test",
        marker: {
          symbol: "square",
        }
      },
      {
        id: "current-year",
        name: props.currentYear,
        data: currentYearRequests,
        borderRadiusTopLeft: "10px",
        borderRadiusTopRight: "10px",
        tooltip: {
          headerFormat:
            "<span>{point.key} " + props.currentYear + "<br></br></span>",
          pointFormat:
            '<span class="highcharts-tooltip-live-data">{point.y}</span>',
        },
        marker: {
          symbol: "square",
        }
      },
      {
        type: "spline",
        name: "Trend data",
        data: trendData,
        borderRadiusTopLeft: "10px",
        borderRadiusTopRight: "10px",
        tooltip: {
          headerFormat:
            "<span>Trend data {point.key}" + "<br></br></span>",
          pointFormat:
            '<span class="highcharts-tooltip-live-data">{point.y}</span>',
          format: '{point.y:,.0f}',
        },
        marker: {
          symbol: "square",
        },
        dashStyle: "longDash"
      },
    ],
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}
export default AcceptedRequestsTrendGraph