import { useState, useEffect } from "react";
import { getProvidersModesData } from "../../../Redux/Actions/Statistics";
import { setLoaderSpinner } from "../../../Redux/Actions/LoaderSpinner";
import ProvidersTable from "./ProvidersTable";
import ModesTable from "./ModesTable";
import ProvidersPieChart from "./ProvidersPieChart";
import ModesPieChart from "./ModesPieChart";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import "./providersModes.css"
import moment from "moment";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const ProvidersModes = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const [providersData, setProvidersData] = useState([]);
  const [apiRequests, setApiRequests] = useState({});
  const [emailRequests, setEmailRequests] = useState({});
  const [externalPayload, setExternalPayload] = useState({});
  const [iframeRequests, setIframeRequests] = useState({});
  const [manualRequests, setManualRequests] = useState({});
  const [smsRequests, setSmsRequests] = useState({});
  const [acceptedReqs, setAcceptedReqs] = useState(0);

  function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  function getStartDate(d) {
    return moment(d).startOf('month').format("DD/MM/YYYY")
  }

  function getEndDate(d) {
    return moment(d).format("DD/MM/YYYY");
  }

  const startDate = getStartDate(new Date())
  const endDate = getEndDate(new Date())
  const [selectedDateRange, setSelectedDateRange] = useState(`${startDate}-${endDate}`);

  useEffect(() => {
    fetchProvidersModesData();
  }, [])

  const fetchProvidersModesData = async () => {
    const params =
      "?statistics_date_range=" +
      selectedDateRange;

    await dispatch(setLoaderSpinner(true));
    const { status, data } = await dispatch(getProvidersModesData(params));
    setProvidersData(data.graph_data.services);
    setApiRequests(data.graph_data.api_requests);
    setEmailRequests(data.graph_data.email_requests);
    setExternalPayload(data.graph_data.external_payload);
    setIframeRequests(data.graph_data.iframe_requests);
    setManualRequests(data.graph_data.manual_requests);
    setSmsRequests(data.graph_data.sms_requests);
    setAcceptedReqs(data.graph_data.accepted_requests);

    if (!status) {
      history.push("/login");
    }
    await dispatch(setLoaderSpinner(false));
  };
  const modesData = [];
  let apiRequestCount = 0;

  if (Object.values(apiRequests).length > 0) {
    Object.values(apiRequests).map((x) => {
      apiRequestCount += x
    })
  }

  let emailRequestCount = 0;

  if (Object.values(emailRequests).length > 0) {
    Object.values(emailRequests).map((x) => {
      emailRequestCount += x
    })
  }

  let externalPayloadCount = 0;

  if (Object.values(externalPayload).length > 0) {
    Object.values(externalPayload).map((x) => {
      externalPayloadCount += x
    })
  }

  let iframeRequestCount = 0;

  if (Object.values(iframeRequests).length > 0) {
    Object.values(iframeRequests).map((x) => {
      iframeRequestCount += x
    })
  }

  let manualRequestCount = 0;

  if (Object.values(manualRequests).length > 0) {
    Object.values(manualRequests).map((x) => {
      manualRequestCount += x
    })
  }

  let smsRequestCount = 0;

  if (Object.values(smsRequests).length > 0) {
    Object.values(smsRequests).map((x) => {
      smsRequestCount += x
    })
  }
  let providersDataSorted = providersData.sort((a, b) => {
    return b.value - a.value
  })
  modesData.push(
    { name: "API", y: apiRequestCount },
    { name: "Email", y: emailRequestCount },
    { name: "External Payload", y: externalPayloadCount },
    { name: "iFrame", y: iframeRequestCount },
    { name: "Manual Request", y: manualRequestCount },
    { name: "SMS", y: smsRequestCount }
  );
  let modesDataSorted = modesData.sort((a, b) => {
    return b.y - a.y
  })
  const handleCallback = (start, end, label) => {
    const startDateValue = moment(start._d).format("DD/MM/YYYY");
    const endDateValue = moment(end._d).format("DD/MM/YYYY");
    setSelectedDateRange(`${startDateValue}-${endDateValue}`);
  };

  const handleSubmit = () => {
    fetchProvidersModesData()
  }
  return (
    <div className="statistics-container">
      <div className="lower-header">
        <h3>Providers and Modes</h3>
        <div className="flex-header customer-activity-header">
          <DateRangePicker
            onCallback={handleCallback}
            initialSettings={{ startDate: getStartDate(new Date()), endDate: getEndDate(new Date()), locale: { format: "DD/MM/YYYY" } }}
            onApply={handleSubmit}
          >
            <input
              type="text"
              id="date-range-picker"
              className="date-form-box"
            />
          </DateRangePicker>
          <div className="customer-activity-icon">
            <FontAwesomeIcon icon={faCalendar} size="1x" color="#e8edfa" />
          </div>
        </div>
      </div>
      <section className="accepted-requests-section">
        <div className="providers-modes-container">
          <div className="providers-table-container">
            {providersDataSorted.length > 0 && <ProvidersTable providersData={providersDataSorted} />}
          </div>
          <div className="modes-table-container">
            {modesDataSorted.length > 0 && <ModesTable modesData={modesDataSorted} />}
          </div>
        </div>
        <h3 className="providers-modes-total">Total: <span className="bold-total">{numberWithSpaces(acceptedReqs)}</span></h3>
        <div className="providers-modes-container">
          <div className="providers-table-container">
            {providersDataSorted.length > 0 && <ProvidersPieChart providersData={providersDataSorted} />}
          </div>
          <div className="modes-table-container">
            {modesDataSorted.length > 0 && <ModesPieChart modesData={modesDataSorted} />}
          </div>
        </div>
      </section>
    </div>
  )
}
export default ProvidersModes;