import React from 'react';
import './editServiceSetting.css'
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import removeCredentials from "../../assets/images/icon_minus.png";
import addCredentials from "../../assets/images/icon_close_sms.png";
import { CreateServiceSettings, getServiceSettingsFilter } from "../../Redux/Actions/ServiceSettings";
import { UpdateServiceSettingsAction } from "../../Redux/Actions/ServiceSettings";
import _ from "lodash"


const EditServiceSetting = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [orggId, setOrggId] = useState([]);
  const [servicesId, setServicesId] = useState([]);
  const [countris, setCountris] = useState([]);
  const [certificateType, setCertificateType] = useState(props?.data?.certificate_type?.toString());
  const [env, setEnv] = useState(props?.data?.env?.toString());
  const [labelization, setLabelization] = useState(props?.data?.labelization?.toString());
  const [active, setActive] = useState(props?.data?.active?.toString());
  const [brokerApplication, setBrokerApplication] = useState(props?.data?.broker_application?.toString())
  const [ssnValidation, setSsnValidation] = useState(props?.data?.ssn_validation?.toString())
  const [selectedOrdID, setSelectedOrdId] = useState("");
  const [selectedServiceId, setSelectedServiceId] = useState(props?.data?.service_id);
  const [selectedCountry, setSelectedCountry] = useState(props?.data?.country_id);
  const [selectedCertificateType, setSelectedCertificateType] = useState("");
  const [selectedEnv, setSelectedEnv] = useState("");
  const [status, setStatus] = useState("");
  const [selectedLabelization, setSelectedLabelization] = useState("");
  const [selectedBankAuthentication, setSelectedBankAuthentication] = useState(props?.data.bank_authentication_approach?.toString());
  const [createData, setCreateData] = useState([]);
  const [service, setService] = useState([{ key: "client_id", value: props?.data?.credentials.client_id }, { key: "client_secret", value: props?.data?.credentials.client_secret }, { key: "pem_file_key", value: props?.data?.credentials.pem_file_key }]);
  const [credentialsKey, setCredentialsKey] = useState('');
  const [credentialsValue, setCredentialsValue] = useState('');
  const [serviceData, setServiceData] = useState([]);
  const [orgName, setOrgName] = useState(props.data?.organization_id)
  const [id, setId] = useState(props?.data?.id)
  const [countryName, setCountryName] = useState(props?.data?.country_name)
  const [userRole, setUserRole] = useState(localStorage.role)
  const [credentialData, setCredentialData] = useState(props?.data?.credentials)


  useEffect(() => {
    fetchServiceSettingCreate();
  }, []);

  const fetchServiceSettingCreate = async (props) => {
    const { data } = await dispatch(getServiceSettingsFilter());
    const { organizations_list, services_list, countries_list } = data;
    setOrggId(organizations_list);
    setServicesId(services_list);
    setCountris(countries_list)
  };

  const submitHandle = async () => {
    let credentialsData = {};
    service.map(item => {
      if (item.value) {
        credentialsData = {
          ...credentialsData, [item.key]: item.value
        }
      }
    })
    let countryIndex = countris.findIndex(item => item.id == selectedCountry)
    const data = {
      id: id,
      organization_id: orgName,
      service_id: Number(selectedServiceId),
      country_id: selectedCountry,
      country_name: countris[countryIndex].name,
      active: JSON.parse(active.replace(/ /g, "")),
      certificate_type: certificateType,
      env: env,
      labelization: JSON.parse(labelization.replace(/ /g, "")),
      broker_application: JSON.parse(brokerApplication.replace(/ /g, "")),
      ssn_validation: JSON.parse(ssnValidation.replace(/ /g, "")),
      bank_authentication_approach: selectedBankAuthentication,
      credentials: credentialsData
    }
    props.handleUpdate(data)
    // const response = await dispatch(UpdateServiceSettingsAction(data))
    // debugger
    // fetchServiceSettingCreate()
    // if (response.status) {
    //   if (response.message === "Unauthorize") {
    //     history.push("/login")
    //   } else {
    //     setCreateData(response?.data);
    //     props.closeDelete(false);
    //   }
    // }
  }

  const handleServiceKey = (e, index) => {
    const { name, value } = e.target;
    const list = [...service];
    list[index][name] = value;
    setService(list);
    setCredentialsKey(value);
  };

  const handleServiceValue = (e, index) => {
    const { name, value } = e.target;
    const list = [...service];
    list[index][name] = value;
    setService(list);
    setCredentialsValue(value);
  };

  const handleServiceAdd = () => {
    if (service.length < 4) {
      setService([...service, { key: "", value: "" }]);
    }
  };

  const handleServiceRemove = (index) => {
    const list = [...service];
    let newList = _.pull(list, list[index])
    setService(newList);
  };

  function humanize(str) {
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  const handleUpdate = async (data) => {
    const response = await dispatch(UpdateServiceSettingsAction(data))
    if (response.status) {
      // await dispatch(setLoaderSpinner(false))
      if (response.message === "Unauthorize") {
        history.push("/login")
      } else {
        const result = data.findIndex(item => item.id === data.id)
        serviceData[result].orgName = data.organization_name
        serviceData[result].active = data.active
        serviceData[result].labelization = data.labelization
        serviceData[result].env = data.env
        serviceData[result].certificateType = data.certificate_type
        serviceData[result].selectedCountry = data.country_name
        serviceData[result].selectedServiceId.countris = data.service_name
        serviceData[result].selectedBankAuthentication = data.bank_authentication_approach

      }
    }
  }

  const getOrgName = (data, id) => {
    const val = data.filter(org => org.id === id)
    return `${val[0]?.name}`
  }


  return (
    <div className='EditPopup'>
      <div className='EditPopup_inner'>
        <h3>Edit Service Setting</h3>
        <div className="row">
          <div className="col-md-6">
            <div className="setting-filter-container">
              <label>Organization</label>
              <input className="select-filter-service-settings" value={`${getOrgName(orggId, orgName)}(${orgName})`} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="setting-filter-container">
              <label>Service</label>
              <input className="select-filter-service-settings" value={humanize(getOrgName(servicesId, selectedServiceId))} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="setting-filter-container">
              <label>Country</label>
              <input className="select-filter-service-settings" value={humanize(getOrgName(countris, Number(selectedCountry)))} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="setting-filter-container">
              <label>Certificate Type</label>
              <input className="select-filter-service-settings" value={humanize(certificateType)} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="setting-filter-container">
              <label>ENV</label>
              <input className="select-filter-service-settings" value={humanize(env)} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="setting-filter-container">
              <label>Active</label>
              <select className="select-filter-service-settings" value={active} onChange={e => setActive(e.target.value)}>
                <option value="">Select</option>
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="setting-filter-container">
              <label>Labelization</label>
              <select className="select-filter-service-settings" value={labelization} onChange={e => setLabelization(e.target.value)}>
                <option value="">Select</option>
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="setting-filter-container">
              <label>Bank Authentication Approach</label>
              <select className="select-filter-service-settings" value={selectedBankAuthentication} onChange={e => setSelectedBankAuthentication(e.target.value)}>
                <option value="">Select</option>
                <option value="decoupled">Decoupled</option>
                <option value="redirect">Redirect</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="setting-filter-container">
              <label>Broker Application</label>
              <select className="select-filter-service-settings" value={brokerApplication} onChange={e => setBrokerApplication(e.target.value)}>
                <option value="">Select</option>
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="setting-filter-container">
              <label>Ssn Validation</label>
              <select className="select-filter-service-settings" value={ssnValidation} onChange={e => setSsnValidation(e.target.value)}>
                <option value="">Select</option>
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </div>
          </div>
        </div>
        <div className="modal-btns">
          <button className='closeDelete-update' style={{ marginLeft: '0px' }} onClick={props.closeDelete}>Cancel</button>
          <button className='confirm-update' onClick={() => submitHandle()}>Confirm</button>
        </div>
      </div>
    </div>
  )
}

export default EditServiceSetting;