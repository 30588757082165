import React, { useEffect, useState } from "react";
import { sideBarData } from "../Sidebar/SidebarData";

const TopLabel = ({ children }) => { return <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{children}</label> }

const access_setting_format = (access_setting) => {
  const formattedAccess = access_setting.map((access) => {
    if (access.hasOwnProperty("children")) {
      const subAccess = access.children.map((subAccess) => {
        const role = subAccess.role || [];
        const roleExclusion = subAccess.roleExclusion || [];
        return {
          displayName: subAccess.displayName,
          value: subAccess.path,
          role,
          roleExclusion
        }
      })
      const role = access.role || [];
      const roleExclusion = access.roleExclusion || [];
      return {
        main: {
          displayName: `${access.displayName} - All`,
          value: access.displayName,
          role,
          roleExclusion
        },
        sub: subAccess
      }
    }
    const role = access.role || [];
    const roleExclusion = access.roleExclusion || [];
    return {
      main: {
        displayName: access.displayName,
        value: access.path,
        role,
        roleExclusion
      }
    }
  })
  return formattedAccess;
}

const SettingMainToggle = ({ accessSetting, updateAccessSettings, accessSettings }) => {
  const isChecked = accessSettings[sanitizeValue(accessSetting.value)] || false;
  const [checked, setChecked] = useState(isChecked);
  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked])
  const handleToggle = (e) => {
    updateAccessSettings(e.target);
  }
  return (
    <div className="form-check form-switch edit-user-access-settings-main-container">
      <TopLabel>{accessSetting.displayName}</TopLabel>
      <input className="form-check-input float-right" checked={checked} value={accessSetting.value} type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={handleToggle} />
    </div>
  )
}

const SettingToggle = ({ accessSetting, updateAccessSettings, accessSettings }) => {
  const isChecked = accessSettings[sanitizeValue(accessSetting.value)] || false;
  const [checked, setChecked] = useState(isChecked);
  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked])
  const handleToggle = (e) => {
    updateAccessSettings(e.target);
  }

  return (
    <div className="form-check form-switch edit-user-access-settings-sub-container">
      <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{accessSetting.displayName}</label>
      <input className="form-check-input float-right" checked={checked} value={accessSetting.value} type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={handleToggle} />
    </div>
  )
}

export const AccessSettings = ({ userAccessSettings, role, updateUserAccessSettings, roleUpdater }) => {
  const formattedAccessSettings = access_setting_format(sideBarData);
  const [accessSettings, setAccessSettings] = useState({});
  useEffect(() => {
    setAccessSettings(initialAccessSettings(userAccessSettings, role, formattedAccessSettings));
  }, []);
  useEffect(() => {
    updateUserAccessSettings(accessSettings);
  }, [accessSettings]);
  useEffect(() => {
    if(!roleUpdater) return;
    setAccessSettings(initialAccessSettings({}, role, formattedAccessSettings));
  }, [roleUpdater]);
  const handleGroupToggle = (e) => {
    const { value, checked } = e.target;
    const subAccess = formattedAccessSettings.find((access) => access.main.value === value).sub;
    if (subAccess) {
      const subAccessValues = {}

      subAccess.forEach((sub) => {
        subAccessValues[sanitizeValue(sub.value)] = checked;
      })
      setAccessSettings({ ...accessSettings, ...subAccessValues });
    }
  }

  const updateAccessSettings = (target) => {
    const { value, checked } = target;
    setAccessSettings({ ...accessSettings, [sanitizeValue(value)]: checked });
  }
  const returnTrueIfAllSubAccessesAreTrue = (mainSetting) => {
    const subAccessValues = Object.values(mainSetting.sub).map((sub) => {
      return accessSettings[sanitizeValue(sub.value)];
    });
    const allTrue = subAccessValues.every((value) => value === true);
    return allTrue;
  }

  return (
    <>
      <div className="edit-user-access-settings-container">
        <h4>
          Access settings - sections
        </h4>
        <div className="edit-user-settings-switches-container">
          {formattedAccessSettings.map((accessSetting, mainIndex) => {
            if (accessSetting.hasOwnProperty("sub") === false) {
              return (
                <div key={mainIndex} className="edit-user-access-settings-checks-container">
                  <SettingMainToggle key={mainIndex} accessSetting={accessSetting.main} updateAccessSettings={updateAccessSettings} accessSettings={accessSettings} />
                </div>
              )
            }
            const checked = returnTrueIfAllSubAccessesAreTrue(accessSetting);
            return (
              <div key={mainIndex} className="edit-user-access-settings-checks-container">
                <div className="form-check form-switch edit-user-access-settings-main-container ">
                  <TopLabel>{accessSetting.main.displayName}</TopLabel>
                  <input className="form-check-input float-right" checked={checked} value={accessSetting.main.value} onChange={handleGroupToggle} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                </div>
                {accessSetting.sub && accessSetting.sub.map((subAccess, subIndex) => {
                  return <SettingToggle key={subIndex} accessSetting={subAccess} updateAccessSettings={updateAccessSettings} accessSettings={accessSettings} />
                })
                }
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
const sanitizeValue = (string) => {
  return string.replace(/^\/|\/$/g, '');
}

const initialAccessSettings = (user_access_settings, user_role, access_settings_data) => {
  const accessSettings = {}
  access_settings_data.map((access) => {
    if (access.hasOwnProperty("sub")) {
      access.sub.map((sub) => {
        const value = sanitizeValue(sub.value);
        if (user_access_settings.hasOwnProperty(value)) {
          accessSettings[value] = user_access_settings[value];
        } else if (sub.hasOwnProperty("role") && sub.role.length > 0) {
          if (sub.role.includes(user_role)) {
            accessSettings[value] = true;
          } else {
            accessSettings[value] = false;
          }
        } else if (sub.roleExclusion.includes(user_role)) {
          accessSettings[value] = false;
        } else {
          accessSettings[value] = true;
        }
      })
    } else {
      const value = sanitizeValue(access.main.value);
      if (user_access_settings.hasOwnProperty(value)) {
        accessSettings[value] = user_access_settings[value];
      } else if (access.main.hasOwnProperty("role") && access.main.role.length > 0) {
        if (access.main.role.includes(user_role)) {
          accessSettings[value] = true;
        } else {
          accessSettings[value] = false;
        }
      } else if (access.main.roleExclusion.includes(user_role)) {
        accessSettings[value] = false;
      } else {
        accessSettings[value] = true;
      }
    }
  })

  return accessSettings;


}
