import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import calendarIcon from '../../assets/images/calendar_icon_white.svg';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import searchIcon from '../../assets/images/search_icon.svg';
import excelIcon from '../../assets/images/excel_icon.png'
import close from '../../assets/images/icon_close.png';
import sortIcon from '../../assets/images/sort_icon.svg';
import { useHistory } from "react-router-dom";
import { getinvoiceslogsList, getInvoiceXlsx } from '../../Redux/Actions/InvoicesLogs';
import { setLoaderSpinner } from '../../Redux/Actions/LoaderSpinner';
import moment from "moment";
import ReactPaginate from 'react-paginate';
import "./invoicingList.scss";
import List from './List';
import NewFlash from "../FlashMessage/NewFlash";

const date = new Date();
function daysInMonth(month, year) {
	return new Date(year, month, 0).getDate();
}
const START_DATE = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('DD/MM/YYYY');
const END_DATE = moment(new Date(date.getFullYear(), date.getMonth(), daysInMonth(date.getMonth() + 1, date.getFullYear()))).format('DD/MM/YYYY')

const InvoicesLogsListList = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [page, setPage] = useState(1);
	const [paginationOrg, setpaginationOrg] = useState([]);
	const [activeTab, setActiveTab] = useState("month");
	const [searchKey, setSearchKey] = useState('');
	const [activeYear, setActiveYear] = useState(false);
	const [selectedDateRange, setSelectedDateRange] = useState(`${START_DATE}-${END_DATE}`);
	const [paginationData, setpaginationData] = useState({ current_page: 1, total_pages: '', total_count: '', next_page: '', prev_page: '', sort_column: 'accepted_requests_count', sort_order: 'asc' });
	const [flashMessage, setFlashMessage] = useState(false);
	const [flashData, setFlashData] = useState({ iconImage: "", firstMsg: "", secondMsg: "" })

	useEffect(() => {
		fetchinvoicesList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTab, page, paginationData.sort_order, paginationData.sort_column]);

	function getStartDate(d) {
		return moment(new Date(d.getFullYear(), d.getMonth(), 1)).format('DD/MM/YYYY');
	}
	function daysInMonth(month, year) {
		return new Date(year, month, 0).getDate();
	}
	function getEndDate(d) {
		var lastDay = new Date(d.getFullYear(),
			d.getMonth(), daysInMonth(d.getMonth() + 1,
				d.getFullYear()));

		return moment(lastDay).format('DD/MM/YYYY');
	}
	const handleCallback = (start, end, label) => {
		setActiveYear(false)
		const startDateValue = moment(start._d).format('DD/MM/YYYY');
		const endDateValue = moment(end._d).format('DD/MM/YYYY');
		setSelectedDateRange(`${startDateValue}-${endDateValue}`);
	}

	const handleSubmit = () => {
		fetchinvoicesList(true)
	}

	function getParams(dateSelected, isSearchBlank) {
		// if (dateSelected)
		// return "?invoice_date_range=" + selectedDateRange + "&page=" + page + "&query=" + (isSearchBlank ? "" : searchKey.toString()) + "&sort_order=" + paginationData.sort_order + "&sort_column=" + paginationData.sort_column
		return "?invoice_date_range=" + selectedDateRange + "&month=" + (activeTab === "month" ? "true" : "false") + "&year=" + activeYear + "&page=" + page + "&query=" + (isSearchBlank ? "" : searchKey.toString()) + "&sort_order=" + paginationData.sort_order + "&sort_column=" + paginationData.sort_column
	}

	const fetchinvoicesList = async (dateSelected = false, isSearchBlank = false) => {
		await dispatch(setLoaderSpinner(true))
		const { status, data = null } = await dispatch(getinvoiceslogsList(getParams(dateSelected, isSearchBlank)));
		if (status) {
			const { pagination_data, paginated_orgs } = data
			setpaginationOrg(paginated_orgs)
			setpaginationData(prevState => ({
				...prevState,
				current_page: pagination_data.current_page,
				total_count: pagination_data.total_count,
				total_pages: pagination_data.total_pages,
				next_page: pagination_data.next_page,
				prev_page: pagination_data.prev_page
			}))
		} else {
			history.push('/login')
		}
		await dispatch(setLoaderSpinner(false))
	}

	const generateInvoiceXlsx = async (dateSelected = false, isSearchBlank = false) => {
		await dispatch(setLoaderSpinner(true))
		const { status } = await dispatch(getInvoiceXlsx(getParams(dateSelected, isSearchBlank)));
		if (status) {
			setFlashMessage(true);
			setFlashData(prevState => ({
				...prevState,
				iconImage: "tick",
				firstMsg: "Success",
				secondMsg: "Success"
			}))
		} else {
			history.push('/login')
		}
		dispatch(setLoaderSpinner(false))
	}

	const applySorting = (type) => {
		const sortOrder = paginationData.sort_column === type ? paginationData.sort_order === 'asc' ? 'desc' : 'asc' : 'desc'
		setpaginationData(prevState => ({
			...prevState,
			sort_column: type,
			sort_order: sortOrder
		}))
	}

	const handleKeyDown = (event) => {
		(event.key === 'Enter') && fetchinvoicesList();
	}

	const handleCancel = (e) => {
		setSearchKey('')
		fetchinvoicesList(false, true);
	}

	const activateYear = () => {
		let startDate = getStartDate(new Date(new Date().getFullYear(), 0, 1));
		let endDate = moment(new Date()).format('DD/MM/YYYY')
		setSelectedDateRange(`${startDate}-${endDate}`);
		setActiveTab("year");
		setActiveYear(true);
	}

	const activateMonth = () => {
		setSelectedDateRange(`${START_DATE}-${END_DATE}`);
		setActiveTab("month")
		setActiveYear(false);
	}

	const closeFlash = () => {
		setFlashMessage(false);
	}

	return (
		<div className="page-container">
			{flashMessage &&
				<div className={`alert-popup alert-success-bar ${flashMessage ? setTimeout(() => setFlashMessage(false), 3000) & 'alert-shown' : 'alert-hidden'}`}>
					<NewFlash
						data={flashData}
						closeFlash={() => closeFlash()}
					/>
				</div>
			}
			<h3 className="basic-head01">Invoice Overview</h3>
			<div className="bg-white-invoices bg-20-top">
				<div className="switch-search">
					<div className="tab-date">
						<ul className="switch-map">
							<li className={activeTab === "month" ? 'icon-month active-table' : 'icon-month'} style={{ marginLeft: '-33px', borderRight: "1px solid #E3E7EE" }} onClick={activateMonth}>Current month</li>
							<li className={activeTab === "year" ? 'icon-year active-table' : 'icon-year'} onClick={activateYear}>Current year</li>
						</ul>
						<div className="input-sets">
							<div className="input-group date k-datepicker">
								{activeTab === "month" &&
									<DateRangePicker
										onCallback={handleCallback}
										initialSettings={{ startDate: getStartDate(new Date()), endDate: getEndDate(new Date()), locale: { format: "DD/MM/YYYY" } }}
										onApply={handleSubmit}
									>
										<input
											type="text"
											name="sms_date_range"
											id="form-date-field"
										/>
									</DateRangePicker>
								}
								{activeTab === "year" &&
									<DateRangePicker
										onCallback={handleCallback}
										initialSettings={{ startDate: getStartDate(new Date(new Date().getFullYear(), 0, 1)), endDate: new Date(), locale: { format: "DD/MM/YYYY" } }}
										onApply={handleSubmit}
									>
										<input
											type="text"
											name="sms_date_range"
											id="form-date-field"
										/>
									</DateRangePicker>
								}
								<span className="input-group-addon invoice_date" id='invoice-calendar-icon'><img src={calendarIcon} alt="calendarIcon" /></span>
							</div>
						</div>
					</div>

					<div className="select-search tab-date">
						<div className="search-input">
							<input className="search-input" placeholder="Search" onChange={(e) => setSearchKey(e.target.value)} value={searchKey} onKeyDown={handleKeyDown} />
							{searchKey.length === 0 ?
								<img className="search-icon" src={searchIcon} alt="search" />
								:
								<img className="search-icon" src={close} alt="close" onClick={(e) => handleCancel(e)} />
							}
						</div>
						<span className='invoice-excel-icon' onClick={generateInvoiceXlsx}><img className='invoice-excel-icon' src={excelIcon} alt="excel icon" /></span>
					</div>
				</div>
				<div className="table-content">
					<table>
						<thead>
							<tr key={Math.random()}>
								<td onClick={(e) => applySorting('id')}>ID <a href="#!"><img src={sortIcon} alt="Short" /></a></td>
								<td onClick={(e) => applySorting('name')}>Client name <a href="#!"><img src={sortIcon} alt="Short" /></a></td>
								<td onClick={(e) => applySorting('requests_count')}>Total sent<a href="#!"><img src={sortIcon} alt="Short" /></a></td>
								<td onClick={(e) => applySorting('accepted_requests_count')}>Total accepted <a href="#!"><img src={sortIcon} alt="Short" /></a></td>
								<td onClick={(e) => applySorting('continuous_access')}>
									<span className="invoice-tooltip-wrapper header-tooltip-hover">
										<span>
											CA accepted
										</span>
										<span className='invoice-tooltip-header'>Continuous access accepted</span>
									</span>
									<a href="#!"><img src={sortIcon} alt="Short" /></a></td>
								<td onClick={(e) => applySorting('refetched')}>
									<span className="invoice-tooltip-wrapper header-tooltip-hover">
										<span>
											CA refetched
										</span>
										<span className='invoice-tooltip-header'>Continuous access refetched</span>
									</span>
									<a href="#!"><img src={sortIcon} alt="Short" /></a></td>
								<td>Market</td>
								<td onClick={(e) => applySorting('total_sent_sms')}>Total SMS <a href="#!"><img src={sortIcon} alt="Short" /></a></td>
								<td><center>Detail view</center></td>
								<td><center>Sub accounts</center></td>
							</tr>
						</thead>
						<tbody>
							{paginationOrg && paginationOrg.length > 0 && paginationOrg.map((item) =>
								<List item={item} key={item.id} />
							)}
						</tbody>
					</table>
				</div>
				<div className="pagination-wrapper">
					{paginationData.total_count > 10 &&
						<ReactPaginate
							previousLabel="Previous"
							activePage={Math.ceil(paginationData.current_page)}
							nextLabel="Next"
							breakLabel={"..."}
							pageCount={Math.ceil(paginationData.total_pages)}
							marginPagesDisplayed={2}
							pageRangeDisplayed={5}
							containerClassName={"pagination justify-content-center"}
							pageClassName={"page-item"}
							pageLinkClassName={"page-link"}
							previousClassName={"page-item"}
							previousLinkClassName={"page-link"}
							nextClassName={"page-item"}
							nextLinkClassName={"page-link"}
							breakClassName={"page-item"}
							breakLinkClassName={"page-link"}
							activeClassName={"active"}
							onPageChange={(e) => setPage(e.selected + 1)}
						/>
					}
				</div>

			</div>
		</div>
	)
}

export default InvoicesLogsListList;