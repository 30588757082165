import React, { useEffect, useState } from "react";
import iconImg from "../../assets/images/icon_arrow_down.png"
import './itMenu.scss'
import { createOrganization, getOrganizationAccountList } from '../../Redux/Actions/OrganizationManagement';
import { useDispatch } from 'react-redux'
import FlashMessage from "../../Components/FlashMessage/FlashMessage";
import { getOrganizationListCertificates, getCertificateData, getCertificateDelete } from "../../Redux/Actions/ItMenu";
import ReactPaginate from "react-paginate";
import vectorImg from "../../assets/images/Vector.svg"
import { setdisplayFlash, getdisplayFlash } from "../../Utils/Common";
import { filter } from "lodash";
import Select from 'react-select'
import ReactSelect from "../ReactSelect/ReactSelect";
import { setLoaderSpinner } from "../../Redux/Actions/LoaderSpinner";
const ItMenu = () => {
  const dispatch = useDispatch();
  const [orgList, setOrgList] = useState([])
  const [filters, setFilters] = useState({ 'organization_id': '', 'type': '', 'certificate_id': '' })
  const [selectedPage, setSelectedPage] = useState(1);
  const [total_pages, setTotal_pages] = useState("");
  const [deletePopup, setDeletePopup] = useState(false)
  const [btnEnabled, setBtnEnabled] = useState(false)
  const [filteredCertificates, setFilteredCertificates] = useState()
  const [orgName, setOrgName] = useState()
  const [page, setPage] = useState(1);
  const [paginationData, setpaginationData] = useState({ current_page: 1, total_count: '', total_pages: '' })
  const [certificate, setCertificate] = useState()
  const [flashMessage, setFlashMessage] = useState(false);
  const [iconImage, setIconImage] = useState("");
  const [firstMsg, setFirstMsg] = useState("");
  const [secondMsg, setSecondMsg] = useState("");
  const [reason, setReason] = useState("")
  const [showCertificate, setShowCertificate] = useState(false)
  const textConfirmation1 = ("Are you sure you want")
  const textConfirmation2 = ("to delete client");

  let options = [{ value: 1, label: 1 }, { value: 2, label: 2 }, { value: 3, label: 3 }]


  useEffect(() => {
    fetchOrganization()
  }, [])



  const selectPageHandler = (event) => {
    const currentPage = event.selected + 1;
    setSelectedPage(currentPage);
  };
  const fetchOrganization = async () => {
    const response = await dispatch(getOrganizationListCertificates())
    setOrgList(response?.result?.data?.organizations_list)
  }
  const handleChange = (type, value) => {
    setFilters(prevState => ({
      ...prevState,
      [type]: value
    }))
  }
  const applyFiltersHandler = async () => {
    await dispatch(setLoaderSpinner(true))
    const data = filters;
    let dataQuery = '?organization_id=' + filters.organization_id + '&type=' + filters.type + '&query=' + filters.certificate_id;
    const response = await dispatch(getCertificateData(dataQuery))
    if (response.status) {
      setFilteredCertificates(response?.result?.data?.organization_request)
      let result = orgList.findIndex(org => org.id == filters.organization_id)
      setOrgName(orgList[result]?.name)
      setShowCertificate(true)
    } else {
      openFlash("", "Error", response?.message);
    }
    await dispatch(setLoaderSpinner(false))
  }

  const deleteHandler = (certificate) => {
    setDeletePopup(true)
    setCertificate(certificate)
  }

  const deleteCertificate = async () => {
    setDeletePopup(false)
    let query = certificate?.id + '?organization_id=' + filters.organization_id + '&reason=' + reason;
    const response = await dispatch(getCertificateDelete(query))
    if (response?.status) {
      const { status, message } = response?.result
      if (status === "success") {
        setShowCertificate(false)
        setFilteredCertificates({})
        openFlash("tick", "Done!", message);
        setBtnEnabled(false)
      }
    }
    else {
      openFlash("", "Error", response?.message);
    }
  }

  const togglePopup = () => {
    setDeletePopup(false)
    setBtnEnabled(false)
  }

  const deleteChangeHandler = (e) => {
    e.target.value === "DELETE" ? setBtnEnabled(true) : setBtnEnabled(false)
  }

  function closeFlash() {
    setdisplayFlash(false);
    setFlashMessage(false);
  }
  function openFlash(iconImage, firstMsg, secondMsg) {
    setdisplayFlash(true);
    setFlashMessage(true);
    setIconImage(iconImage);
    setFirstMsg(firstMsg);
    setSecondMsg(secondMsg);
  }

  const reasonHandler = (e) => {
    setReason(e.target.value)
  }

  const pageChangeHandler = (e) => {
    setPage(e.selected + 1)
    applyFiltersHandler()
  }


  return (
    <div className="service-settings-container page-container">
      <div className="lower-header-it-certificates ">
        <div className="lower-header-service-settings service-settings-head-bg lower-header-it-certificates">
          <h3>Certificates</h3>
          <div>
            <div
              className={`alert-popup alert-success-bar ${flashMessage
                ? setTimeout(() => setFlashMessage(false), 3000) &
                setdisplayFlash(false) &
                "alert-shown"
                : "alert-hidden"
                }`}
            >
              <FlashMessage
                iconImage={iconImage}
                firstMsg={firstMsg}
                secondMsg={secondMsg}
                closeFlash={() => closeFlash()}
              />
            </div>
            {deletePopup &&
              <div className='certificate-removal-hard-deletion'>
                <div className='inner-hard-deletion'>
                  <img src={vectorImg} alt="img" />
                  <h3>Certificate removal - Hard deletion</h3>
                  <p>{`You are about to hard delete certificate ${certificate?.id}`}<br />The certificate will not be able to restore after hard deletion!</p>
                  <ul>
                    <li><span>Organization :</span>{orgName}</li>
                    <li><span>Name :</span>{certificate?.receiver_name}</li>
                    <li><span>Email :</span>{certificate?.email}</li>
                  </ul>
                  <form>
                    <div className="label-form">
                      <label>State your reason for removing this certificate</label>
                      <textarea placeholder="Enter detail" onChange={(e) => reasonHandler(e)}></textarea>
                    </div>
                    <div className="label-form">
                      <label>Enter "DELETE" to delete<sup>*</sup></label>
                      <input onChange={(e) => deleteChangeHandler(e)} type="text" placeholder="Enter DELETE" />
                    </div>
                    <div className="label-form-btns">
                      <button onClick={togglePopup} className='cancel-b'>Cancel</button>
                      <button onClick={() => deleteCertificate(certificate?.id)} className={btnEnabled ? 'delete-b' : 'delete-b delete-disabled'} type='button' disabled={!btnEnabled}>Delete</button>
                    </div>
                  </form>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="filter-container-service-settings">
          <div className="service-settings-filter-container">
            <label>Organization</label>
            <select className="select-filter-service-settings capitalize" onChange={(e) => handleChange("organization_id", e.target.value)}>
              <option value="">Select</option>
              {
                orgList && orgList.map((oid, index) => (
                  <option value={oid.id} handleChange
                    key={`orgList-option-${index + 1}`}>
                    {oid.name} ({oid.id})
                  </option>
                ))
              }
            </select>
          </div>
          <div className="service-settings-filter-container">
            <label>Type</label>
            <select className="select-filter-service-settings capitalize" onChange={(e) => handleChange("type", e.target.value)}>
              <option value="">Select</option>
              <option value="customer">Customer</option>
              <option value="corporate">Corporate</option>
            </select>
          </div>
          <div className="service-settings-filter-container search-certificate-id">
            <input type="text" className="select-filter-service-settings" placeholder="Search: certificate ID" onChange={(e) => handleChange("certificate_id", e.target.value)} />
          </div>
          <div className="service-settings-filter-container">
            <button className={filters.organization_id === '' || filters.type === '' || filters.certificate_id === '' ? 'apply-filter-button login_submit_disabled' : 'apply-filter-button'} onClick={applyFiltersHandler}>Apply</button>
          </div>
        </div>
        <div className="bg-white-organizations bg-20-top" style={{ margin: "20px 0 0 0" }}>
          <div className="organizations-thead-scroll-y it-certificates-table">
            <table className="table" width="100%">
              <thead className="organizations-thead">
                <tr>
                  <th className="org-head" style={{ minWidth: "180px" }}>Organization</th>
                  <th className="org-head" style={{ minWidth: "100px" }}>Type</th>
                  <th className="org-head" style={{ minWidth: "180px" }}>Name</th>
                  <th className="org-head" style={{ minWidth: "180px" }}>Email</th>
                  <th className="org-head" style={{ minWidth: "100px" }}>Customer ID</th>
                  <th className="org-head" style={{ minWidth: "100px" }}>Request Type</th>
                  <th className="org-head" style={{ minWidth: "100px" }}>Status</th>
                  <th style={{ minWidth: "100px" }}>Action</th>
                </tr>
              </thead>
              <tbody className="organizations-tbody">
                {showCertificate && <tr>
                  <td className="org-td">{orgName}</td>
                  <td className="org-td">{filteredCertificates?.type}</td>
                  <td className="org-td">{filteredCertificates?.receiver_name}</td>
                  <td className="org-td">{filteredCertificates?.email}</td>
                  <td className="org-td">{filteredCertificates?.id}</td>
                  <td className="org-td">{filteredCertificates?.via?.split("_")?.map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(" ")}</td>
                  <td className="org-td">{filteredCertificates?.status}</td>
                  <td className="org-td" onClick={() => deleteHandler(filteredCertificates)}><a href="#" className="delete-rad">Delete</a></td>
                </tr>}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* </div>
      </div> */}
    </div>
  );
};
export default ItMenu;