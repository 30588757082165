import React, { useState, useEffect } from 'react';
import ToggleOrgCertificate from '../ToggleOrgCertificate/ToggleOrgCertificate';
import ToggleField from '../ToggleField/ToggleField';
import './notification.css'

function NotificationTab({
  setClientDetails,
  clientDetails,
  changeNamesFromKeys,
  setOpenEditOrg,
  setEidtOrgName,
  handleSetting
}) {

  const handleChange = (event, name, index) => {
    let organization = clientDetails.organization;
    organization.notification_settings_attributes[index].email_for_request_response = event.target.value;
    setClientDetails(prevState => ({ ...prevState, organization: organization }))
  }

  const toggleFunction = (event, keys, index) => {
    let organization = clientDetails.organization;
    organization.notification_settings_attributes[index].status = event.target.checked;
    setClientDetails(prevState => ({ ...prevState, organization: organization }))
  }

  const submitForm = (event) => {
    event.preventDefault();
    setOpenEditOrg(true)
    setEidtOrgName(clientDetails.name)
  }

  return (
    <form id="inside_tab_panel" onSubmit={submitForm}>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button className="nav-link active" id="notification-tab" data-bs-toggle="tab" data-bs-target="#notification" type="button" role="tab" aria-controls="notification" aria-selected="true">Accepted Email Delivery</button>
        </li>
      </ul>
      <div className="detail-two-box">
        <div className="details-left details-full-box padding-15">
          {
            clientDetails?.organization?.notification_settings_attributes.map((keys, index) => <div key={index}>
              <h3 className="headeing-switch-light">
                {changeNamesFromKeys(keys.type)}
                <div className="adjust-switcher">
                  <div className="select-switch">
                    <label className="switch-box">
                      <input type="checkbox" name={keys} checked={keys?.status} onChange={(e) => toggleFunction(e, keys, index)} />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </h3>
              {clientDetails.organization.notification_settings_attributes[index].status && <div className='form-group notification-setting'> <h3>Enter Email</h3> <input type="email" value={keys?.email_for_request_response} onChange={(e) => handleChange(e, keys, index)} required={keys.type !== 'manual_request'} /></div>}
            </div>)
          }
          {
            ['display_case_id_in_accepted_email', 'display_email_in_accepted_email'].map((keys, index) => {
              return (
                <ToggleField keys={keys} checked={clientDetails.setting[keys]} toggleFunction={handleSetting} capitalizeFirstLetter={changeNamesFromKeys} key={index} showIcon={true} />);
            })
          }
        </div>
      </div>
      <div className="create-client-button"><button type='submit'>Update</button></div>
    </form>
  );
}

export default NotificationTab;